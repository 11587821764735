import { useCallback, useMemo } from 'react';

const SELECT_ALL_OPTION = { label: 'Seleccionar todo', value: '_SELECT_ALL_OPTION' };

const useSelectAllOption = (options) => {
    const optionsWithAllOption = useMemo(() => [SELECT_ALL_OPTION, ...options], [options]);

    const getValueFromEvent = useCallback(
        (value, selections) => {
            if (!selections?.length) return selections;
            if (!selections?.some((s) => s.value === SELECT_ALL_OPTION.value)) {
                return selections;
            }
            const labelInValue = typeof value[0]?.label === 'string';
            // if "Select All" option selected, set value to all options
            // also keep labelInValue in consideration
            return labelInValue ? options : options.map((o) => o.value);
        },
        [options]
    );

    return [getValueFromEvent, optionsWithAllOption];
};

export default useSelectAllOption;
