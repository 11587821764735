import { Button, Form, Select, Table, Space, Tooltip, Divider, Popconfirm, message } from 'antd';
import { EditTwoTone, DeleteTwoTone, DollarOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import useSWR from 'swr';
import useSWRImmutable from 'swr/immutable';
import { DefaultPaginationConfig, DEFAULT_PAGE_SIZE } from '../../constants/pagination';
import { getAllRubroEmpresasForDropDowList, RUBRO_EMPRESAS_ENDPOINT } from '../../services/api/rubro-empresas';
import { getAllVisibilidadTipoUsuarios, VISIBILIDAD_TIPO_USUARIO_ENDPOINT } from '../../services/api/enumeraciones';
import { deleteEmpresa, EMPRESAS_ENDPOINT, getAllEmpresas } from '../../services/api/empresas';
import { DELETE_CONFIRMATION_MESSAGE, GENERIC_ERROR_MESSAGE, GENERIC_SUCCESS_MESSAGE } from '../../constants/feedback';

const { Option } = Select;
const { Column } = Table;

const itemLayout = {
    labelCol: { span: 24 },
};

const initialFilters = {
    idRubroEmpresa: '',
    idVisibilidadTipoUsuario: '',
    sortField: 'razonSocial',
    sortOrder: 'ascend',
    pageNumber: 1,
    pageSize: DEFAULT_PAGE_SIZE,
};

const EmpresaList = () => {
    const [filters, setFilters] = useState({ ...initialFilters });
    const [selectedFilters, setSelectedFilters] = useState({ ...initialFilters });

    const { data: rubroEmpresasData } = useSWRImmutable(RUBRO_EMPRESAS_ENDPOINT, () =>
        getAllRubroEmpresasForDropDowList()
    );

    const { data: visibilidadTipoUsuariosData } = useSWRImmutable(VISIBILIDAD_TIPO_USUARIO_ENDPOINT, () =>
        getAllVisibilidadTipoUsuarios()
    );

    const {
        data: empresasData,
        error,
        mutate,
    } = useSWR([EMPRESAS_ENDPOINT, filters], (_, filters) => getAllEmpresas(filters));
    const isLoading = !error && !empresasData;

    const [form] = Form.useForm();

    const handleTableChange = (pagination, _, sorter) => {
        const params = {
            ...filters,
            pageNumber: Number(pagination.current),
            pageSize: Number(pagination.pageSize),
            sortField: sorter.columnKey,
            sortOrder: sorter.order,
        };

        setSelectedFilters({ ...params });
        setFilters({ ...params });
    };

    const getActionColumn = (empresa) => {
        return (
            <Space size={0}>
                <Tooltip title='Pagos'>
                    <Link to={`/empresas/${empresa.id}/pagos`}>
                        <DollarOutlined />
                    </Link>
                </Tooltip>

                <Divider type='vertical' />

                <Tooltip title='Editar'>
                    <Link to={`/empresas/${empresa.id}`}>
                        <EditTwoTone />
                    </Link>
                </Tooltip>

                <Divider type='vertical' />

                <Popconfirm title={DELETE_CONFIRMATION_MESSAGE} onConfirm={() => handleConfirmDelete(empresa.id)}>
                    <Tooltip title='Eliminar' className='cursor-pointer'>
                        <DeleteTwoTone />
                    </Tooltip>
                </Popconfirm>
            </Space>
        );
    };

    const handleShowSizeChange = (current, pageSize) => {
        setSelectedFilters({
            ...filters,
            pageSize: pageSize,
        });
    };

    const handleConfirmDelete = async (id) => {
        try {
            await deleteEmpresa(id);

            message.success(GENERIC_SUCCESS_MESSAGE);
            mutate();
        } catch (error) {
            message.error(GENERIC_ERROR_MESSAGE);
        }
    };

    const handleReset = async () => {
        form.resetFields();

        setSelectedFilters({ ...initialFilters });
        setFilters({ ...initialFilters });
    };

    const handleFinish = async (values) => {
        const params = {
            ...selectedFilters,
            idRubroEmpresa: values.idRubroEmpresa ?? null,
            idVisibilidadTipoUsuario: values.idVisibilidadTipoUsuario ?? null,
            pageNumber: 1,
        };

        setFilters(params);
    };

    return (
        <>
            <div className='row pt-3'>
                <div className='col-lg-12'>
                    <div className='card rounded-4 shadow'>
                        <h4 className='color-text pt-3 border-bottom mx-3'>Filtros</h4>

                        <div className='card-body'>
                            <Form form={form} onFinish={handleFinish}>
                                <div className='row pl-2 pr-2'>
                                    <div className='col-md-4 pt-2 pb-3'>
                                        <Form.Item name='idRubroEmpresa' label='Rubro' {...itemLayout}>
                                            <Select className='w-100' allowClear>
                                                {rubroEmpresasData?.data.map((rubro) => (
                                                    <Option key={rubro.id} value={rubro.id}>
                                                        {rubro.nombre}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </div>

                                    <div className='col-md-4  pt-2 pb-3'>
                                        <Form.Item name='idVisibilidadTipoUsuario' label='Tipo usuario' {...itemLayout}>
                                            <Select className='w-100' allowClear>
                                                {visibilidadTipoUsuariosData?.data.map((visibilidad) => (
                                                    <Option key={visibilidad.id} value={visibilidad.id}>
                                                        {visibilidad.nombre}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </div>

                                    <div className='col-lg-12 text-end'>
                                        <Form.Item>
                                            <Space>
                                                <Button type='primary' htmlType='submit'>
                                                    Filtrar
                                                </Button>
                                                <Button onClick={handleReset}>Limpiar</Button>
                                            </Space>
                                        </Form.Item>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>

            <div className='row pt-5 pb-5'>
                <div className='col-lg-12'>
                    <div className='card rounded-4 shadow'>
                        <h4 className='color-text pt-3 border-bottom mx-3'>Empresas</h4>

                        <div className='card-body'>
                            <div className='row pl-2 pr-2'>
                                <Table
                                    rowKey={(empresa) => empresa.id}
                                    dataSource={empresasData?.data.items}
                                    pagination={{
                                        ...DefaultPaginationConfig,
                                        current: filters.pageNumber,
                                        total: empresasData?.data.totalCount,
                                        onShowSizeChange: handleShowSizeChange,
                                    }}
                                    loading={isLoading}
                                    onChange={handleTableChange}
                                >
                                    <Column title='Id' dataIndex='id' key='id' sorter />
                                    <Column title='Razón social' dataIndex='razonSocial' key='razonSocial' sorter />
                                    <Column title='Rut' dataIndex='rutFormateado' key='rut' sorter />
                                    <Column
                                        title='Rubro'
                                        dataIndex='nombreRubroEmpresa'
                                        key='rubroEmpresa.nombre'
                                        sorter
                                    />
                                    <Column title='Usuario' dataIndex='nombreUsuario' key='usuario.username' sorter />
                                    <Column
                                        title='Tipo usuario'
                                        dataIndex='nombreVisibilidadTipoUsuario'
                                        key='usuarioAbonado'
                                        sorter
                                    />
                                    <Column title='Acción' render={(cuestionario) => getActionColumn(cuestionario)} />
                                </Table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-lg-12 text-end mt-3'>
                    <Link to='/empresas/new'>
                        <Button type='primary'>Nueva empresa</Button>
                    </Link>
                </div>
            </div>
        </>
    );
};

export default EmpresaList;
