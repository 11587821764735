import Pregunta from './pregunta';

const Seccion = (props) => {
    const { data } = props;

    return (
        <>
            <div className='row pt-3'>
                <div className='col-lg-12'>
                    <div className='card rounded-4 shadow'>
                        <h4 className='color-text pt-3 border-bottom mx-3'>{data.nombre}</h4>

                        <div className='card-body'>
                            <div className='row pl-2 pr-2'>
                                <div className='col-md-12  pt-2 pb-3'>
                                    <p>
                                        <strong>Lea detenidamente cada ítem.</strong>
                                        <br />
                                        {data.descripcion}
                                    </p>

                                    {data.preguntas.map((pregunta) => (
                                        <Pregunta data={pregunta} key={pregunta.id} />
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Seccion;
