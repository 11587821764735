import axios from '../../lib/axios';

export const AMBITOS_ENDPOINT = '/ambitos';

export const getAllAmbitos = async (params) => {
    const response = await axios.get(AMBITOS_ENDPOINT, { params });
    return response;
};

export const getAmbitoById = async (id) => {
    const response = await axios.get(`${AMBITOS_ENDPOINT}/${id}`);
    return response;
};

export const AMBITOS_DDL_ENDPOINT = `${AMBITOS_ENDPOINT}/drop-down-list`;

export const getAllAmbitosForDropDowList = async () => {
    const response = await axios.get(AMBITOS_DDL_ENDPOINT);

    return response;
};

export const AMBITOS_BY_LEY_DDL_ENDPOINT = `${AMBITOS_ENDPOINT}/{idLeyNormativa}/drop-down-list`;

export const getAmbitosByLeyNormativaForDropDowList = async (idLeyNormativa) => {
    const response = await axios.get(`${AMBITOS_ENDPOINT}/${idLeyNormativa}/drop-down-list`);

    return response;
};

export const SUBAMBITOS_BY_AMBITO_DDL_ENDPOINT = `${AMBITOS_ENDPOINT}/{id}/subambitos/drop-down-list`;

export const getSubambitosByAmbitoForDropDowList = async (idAmbito) => {
    const response = await axios.get(`${AMBITOS_ENDPOINT}/${idAmbito}/subambitos/drop-down-list`);

    return response;
};

export const createAmbito = async (params) => {
    const response = await axios.post(AMBITOS_ENDPOINT, params);
    return response;
};

export const updateAmbito = async (params) => {
    const response = await axios.put(AMBITOS_ENDPOINT, params);
    return response;
};

export const deleteAmbito = async (id) => {
    const response = await axios.delete(`${AMBITOS_ENDPOINT}/${id}`);
    return response;
};

export const checkAmbitoAvailability = async (params) => {
    const response = await axios.get(`${AMBITOS_ENDPOINT}/availability`, { params });
    return response;
};
