import { message } from 'antd';
import { createContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSWRConfig } from 'swr';
import { ESTADO_ENCUESTA_BORRADOR, ESTADO_ENCUESTA_FINALIZADO } from '../constants/estado-encuesta';
import { GENERIC_ERROR_MESSAGE, GENERIC_SUCCESS_MESSAGE } from '../constants/feedback';
import {
    createCuestionario,
    CUESTIONARIOS_ENDPOINT,
    publishCuestionario,
    updateCuestionario,
} from '../services/api/cuestionarios';

const initialState = {
    id: 0,
    nombre: '',
    objetivo: '',
    idLeyNormativa: undefined,
    idVisibilidadTipoUsuario: undefined,
    idEstadoEncuesta: ESTADO_ENCUESTA_BORRADOR,
    activo: false,
    idRubroEmpresas: [],
    idTamanoEmpresas: [],
    secciones: [],
};

const CuestionarioContext = createContext({
    ...initialState,
    fill: (params) => {},
    update: (params) => {},
    save: (params, isFinish = false) => {},
    publish: () => {},
});

export const CuestionarioProvider = (props) => {
    const [cuestionario, setCuestionario] = useState(initialState);
    const { mutate } = useSWRConfig();
    const navigate = useNavigate();

    const handleFill = async (params) => {
        setCuestionario({ ...cuestionario, ...params });
    };

    const handleUpdate = (params) => {
        setCuestionario({ ...cuestionario, ...params });
    };

    const handleSave = async (params, isFinish = false) => {
        try {
            let i = 0;

            const newSecciones = cuestionario.secciones.map((seccion) => {
                return {
                    ...seccion,
                    descripcion: params[`descripcion_${seccion.uuid}`] || null,
                    idAmbito: params[`idAmbito_${seccion.uuid}`] ?? null,
                    idSubambito: params[`idSubambito_${seccion.uuid}`] ?? null,
                    saltoPagina: params[`saltoPagina_${seccion.uuid}`] || false,
                    preguntas: seccion.preguntas.map((pregunta) => {
                        i++;

                        return {
                            ...pregunta,
                            nombre: params[`nombre_${pregunta.uuid}`] || null,
                            cantidadOpciones: params[`cantidadOpciones_${pregunta.uuid}`] ?? null,
                            numero: i,
                            opciones: pregunta.opciones.map((opcion, index) => {
                                return {
                                    ...opcion,
                                    nombre: params[`nombre_${opcion.uuid}_${index}`] || null,
                                    ponderacion: params[`ponderacion_${opcion.uuid}_${index}`] ?? null,
                                };
                            }),
                        };
                    }),
                };
            });

            const newCuestionario = {
                ...cuestionario,
                nombre: params.nombre || null,
                objetivo: params.objetivo || null,
                idLeyNormativa: params.idLeyNormativa ?? null,
                idVisibilidadTipoUsuario: params.idVisibilidadTipoUsuario ?? null,
                idEstadoEncuesta: isFinish ? ESTADO_ENCUESTA_FINALIZADO : ESTADO_ENCUESTA_BORRADOR,
                activo: params.activo ?? false,
                idRubroEmpresas: params.idRubroEmpresas ?? [],
                idTamanoEmpresas: params.idTamanoEmpresas ?? [],
                secciones: newSecciones,
            };

            if (cuestionario.id > 0) {
                await updateCuestionario(newCuestionario);

                mutate([CUESTIONARIOS_ENDPOINT, cuestionario.id.toString()]);

                if (isFinish) {
                    navigate('/cuestionarios');
                }
            } else {
                const response = await createCuestionario(newCuestionario);

                setCuestionario({ ...cuestionario, id: response.data });

                if (isFinish) {
                    navigate('/cuestionarios');
                } else {
                    navigate(`/cuestionarios/${response.data}`);
                }
            }

            message.success(GENERIC_SUCCESS_MESSAGE);
        } catch (error) {
            message.error(GENERIC_ERROR_MESSAGE);
        }
    };

    const handlePublish = async () => {
        try {
            await publishCuestionario(cuestionario.id);

            mutate([CUESTIONARIOS_ENDPOINT, cuestionario.id.toString()]);

            navigate('/cuestionarios');

            message.success(GENERIC_SUCCESS_MESSAGE);
        } catch (error) {
            if (error.response && error.response.status === 422) {
                message.error(error.response.data.Message);
            } else {
                message.error(GENERIC_ERROR_MESSAGE);
            }
        }
    };

    const context = {
        ...cuestionario,
        fill: handleFill,
        update: handleUpdate,
        save: handleSave,
        publish: handlePublish,
    };

    return <CuestionarioContext.Provider value={context}>{props.children}</CuestionarioContext.Provider>;
};

export default CuestionarioContext;
