import React from 'react';
import { Form, Input, Select, Button, Space } from 'antd';
const itemLayout = {
    labelCol: { span: 24 },
};

export const FilterForm = (props) => {
    const [form] = Form.useForm();
    const { onSearch } = props;

    const handleReset = async () => {
        form.resetFields();
        onSearch?.(null);
    };

    const handleFinish = async (values) => {
        onSearch?.(values);
    };

    return (
        <Form form={form} onFinish={handleFinish} layout='vertical'>
            <div className='row pl-2 pr-2'>
                <div className='col-md-3 pt-2 pb-3'>
                    <Form.Item name='username' label='User Name' rules={[{ whitespace: true }]}>
                        <Input />
                    </Form.Item>
                </div>
                <div className='col-md-3 pt-2 pb-3'>
                    <Form.Item name='nombre' label='Nombres' rules={[{ whitespace: true }]}>
                        <Input />
                    </Form.Item>
                </div>
                <div className='col-md-3 pt-2 pb-3'>
                    <Form.Item name='activo' label='Estado Usuario Activo' {...itemLayout}>
                        <Select className='w-100'>
                            {props.estados?.map((estado) => (
                                <Select.Option key={estado.id} value={estado.id}>
                                    {estado.nombre}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </div>
                <div className='col-md-3 pt-2 pb-3'>
                    <Form.Item name='bloqueado' label='Usuario Bloqueado' {...itemLayout}>
                        <Select className='w-100'>
                            {props.estados?.map((estado) => (
                                <Select.Option key={estado.id} value={estado.id}>
                                    {estado.nombre}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </div>
                <div className='col-lg-12 text-end'>
                    <Form.Item>
                        <Space>
                            <Button type='primary' htmlType='submit' block style={{ marginTop: '39px' }}>
                                Filtrar
                            </Button>
                            <Button onClick={handleReset} block style={{ marginTop: '39px' }}>
                                Limpiar
                            </Button>
                        </Space>
                    </Form.Item>
                </div>
            </div>
        </Form>
    );
};
