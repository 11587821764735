import { TIPO_USUARIO_ADMINISTRADOR } from '../../constants/tipo-usuario';
import { useAuth } from '../../hooks/use-auth';
import HomeEmpresa from './home-empresa';

const HomePage = () => {
    const { user } = useAuth();

    if (user?.idTipoUsuario === TIPO_USUARIO_ADMINISTRADOR) {
        return <></>;
    }

    return <HomeEmpresa />;
};

export default HomePage;
