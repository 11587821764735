import './App.css';
import { Route, Routes } from 'react-router-dom';
import AuthGuard from './pages/login/auth-guard';
import SiteLayout from './layouts/site-layout';
import HomePage from './pages/home';
import UsuariosPage from './pages/usuarios';
import AmbitosPage from './pages/ambitos';
import SubAmbitosPage from './pages/sub-ambitos';
import CuestionariosPage from './pages/cuestionarios';
import CuestionarioItemPage from './pages/cuestionarios/cuestionario-item';
import { CuestionarioProvider } from './contexts/cuestionario-context';
import EvaluacionItem from './pages/cuestionarios/evaluacion-item';
import CuestionariosDisponiblesPage from './pages/encuestas';
import EncuestaItemPage from './pages/encuestas/encuesta-item';
import EncuestaNewPage from './pages/encuestas/encuesta-new';
import AuthPage from './pages/login/auth';
import EncuestaList from './pages/encuestas/encuesta-list';
import ResultadoItemPage from './pages/encuestas/resultado-item';
import EmpresasPage from './pages/empresas';
import ConsultaPage from './pages/atencion-clientes';
import EmpresaItemPage from './pages/empresas/empresa-item';
import { EmpresaProvider } from './contexts/empresa-context';
import SignUpPage from './pages/signup';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import ResultadosPage from './pages/resultados';
import ResultadoEncuestaListPage from './pages/resultados/resultado-encuesta-list';
import ResultadoEncuestaItemPage from './pages/resultados/resultado-encuesta-item';
import EncuestaPreviewPage from './pages/encuestas/encuesta-preview';
import RecoveryPage from './pages/recuperar-password';
import PagoListPage from './pages/empresas/pago-list';

function App() {
    return (
        <Routes>
            <Route element={<SiteLayout />}>
                <Route element={<AuthGuard />}>
                    <Route path='/' element={<HomePage />} />
                    <Route path='/ambitos' element={<AmbitosPage />} />
                    <Route path='/sub-ambitos' element={<SubAmbitosPage />} />
                    <Route path='/usuarios' element={<UsuariosPage />} />
                    <Route path='/atencion-clientes' element={<ConsultaPage />} />

                    <Route path='/empresas'>
                        <Route index element={<EmpresasPage />} />
                        <Route path=':id'>
                            <Route
                                index
                                element={
                                    <EmpresaProvider>
                                        <EmpresaItemPage />
                                    </EmpresaProvider>
                                }
                            />
                            <Route path='pagos' element={<PagoListPage />} />
                        </Route>
                        <Route
                            path='new'
                            element={
                                <EmpresaProvider>
                                    <EmpresaItemPage />
                                </EmpresaProvider>
                            }
                        />
                    </Route>
                    <Route path='/cuestionarios'>
                        <Route index element={<CuestionariosPage />} />
                        <Route path=':id'>
                            <Route
                                index
                                element={
                                    <CuestionarioProvider>
                                        <CuestionarioItemPage />
                                    </CuestionarioProvider>
                                }
                            />
                            <Route path='evaluacion' element={<EvaluacionItem />} />
                        </Route>
                        <Route
                            path='new'
                            element={
                                <CuestionarioProvider>
                                    <CuestionarioItemPage />
                                </CuestionarioProvider>
                            }
                        />
                    </Route>
                    <Route path='/encuestas'>
                        <Route index element={<EncuestaList />} />
                        <Route path=':id'>
                            <Route index element={<EncuestaItemPage />} />
                            <Route path='new' element={<EncuestaNewPage />} />
                            <Route path='result' element={<ResultadoItemPage />} />
                            <Route path='preview' element={<EncuestaPreviewPage />} />
                        </Route>
                        <Route path='disponibles' element={<CuestionariosDisponiblesPage />} />
                    </Route>
                    <Route path='resultado-empresas'>
                        <Route index element={<ResultadosPage />} />
                        <Route path=':id'>
                            <Route path='encuestas'>
                                <Route index element={<ResultadoEncuestaListPage />} />
                                <Route path=':id'>
                                    <Route path='result' element={<ResultadoEncuestaItemPage />} />
                                </Route>
                            </Route>
                        </Route>
                    </Route>
                </Route>
            </Route>

            <Route path='/login' element={<AuthPage />} />
            <Route path='/recovery' element={<RecoveryPage />} />
            <Route
                path='/signup'
                element={
                    <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY}>
                        <SignUpPage />
                    </GoogleReCaptchaProvider>
                }
            />
        </Routes>
    );
}

export default App;
