import IcoCuestionarioSvg from '../../assets/image/ico-cuestionario.svg';
import IcoCuestionarioDisponibleSvg from '../../assets/image/ico-cuestinariodisponible.svg';
import IcoMaterialAudiovisualSvg from '../../assets/image/ico-materialaudiovisual.svg';
import IcoAtencionClienteSvg from '../../assets/image/ico-atencioncliente.svg';
import { Link } from 'react-router-dom';
import { Popconfirm } from 'antd';
import { ABONARSE_CONFIRMATION_MESSAGE } from '../../constants/feedback';
import { useAuth } from '../../hooks/use-auth';

const MaterialAudiovisual = () => {
    return (
        <div className='card rounded-4 shadow h300 hover-div' role='button'>
            <h4 className='color-text pt-3 text-center text-uppercase mx-3'>Material audiovisual</h4>

            <div className='card-body'>
                <div className='row pl-2 pr-2 d-flex justify-content-center'>
                    <div className='col-md-6'>
                        <img className='img-fluid' src={IcoMaterialAudiovisualSvg} alt='Material audiovisual' />
                    </div>
                </div>
            </div>

            <div className='mx-auto border-inf'></div>
        </div>
    );
};

const AtencionClientes = () => {
    return (
        <div className='card rounded-4 shadow h300 hover-div' role='button'>
            <h4 className='color-text pt-3 text-center text-uppercase mx-3'>Atención al cliente</h4>

            <div className='card-body'>
                <div className='row pl-2 pr-2 d-flex justify-content-center'>
                    <div className='col-md-6'>
                        <img className='img-fluid' src={IcoAtencionClienteSvg} alt='Atención al cliente' />
                    </div>
                </div>
            </div>
            <div className='mx-auto border-inf'></div>
        </div>
    );
};

const HomeEmpresa = () => {
    const { user } = useAuth();

    const handleMaterialAudiovisualConfirm = () => {
        let anchor = document.createElement('a');
        anchor.href = 'https://www.youtube.com/watch?v=4lrfHD0zhqY&t=6s';
        anchor.target = '_blank';
        anchor.click();
    };

    const handleAtencionClientesConfirm = () => {
        let anchor = document.createElement('a');
        anchor.href = 'https://youtube.com';
        anchor.target = '_blank';
        anchor.click();
    };

    return (
        <>
            <div className='row pt-3'>
                <div className='col-md-6 '>
                    <Link to='/encuestas'>
                        <div className='card rounded-4 shadow h300 hover-div'>
                            <h4 className='color-text pt-3 text-center text-uppercase mx-3'>Mis cuestionarios</h4>

                            <div className='card-body'>
                                <div className='row pl-2 pr-2 d-flex justify-content-center'>
                                    <div className='col-md-6'>
                                        <img className='img-fluid' src={IcoCuestionarioSvg} alt='Mis cuestionarios' />
                                    </div>
                                </div>
                            </div>

                            <div className='mx-auto border-inf'></div>
                        </div>
                    </Link>
                </div>

                <div className='col-md-6'>
                    <Link to='/encuestas/disponibles'>
                        <div className='card rounded-4 shadow h300 hover-div'>
                            <h4 className='color-text pt-3 text-center text-uppercase mx-3'>
                                Cuestionarios disponibles
                            </h4>

                            <div className='card-body'>
                                <div className='row pl-2 pr-2 d-flex justify-content-center'>
                                    <div className='col-md-6'>
                                        <img
                                            className='img-fluid'
                                            src={IcoCuestionarioDisponibleSvg}
                                            alt='Cuestionarios disponibles'
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='mx-auto border-inf'></div>
                        </div>
                    </Link>
                </div>
            </div>

            <div className='row pt-3 pb-4'>
                <div className='col-md-6'>
                    {user.usuarioAbonado && (
                        <a href='https://www.youtube.com/watch?v=4lrfHD0zhqY&t=6s' target='_blank' rel='noreferrer'>
                            <MaterialAudiovisual />
                        </a>
                    )}

                    {!user.usuarioAbonado && (
                        <Popconfirm title={ABONARSE_CONFIRMATION_MESSAGE} onConfirm={handleMaterialAudiovisualConfirm}>
                            <div>
                                <MaterialAudiovisual />
                            </div>
                        </Popconfirm>
                    )}
                </div>

                <div className='col-md-6'>
                    {user.usuarioAbonado && (
                        <Link to='/atencion-clientes'>
                            <AtencionClientes />
                        </Link>
                    )}

                    {!user.usuarioAbonado && (
                        <Popconfirm title={ABONARSE_CONFIRMATION_MESSAGE} onConfirm={handleAtencionClientesConfirm}>
                            <div>{<AtencionClientes />}</div>
                        </Popconfirm>
                    )}
                </div>
            </div>
        </>
    );
};

export default HomeEmpresa;
