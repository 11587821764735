import { Table, Space, Tooltip } from 'antd';
import { AlignLeftOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import useSWR from 'swr';
import { DefaultPaginationConfig } from '../../../constants/pagination';
import { getAllResultadoEmpresas, RESULTADO_EMPRESAS_ENDPOINT } from '../../../services/api/empresas';
import { useEffect } from 'react';

const { Column } = Table;

const Resultado = (props) => {
    const [filters, setFilters] = useState({ ...props.filters });

    const { data: empresasData, error } = useSWR([RESULTADO_EMPRESAS_ENDPOINT, filters], (_, filters) =>
        getAllResultadoEmpresas(filters)
    );
    const isLoading = !error && !empresasData;

    useEffect(() => {
        setFilters({ ...props.filters });
    }, [props.filters]);

    const handleTableChange = (pagination, _, sorter) => {
        const params = {
            ...filters,
            pageNumber: Number(pagination.current),
            pageSize: Number(pagination.pageSize),
            sortField: sorter.columnKey,
            sortOrder: sorter.order,
        };

        setFilters({ ...params });
    };

    const getActionColumn = (empresa) => {
        return (
            <Space size={0}>
                <Tooltip title='Ver resultado'>
                    <Link to={`${empresa.id}/encuestas`}>
                        <AlignLeftOutlined />
                    </Link>
                </Tooltip>
            </Space>
        );
    };

    const handleShowSizeChange = (current, pageSize) => {
        setFilters({
            ...filters,
            pageSize: pageSize,
        });
    };

    return (
        <>
            <Table
                rowKey={(empresa) => empresa.id}
                dataSource={empresasData?.data.items}
                pagination={{
                    ...DefaultPaginationConfig,
                    current: filters.pageNumber,
                    total: empresasData?.data.totalCount,
                    onShowSizeChange: handleShowSizeChange,
                }}
                loading={isLoading}
                onChange={handleTableChange}
            >
                <Column title='Id' dataIndex='id' key='id' sorter />
                <Column title='Razón social' dataIndex='razonSocial' key='razonSocial' sorter />
                <Column title='Rut' dataIndex='rutFormateado' key='rut' sorter />
                <Column title='Rubro' dataIndex='nombreRubroEmpresa' key='rubroEmpresa.nombre' sorter />

                {filters.usuarioAbonado && (
                    <Column
                        title='Fecha vencimiento'
                        dataIndex='fechaVencimiento'
                        key='fechaVencimiento'
                        render={(fechaVencimiento) =>
                            fechaVencimiento ? new Date(fechaVencimiento).toLocaleDateString() : null
                        }
                    />
                )}

                <Column
                    title='Fecha creación'
                    dataIndex='fechaCreacion'
                    key='fechaCreacion'
                    sorter
                    render={(fechaCreacion) => new Date(fechaCreacion).toLocaleDateString()}
                />
                <Column title='Acción' render={(cuestionario) => getActionColumn(cuestionario)} />
            </Table>
        </>
    );
};

export default Resultado;
