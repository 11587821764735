import axios from '../../lib/axios';

export const ESTADO_ENCUESTAS_ENDPOINT = '/enumeraciones/estado-encuesta';

export const getAllEstadoEncuestas = async () => {
    const response = await axios.get(ESTADO_ENCUESTAS_ENDPOINT);
    return response;
};

export const ESTADO_AMBITO_ENDPOINT = '/enumeraciones/estado-ambito';

export const getAllEstadoAmbitos = async () => {
    const response = await axios.get(ESTADO_AMBITO_ENDPOINT);
    return response;
};

export const VISIBILIDAD_TIPO_USUARIO_ENDPOINT = '/enumeraciones/visibilidad-tipo-usuario';

export const getAllVisibilidadTipoUsuarios = async () => {
    const response = await axios.get(VISIBILIDAD_TIPO_USUARIO_ENDPOINT);
    return response;
};

export const TIPO_FEEDBACKS_ENDPOINT = '/enumeraciones/tipo-feedback';

export const getAllTipoFeedbacks = async () => {
    const response = await axios.get(TIPO_FEEDBACKS_ENDPOINT);
    return response;
};

export const ESTADO_CUESTIONARIO_RESPONDIDO_ENDPOINT = '/enumeraciones/estado-cuestionario-respondido';

export const getAllEstadoCuestionarioRespondidos = async () => {
    const response = await axios.get(ESTADO_CUESTIONARIO_RESPONDIDO_ENDPOINT);
    return response;
};

export const TIPO_USUARIOS_ENDPOINT = '/enumeraciones/tipo-usuario';

export const getAllTipoUsuarios = async () => {
    const response = await axios.get(TIPO_USUARIOS_ENDPOINT);
    return response;
};

export const TIPO_PERSONA_JURIDICAS_ENDPOINT = '/enumeraciones/tipo-persona-juridica';

export const getAllTipoPersonaJuridicas = async () => {
    const response = await axios.get(TIPO_PERSONA_JURIDICAS_ENDPOINT);
    return response;
};

export const RANGO_COlABORADORES_ENDPOINT = '/enumeraciones/rango-colaboradores';

export const getAllRangoColaboradores = async () => {
    const response = await axios.get(RANGO_COlABORADORES_ENDPOINT);
    return response;
};
