import { Form, Input } from 'antd';
import { PASSWORD_MISMATCH, USERNAME_TAKEN } from '../../constants/feedback';
import { checkUsernameAvailability } from '../../services/api/signup';

const itemLayout = {
    labelCol: { span: 24 },
};

const EmpresaStepTwo = (props) => {
    const handleUsernameValidation = async (_, username) => {
        if (username && username.trim().length > 0) {
            const params = {
                id: 0,
                userName: username,
            };

            const response = await checkUsernameAvailability(params);

            if (!response.data) {
                return Promise.reject(USERNAME_TAKEN);
            }
        }

        return Promise.resolve(true);
    };

    return (
        <>
            <div className='row'>
                <div className='col-md-4'>
                    <Form.Item
                        name='nombre'
                        label='Nombre'
                        {...itemLayout}
                        rules={[{ required: true, whitespace: true }]}
                    >
                        <Input maxLength={45} allowClear />
                    </Form.Item>
                </div>

                <div className='col-md-4'>
                    <Form.Item
                        name='apellidoPaterno'
                        label='Apellido paterno'
                        {...itemLayout}
                        rules={[{ required: true, whitespace: true }]}
                    >
                        <Input maxLength={45} allowClear />
                    </Form.Item>
                </div>

                <div className='col-md-4'>
                    <Form.Item
                        name='apellidoMaterno'
                        label='Apellido materno'
                        {...itemLayout}
                        rules={[{ whitespace: true }]}
                    >
                        <Input maxLength={45} allowClear />
                    </Form.Item>
                </div>

                <div className='col-md-4'>
                    <Form.Item name='cargoResponsable' label='Cargo' {...itemLayout} rules={[{ whitespace: true }]}>
                        <Input maxLength={100} allowClear />
                    </Form.Item>
                </div>

                <div className='col-md-4'>
                    <Form.Item
                        name='correoContacto'
                        label='Correo'
                        {...itemLayout}
                        rules={[
                            { required: true, whitespace: true },
                            {
                                type: 'email',
                            },
                        ]}
                    >
                        <Input maxLength={100} allowClear />
                    </Form.Item>
                </div>

                <div className='col-md-4'>
                    <Form.Item
                        name='telefonoResponsable'
                        label='Teléfono'
                        {...itemLayout}
                        rules={[{ whitespace: true }]}
                    >
                        <Input maxLength={45} allowClear />
                    </Form.Item>
                </div>

                <div className='col-md-4'>
                    <Form.Item
                        name='username'
                        label='Usuario'
                        {...itemLayout}
                        validateFirst={true}
                        validateTrigger='onBlur'
                        rules={[
                            { required: true, whitespace: true },
                            () => ({
                                validator: handleUsernameValidation,
                            }),
                        ]}
                    >
                        <Input maxLength={45} allowClear />
                    </Form.Item>
                </div>

                <div className='col-md-4'>
                    <Form.Item
                        name='password'
                        label='Contraseña'
                        {...itemLayout}
                        rules={[{ required: true, whitespace: true }]}
                    >
                        <Input.Password maxLength={100} allowClear />
                    </Form.Item>
                </div>

                <div className='col-md-4'>
                    <Form.Item
                        name='confirmPassword'
                        label='Repetir contraseña'
                        {...itemLayout}
                        rules={[
                            { required: true, whitespace: true },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error(PASSWORD_MISMATCH));
                                },
                            }),
                        ]}
                    >
                        <Input.Password maxLength={100} allowClear />
                    </Form.Item>
                </div>

                <div className='col-md-12'>
                    <br />
                    <p>
                        <b>
                            <span style={{ color: '#ff4d4f' }}>*</span> Son campos obligatorios
                        </b>
                    </p>
                </div>
            </div>
        </>
    );
};

export default EmpresaStepTwo;
