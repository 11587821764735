import React, { useEffect, useMemo } from 'react';
import { Modal, Form, Input, Select, Row, Col, Spin } from 'antd';
import { checkAmbitoAvailability } from '../../../services/api/ambito';
import { AMBITO_TAKEN } from '../../../constants/feedback';

const { Option } = Select;

export const CRUDAmbitoForm = ({ openModal, onCancel, onSubmit, model, estados, isPosting, leyNormativas }) => {
    const [form] = Form.useForm();
    const formFields = useMemo(
        () => (model ? Object.keys(model).map((key) => ({ name: key, value: model[key] })) : []),
        [model]
    );

    useEffect(() => {
        form.setFields(formFields);
    }, [formFields, form]);

    const handleSubmit = async (values) => {
        await onSubmit?.({ ...values, ...(model?.id && { id: model.id }) });

        form.resetFields();
    };

    const handleCancel = () => {
        form.resetFields();
        onCancel?.();
    };

    const handleNombreValidation = async (_, nombre) => {
        if (nombre && nombre.trim().length > 0) {
            const params = {
                id: model?.id ?? 0,
                nombre,
                idLeyNormativa: form.getFieldValue('idLeyNormativa') ?? 0,
            };

            const response = await checkAmbitoAvailability(params);

            if (!response.data) {
                return Promise.reject(AMBITO_TAKEN);
            }
        }

        return Promise.resolve(true);
    };

    return (
        <Modal title='Crear o Modificar Ámbito' visible={openModal} onOk={form.submit} onCancel={handleCancel}>
            <Spin spinning={isPosting}>
                <Form form={form} onFinish={handleSubmit} layout='vertical'>
                    <Row>
                        <Col span={10}>
                            <Form.Item
                                name='nombre'
                                label='Nombre'
                                rules={[
                                    { required: true, whitespace: true },
                                    () => ({
                                        validator: handleNombreValidation,
                                    }),
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={2} />
                        <Col span={10}>
                            <Form.Item name='activo' label='Estado Activo' rules={[{ required: true }]}>
                                <Select>
                                    {estados?.map((estado, i) => (
                                        <Select.Option key={i} value={estado.id}>
                                            {estado.nombre}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={22}>
                            <Form.Item name='idLeyNormativa' label='Ley normativa' rules={[{ required: true }]}>
                                <Select className='w-100' allowClear>
                                    {leyNormativas?.map((ley) => (
                                        <Option key={ley.id} value={ley.id}>
                                            {ley.nombre}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        </Modal>
    );
};
