import { useEffect } from 'react';
import { Form, Input } from 'antd';
import useEmpresa from '../../hooks/use-empresa';
import { PASSWORD_MISMATCH, USERNAME_TAKEN } from '../../constants/feedback';
import { checkUsernameAvailability } from '../../services/api/signup';
import { LETTERS_SPACE_ONLY_PATTERN, NUMBERS_ONLY_PATTERN } from '../../constants/regex-pattern';

const itemLayout = {
    labelCol: { span: 24 },
};

const EmpresaStepTwo = (props) => {
    const { form } = props;

    const empresaContext = useEmpresa();

    useEffect(() => {
        if (empresaContext.id > 0) {
            const values = {
                nombre: empresaContext.nombre,
                apellidoPaterno: empresaContext.apellidoPaterno,
                apellidoMaterno: empresaContext.apellidoMaterno,
                cargoResponsable: empresaContext.cargoResponsable,
                correoContacto: empresaContext.correoContacto,
                telefonoResponsable: empresaContext.telefonoResponsable,
                username: empresaContext.username,
                password: empresaContext.password,
                confirmPassword: empresaContext.password,
                direccion: empresaContext.direccion,
            };

            form.setFieldsValue(values);
        }
    }, [form, empresaContext]);

    const handleUsernameValidation = async (_, username) => {
        if (username && username.trim().length > 0) {
            const params = {
                username,
                id: empresaContext.idUsuarioResponsable ?? 0,
            };

            const response = await checkUsernameAvailability(params);

            if (!response.data) {
                return Promise.reject(USERNAME_TAKEN);
            }
        }

        return Promise.resolve(true);
    };

    return (
        <>
            <div className='row'>
                <div className='col-md-4'>
                    <Form.Item
                        name='nombre'
                        label='Nombre'
                        {...itemLayout}
                        rules={[
                            { required: true, whitespace: true },
                            { pattern: LETTERS_SPACE_ONLY_PATTERN, message: 'Nombre no es válido' },
                        ]}
                    >
                        <Input maxLength={45} allowClear />
                    </Form.Item>
                </div>

                <div className='col-md-4'>
                    <Form.Item
                        name='apellidoPaterno'
                        label='Apellido paterno'
                        {...itemLayout}
                        rules={[
                            { required: true, whitespace: true },
                            { pattern: LETTERS_SPACE_ONLY_PATTERN, message: 'Apellido paterno no es válido' },
                        ]}
                    >
                        <Input maxLength={45} allowClear />
                    </Form.Item>
                </div>

                <div className='col-md-4'>
                    <Form.Item
                        name='apellidoMaterno'
                        label='Apellido materno'
                        {...itemLayout}
                        rules={[
                            { whitespace: true },
                            { pattern: LETTERS_SPACE_ONLY_PATTERN, message: 'Apellido materno no es válido' },
                        ]}
                    >
                        <Input maxLength={45} allowClear />
                    </Form.Item>
                </div>

                <div className='col-md-4'>
                    <Form.Item
                        name='cargoResponsable'
                        label='Cargo'
                        {...itemLayout}
                        rules={[
                            { whitespace: true },
                            { pattern: LETTERS_SPACE_ONLY_PATTERN, message: 'Cargo no es válido' },
                        ]}
                    >
                        <Input maxLength={100} allowClear />
                    </Form.Item>
                </div>

                <div className='col-md-4'>
                    <Form.Item
                        name='correoContacto'
                        label='Correo'
                        {...itemLayout}
                        rules={[
                            { required: true, whitespace: true },
                            {
                                type: 'email',
                            },
                        ]}
                    >
                        <Input maxLength={100} allowClear />
                    </Form.Item>
                </div>

                <div className='col-md-4'>
                    <Form.Item
                        name='telefonoResponsable'
                        label='Teléfono'
                        {...itemLayout}
                        rules={[
                            { whitespace: true },
                            { pattern: NUMBERS_ONLY_PATTERN, message: 'Teléfono solo acepta números' },
                        ]}
                    >
                        <Input maxLength={45} allowClear />
                    </Form.Item>
                </div>

                <div className='col-md-4'>
                    <Form.Item
                        name='username'
                        label='Usuario'
                        {...itemLayout}
                        rules={[
                            { required: true, whitespace: true },
                            () => ({
                                validator: handleUsernameValidation,
                            }),
                        ]}
                    >
                        <Input maxLength={45} allowClear />
                    </Form.Item>
                </div>

                {empresaContext.id === 0 && (
                    <>
                        <div className='col-md-4'>
                            <Form.Item
                                name='password'
                                label='Contraseña'
                                {...itemLayout}
                                rules={[{ required: true, whitespace: true }]}
                            >
                                <Input.Password maxLength={100} allowClear />
                            </Form.Item>
                        </div>

                        <div className='col-md-4'>
                            <Form.Item
                                name='confirmPassword'
                                label='Repetir contraseña'
                                {...itemLayout}
                                rules={[
                                    { required: true, whitespace: true },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue('password') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error(PASSWORD_MISMATCH));
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password maxLength={100} allowClear />
                            </Form.Item>
                        </div>
                    </>
                )}
            </div>
            <div className='col-md-12'>
                <br />
                <p>
                    <b>
                        <span style={{ color: '#ff4d4f' }}>*</span> Son campos obligatorios
                    </b>
                </p>
            </div>
        </>
    );
};

export default EmpresaStepTwo;
