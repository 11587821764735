import axios from '../../lib/axios';

export const TAMANO_EMPRESAS_ENDPOINT = '/tamanoempresas';

export const TAMANO_EMPRESAS_DDL_ENDPOINT = `${TAMANO_EMPRESAS_ENDPOINT}/drop-down-list`;

export const getAllTamanoEmpresasForDropDowList = async () => {
    const response = await axios.get(TAMANO_EMPRESAS_DDL_ENDPOINT);

    return response;
};
