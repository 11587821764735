import { useState } from 'react';
import { Button, Dropdown, Menu, Popconfirm, Space } from 'antd';
import { ArrowDownOutlined, ArrowUpOutlined, MoreOutlined } from '@ant-design/icons';
import { DELETE_CONFIRMATION_MESSAGE } from '../../../constants/feedback';

const menu = (handleDeleteClick) => (
    <Menu
        items={[
            {
                key: '1',
                label: 'Eliminar',
                onClick: handleDeleteClick,
            },
        ]}
    />
);

const Toolbar = (props) => {
    const { pregunta, total, onDelete, onUp, onDown } = props;
    const [isOpen, setIsOpen] = useState(false);

    const handleUpClick = () => {
        onUp(pregunta);
    };

    const handleDownClick = () => {
        onDown(pregunta);
    };

    const handleDeleteClick = () => {
        setIsOpen(true);
    };

    const handleDeleteConfirm = () => {
        setIsOpen(false);
        onDelete(pregunta);
    };

    const handleDeleteCancel = () => {
        setIsOpen(false);
    };

    return (
        <Space>
            {pregunta.posicion < total && (
                <Button type='primary' shape='circle' icon={<ArrowDownOutlined />} onClick={handleDownClick} />
            )}

            {pregunta.posicion > 1 && (
                <Button type='primary' shape='circle' icon={<ArrowUpOutlined />} onClick={handleUpClick} />
            )}

            <Popconfirm
                title={DELETE_CONFIRMATION_MESSAGE}
                open={isOpen}
                onCancel={handleDeleteCancel}
                onConfirm={handleDeleteConfirm}
            >
                <Dropdown
                    overlay={menu(handleDeleteClick)}
                    placement='top'
                    arrow={{
                        pointAtCenter: true,
                    }}
                >
                    <Button type='link' icon={<MoreOutlined style={{ fontSize: '32px' }} />} />
                </Dropdown>
            </Popconfirm>
        </Space>
    );
};

export default Toolbar;
