import { useEffect } from 'react';
import { Button, Form, Skeleton, Tabs, Tooltip } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import { Link, useParams } from 'react-router-dom';
import useSWRImmutable from 'swr/immutable';
import useCuestionario from '../../hooks/use-cuestionario';
import { v4 as uuidv4 } from 'uuid';
import { CUESTIONARIOS_ENDPOINT, getCuestionarioById } from '../../services/api/cuestionarios';
import CuestionarioStepOne from './cuestionario-step-one';
import CuestionarioStepTwo from './cuestionario-step-two';
import { validateMessages } from '../../constants/feedback';

const CuestionarioItemPage = () => {
    const cuestionarioContext = useCuestionario();
    let { id } = useParams();

    const [form] = Form.useForm();

    const { data: cuestionarioData, error: cuestionarioError } = useSWRImmutable(
        id > 0 ? [CUESTIONARIOS_ENDPOINT, id] : null,
        (_, id) => getCuestionarioById(id),
        {
            revalidateOnMount: true,
        }
    );
    let isLoading = !cuestionarioData && !cuestionarioError && id > 0;

    useEffect(() => {
        if (cuestionarioData) {
            const newSecciones = cuestionarioData.data.secciones.map((seccion) => {
                const uuidSeccion = uuidv4();

                return {
                    ...seccion,
                    uuid: uuidSeccion,
                    preguntas: seccion.preguntas.map((pregunta) => {
                        const uuidPregunta = uuidv4();

                        return {
                            ...pregunta,
                            uuid: uuidPregunta,
                            uuidSeccion: uuidSeccion,
                            opciones: pregunta.opciones.map((opcion) => ({ ...opcion, uuid: uuidv4() })),
                        };
                    }),
                };
            });

            const params = { ...cuestionarioData.data, secciones: newSecciones };

            cuestionarioContext.fill(params);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cuestionarioData]);

    const handleFinishClick = (values) => {
        cuestionarioContext.save(values, true);
    };

    return (
        <>
            <div className='row pt-3 mb-3'>
                <div className='col-lg-12'>
                    <div className='card rounded-4 shadow'>
                        <h4 className='color-text pt-3 border-bottom mx-3'>
                            <div className='d-flex justify-content-between'>
                                <p>Cuestionarios</p>

                                <Link to={`/encuestas/${cuestionarioContext.id}/preview`}>
                                    <Tooltip title='Previsualizar'>
                                        <Button
                                            type='primary'
                                            icon={<EyeOutlined />}
                                            shape='circle'
                                            className='text-right'
                                        ></Button>
                                    </Tooltip>
                                </Link>
                            </div>
                        </h4>

                        <div className='card-body'>
                            <div className='row pl-2 pr-2'>
                                <Form
                                    form={form}
                                    onFinish={handleFinishClick}
                                    validateMessages={validateMessages}
                                    autoComplete='off'
                                >
                                    <Tabs
                                        defaultActiveKey='1'
                                        type='card'
                                        size='small'
                                        className='ps-3 pe-3'
                                        items={[
                                            {
                                                label: 'Configuración',
                                                key: 'step-one',
                                                children: isLoading ? (
                                                    <Skeleton active />
                                                ) : (
                                                    <CuestionarioStepOne form={form} />
                                                ),
                                                forceRender: true,
                                            },
                                            {
                                                label: 'Preguntas',
                                                key: 'step-two',
                                                children: <CuestionarioStepTwo form={form} />,
                                                forceRender: true,
                                                disabled: cuestionarioContext.id === 0,
                                            },
                                        ]}
                                    />
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CuestionarioItemPage;
