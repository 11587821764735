export const GENERIC_ERROR_MESSAGE = 'Ha ocurrido un error en la operación, por favor intente nuevamente';

export const GENERIC_SUCCESS_MESSAGE = 'Operación realizada con éxito';

export const DELETE_CONFIRMATION_MESSAGE = '¿Está seguro que desea eliminar?';

export const validateMessages = {
    // eslint-disable-next-line no-template-curly-in-string
    required: '${label} es requerido!',
    types: {
        // eslint-disable-next-line no-template-curly-in-string
        email: '${label} no es un correo válido!',
        // eslint-disable-next-line no-template-curly-in-string
        number: '${label} no es un número válido!',
        // eslint-disable-next-line no-template-curly-in-string
        url: '${label} no es una url válida!',
    },
    number: {
        // eslint-disable-next-line no-template-curly-in-string
        range: '${label} debe estar entre ${min} y ${max}',
    },
};

export const USERNAME_TAKEN = 'El nombre de usuario ya está en uso';

export const PASSWORD_MISMATCH = 'Las dos contraseñas que ingresó no coinciden!';

export const RUT_NOT_VALID = 'RUT no es válido!';

export const PREGUNTA_REQUIRED = 'Esta pregunta es requerida';

export const CUESTIONARIO_NOT_FOUND = 'Cuestionario no encontrado';

export const PODERACION_PREGUNTA_NOT_VALID = 'Ponderación debe ser igual a 100!';

export const SUM_PODERACION_EVALUACION_AMBITO_NOT_VALID = 'Suma de la ponderación de los ámbitos debe ser igual a 100!';

export const SUM_PODERACION_EVALUACION_SUBAMBITO_NOT_VALID =
    'Suma de la ponderación de los subámbitos debe ser igual a 100!';

export const FINISH_CUESTIONARIO_CONFIRMATION_MESSAGE = '¿Está seguro de finalizar el cuestionario?';

export const SUBAMBITO_TAKEN = 'El sub-ámbito ya está en uso';

export const AMBITO_TAKEN = 'El ámbito ya está en uso';

export const ABONARSE_CONFIRMATION_MESSAGE = 'Funcionalidad disponible solo para usuarios abonados. ¿Desea abonarse?';
