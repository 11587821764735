import { useEffect, useState } from 'react';
import Icon from '@ant-design/icons';
import useSWR from 'swr';
import { ReactComponent as IcoDocSvg } from '../../assets/image/ico-doc.svg';
import { ENCUESTAS_DISPONIBLES_ENDPOINT, getEncuestasDisponibles } from '../../services/api/encuestas';
import { Link } from 'react-router-dom';

const CuestionarioDisponibleList = () => {
    const [leyNormativas, setLeyNormativas] = useState([]);

    const { data: cuestionariosData } = useSWR(ENCUESTAS_DISPONIBLES_ENDPOINT, () => getEncuestasDisponibles());

    useEffect(() => {
        if (cuestionariosData?.data) {
            let uniqueLeyNormativas = cuestionariosData?.data.reduce((acc, cv) => {
                const item = acc.find((item) => item.id === cv.idLeyNormativa);

                if (!item) {
                    return [...acc, { id: cv.idLeyNormativa, nombre: cv.nombreLeyNormativa }];
                } else {
                    return acc;
                }
            }, []);

            uniqueLeyNormativas = uniqueLeyNormativas.map((ley) => {
                return {
                    ...ley,
                    cuestionarios: cuestionariosData.data.filter(
                        (cuestionario) => cuestionario.idLeyNormativa === ley.id
                    ),
                };
            });

            setLeyNormativas(uniqueLeyNormativas);
        }
    }, [cuestionariosData]);

    return (
        <>
            <div className='row pt-3'>
                <div className='col-lg-12'>
                    <div className='card rounded-4 shadow'>
                        <h4 className='color-text pt-3 border-bottom mx-3'>Bienvenido a tu cuestionario</h4>

                        <div className='card-body'>
                            <div className='row pl-2 pr-2'>
                                <div className='col-md-12 pt-2 pb-3'>
                                    <p>
                                        Conoce el nivel de cumplimiento en las exigencias normativas que rigen a tu
                                        empresa. Determina cuáles son sus principales fortalezas y sus principales
                                        brechas en el cumplimiento de sus obligaciones legales. Sólo así podrás conocer
                                        el verdadero nivel de exposición al riesgo de incumplimiento al cual está
                                        sometida la empresa. Como Exempla estamos disponibles para acompañarte en este
                                        proceso de mejora continua.
                                    </p>

                                    {leyNormativas.map((ley) => (
                                        <div key={ley.id}>
                                            <h4 className='color-text pt-3 border-bottom mx-3'>{ley.nombre}</h4>

                                            {ley.cuestionarios.map((cuestionario) => (
                                                <div className='px-3 py-2' key={cuestionario.id}>
                                                    {cuestionario.habilitado && (
                                                        <Link to={`/encuestas/${cuestionario.id}/new`}>
                                                            Cuestionario {cuestionario.nombre} -{' '}
                                                            {new Date(
                                                                cuestionario.fechaPublicacion
                                                            ).toLocaleDateString()}{' '}
                                                            {!cuestionario.habilitado ? '(usuarios abonados)' : ''}
                                                            <div className='float-end'>
                                                                {cuestionario.habilitado && (
                                                                    <Icon component={IcoDocSvg} />
                                                                )}
                                                            </div>
                                                        </Link>
                                                    )}

                                                    {!cuestionario.habilitado && (
                                                        <>
                                                            Cuestionario {cuestionario.nombre} -{' '}
                                                            {new Date(
                                                                cuestionario.fechaPublicacion
                                                            ).toLocaleDateString()}{' '}
                                                            {!cuestionario.habilitado ? '(solo suscriptores)' : ''}
                                                        </>
                                                    )}
                                                </div>
                                            ))}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CuestionarioDisponibleList;
