import React from 'react';
import { Form, Input, Select, Button, Space } from 'antd';
const itemLayout = {
    labelCol: { span: 24 },
};

export const FilterForm = (props) => {
    const [form] = Form.useForm();
    const { onSearch } = props;

    const handleReset = async () => {
        form.resetFields();
        onSearch?.(null);
    };

    const handleFinish = async (values) => {
        onSearch?.(values);
    };

    return (
        <Form form={form} onFinish={handleFinish}>
            <div className='row pl-2 pr-2'>
                <div className='col-md-4 pt-2 pb-3'>
                    <Form.Item name='nombre' label='Ámbito' {...itemLayout} rules={[{ whitespace: true }]}>
                        <Input className='w-100' />
                    </Form.Item>
                </div>
                <div className='col-md-4 pt-2 pb-3'>
                    <Form.Item name='activo' label='Estado Activo' {...itemLayout}>
                        <Select className='w-100'>
                            {props.estados?.map((estado) => (
                                <Select.Option key={estado.id} value={estado.id}>
                                    {estado.nombre}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </div>
                <div className='col-md-4 pt-2 pb-3'>
                    <Form.Item name='idLeyNormativa' label='Ley normativa' {...itemLayout}>
                        <Select className='w-100'>
                            {props.leyNormativas?.map((leyNormativa) => (
                                <Select.Option key={leyNormativa.id} value={leyNormativa.id}>
                                    {leyNormativa.nombre}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </div>
                <div className='col-md-12 text-end'>
                    <Form.Item>
                        <Space>
                            <Button type='primary' htmlType='submit' block>
                                Filtrar
                            </Button>
                            <Button onClick={handleReset} block>
                                Limpiar
                            </Button>
                        </Space>
                    </Form.Item>
                </div>
            </div>
        </Form>
    );
};
