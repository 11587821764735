import React from 'react';
import { Form, Input, Select, Button } from 'antd';
const itemLayout = {
    labelCol: { span: 24 },
};

export const FilterForm = (props) => {
    const [form] = Form.useForm();
    const { onSearch } = props;

    const handleReset = async () => {
        form.resetFields();
        onSearch?.(null);
    };

    const handleFinish = async (values) => {
        onSearch?.(values);
    };

    return (
        <Form form={form} onFinish={handleFinish}>
            <div className='row pl-2 pr-2'>
                <div className='col-md-3 pt-2 pb-3'>
                    <Form.Item name='idAmbito' label='Ámbito' {...itemLayout}>
                        <Select className='w-100'>
                            {props.ambitos?.map((ambito, i) => (
                                <Select.Option key={i} value={ambito.idAmbito}>
                                    {ambito.nombre}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </div>
                <div className='col-md-3 pt-2 pb-3'>
                    <Form.Item name='nombre' label='Sub-Ámbito' {...itemLayout} rules={[{ whitespace: true }]}>
                        <Input className='w-100' />
                    </Form.Item>
                </div>
                <div className='col-md-3 pt-2 pb-3'>
                    <Form.Item name='activo' label='Estado Activo' {...itemLayout}>
                        <Select className='w-100'>
                            {props.estados?.map((estado) => (
                                <Select.Option key={estado.id} value={estado.id}>
                                    {estado.nombre}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </div>

                <div className='col-md-auto pt-2 pb-3'>
                    <Form.Item>
                        <Button type='primary' htmlType='submit' block style={{ marginTop: '39px' }}>
                            Filtrar
                        </Button>
                    </Form.Item>
                </div>
                <div className='col-md-auto pt-2 pb-3'>
                    <Form.Item>
                        <Button onClick={handleReset} block style={{ marginTop: '39px' }}>
                            Limpiar
                        </Button>
                    </Form.Item>
                </div>
            </div>
        </Form>
    );
};
