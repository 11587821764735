import { createContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from '../hooks/use-local-storage';

const AuthContext = createContext({
    user: {},
    login: (data) => {},
    logout: () => {},
});

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useLocalStorage('user', null);
    const navigate = useNavigate();

    const login = async (data) => {
        setUser(data);
        navigate('/', { replace: true });
    };

    const logout = () => {
        setUser(null);
        navigate('/login', { replace: true });
    };

    const context = useMemo(
        () => ({
            user,
            login,
            logout,
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [user]
    );

    return <AuthContext.Provider value={context}>{children}</AuthContext.Provider>;
};

export default AuthContext;
