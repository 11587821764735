import React, { useState, useRef } from 'react';
import { Button, message } from 'antd';
import { ESTADO_AMBITO_ENDPOINT, getAllEstadoAmbitos } from '../../services/api/enumeraciones';
import useSWRImmutable from 'swr/immutable';
import { Card } from '../../components/card';
import { DataTable } from '../../components/form/datatable';
import { FilterForm } from './components/filter-form';
import { AMBITOS_ENDPOINT, getAllAmbitos, deleteAmbito, createAmbito, updateAmbito } from '../../services/api/ambito';
import { GENERIC_SUCCESS_MESSAGE, GENERIC_ERROR_MESSAGE } from '../../constants/feedback';
import { getAllLeyNormativasForDropDowList, LEY_NORMATIVAS_DDL_ENDPOINT } from '../../services/api/ley-normativas';

import { CRUDAmbitoForm } from './components/crud-form';

const AmbitoList = () => {
    const { data: estadosAmbitoData } = useSWRImmutable(ESTADO_AMBITO_ENDPOINT, () => getAllEstadoAmbitos());

    const { data: leyNormativasData } = useSWRImmutable(LEY_NORMATIVAS_DDL_ENDPOINT, () =>
        getAllLeyNormativasForDropDowList()
    );

    const [isPosting, setIsPosting] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedModel, setSelectedModel] = useState(null);
    const [filters, setFilters] = useState({
        sortField: 'nombre',
        sortOrder: 'ascend',
    });

    const dataTable = useRef();

    const estados = estadosAmbitoData?.data
        ?.sort((a, b) => a.id - b.id)
        .map((estado) => ({
            nombre: estado.nombre,
            id: estado.id === 0,
        }));

    const leyNormativas = leyNormativasData?.data?.map((leyNormativa) => ({
        nombre: leyNormativa.nombre,
        id: leyNormativa.id,
    }));

    const openModal = () => {
        setIsModalOpen(true);
    };
    const closeModal = () => {
        setIsModalOpen(false);
    };

    const onSubmit = async (form) => {
        setIsPosting(true);
        try {
            const { id } = form;
            if (id) {
                await updateAmbito(form);
            } else {
                await createAmbito(form);
            }
            dataTable.current.refresh();
            setIsPosting(false);
            message.success(GENERIC_SUCCESS_MESSAGE);
        } catch (e) {
            message.error(GENERIC_ERROR_MESSAGE);
        } finally {
            closeModal();
        }
    };
    const onCancel = () => {
        setSelectedModel(null);
        closeModal();
    };

    const onSearch = (params) => {
        setFilters({
            ...(params ? filters : { sortField: 'nombre', sortOrder: 'ascend' }),
            ...params,
        });

        if (!params) {
            dataTable.current.reset();
        }
    };

    return (
        <>
            <div className='row pt-3'>
                <div className='col-lg-12'>
                    <Card title='Mantenedor Ámbito'>
                        <FilterForm onSearch={onSearch} estados={estados} leyNormativas={leyNormativas} />
                    </Card>
                </div>
            </div>
            <div className='row pt-5 pb-5'>
                <div className='col-lg-12'>
                    <Card title='Listado Ámbitos'>
                        <DataTable
                            ref={dataTable}
                            linkTo='/cuestionarios'
                            endpoint={{
                                path: AMBITOS_ENDPOINT,
                                getItems: getAllAmbitos,
                                deleteItem: (model) => deleteAmbito(model.id),
                            }}
                            dataFilters={filters}
                            columns={[
                                { name: 'id', title: 'ID' },
                                { name: 'nombre', sorter: true },
                                {
                                    name: 'activo',
                                    title: 'Estado',
                                    sorter: true,
                                    render: (estado) => (estado ? 'Activo' : 'No Activo'),
                                },
                                { name: 'nombreLeyNormativa', title: 'Ley normativa', sorter: true },
                            ]}
                            actions={{
                                deleteable: true,
                                editable: true,
                                handleEdit: (model) => {
                                    setSelectedModel(model);
                                    openModal();
                                },
                            }}
                        ></DataTable>
                    </Card>
                </div>
                <div className='col-lg-12 text-end mt-3'>
                    <Button type='primary' onClick={() => openModal()}>
                        Nuevo Ámbito
                    </Button>
                </div>
            </div>
            <CRUDAmbitoForm
                openModal={isModalOpen}
                onCancel={onCancel}
                onSubmit={onSubmit}
                model={selectedModel}
                estados={estados}
                isPosting={isPosting}
                leyNormativas={leyNormativas}
            />
        </>
    );
};

export default AmbitoList;
