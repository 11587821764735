import { useContext } from 'react';
import EmpresaContext from '../contexts/empresa-context';

const useEmpresa = () => {
    const context = useContext(EmpresaContext);

    if (context === undefined) {
        throw new Error('useEmpresa must be used within a EmpresaContext');
    }

    return context;
};

export default useEmpresa;
