import React from 'react';
import { Button, Skeleton } from 'antd';
import { useParams, useNavigate } from 'react-router-dom';
import useSWRImmutable from 'swr/immutable';
import { getResultadoEncuestaById, RESULTADO_ENCUESTA_ENDPOINT } from '../../services/api/encuestas';
import logo from '../../assets/image/exempla-azul.png';
import semaforo1 from '../../assets/image/semaforo1.png';
import semaforoRojo from '../../assets/image/semaforo-rojo.png';
import semaforoNaranjo from '../../assets/image/semaforo-naranja.png';
import semaforoAmarillo from '../../assets/image/semaforo-amarillo.png';
import semaforoVerde from '../../assets/image/semaforo-verde.png';
import { SEMAFORO_AMARILLO, SEMAFORO_NARANJO, SEMAFORO_ROJO, SEMAFORO_VERDE } from '../../constants/semaforo';

const ResultadoEncuestaItemPage = () => {
    let { id } = useParams();
    const navigate = useNavigate();

    const { data: resultadoData, error } = useSWRImmutable(id > 0 ? [RESULTADO_ENCUESTA_ENDPOINT, id] : null, (_, id) =>
        getResultadoEncuestaById(id)
    );
    let isLoading = !resultadoData && !error;

    return (
        <>
            <div className='row pt-3'>
                <div className='col-md-6 pb-2'>
                    <ul className='color-text fw-bold'>
                        <li>Nombre de la empresa: {resultadoData?.data.razonSocial}</li>
                        <li>Representante de la empresa: {resultadoData?.data.nombreUsuarioEmpresa}</li>
                        <li>
                            Fecha:{' '}
                            {resultadoData
                                ? new Date(resultadoData.data.fechaProcesamiento).toLocaleDateString()
                                : null}{' '}
                        </li>
                    </ul>
                </div>
                <div className='col-md-6 pb-2 text-end'>
                    <img className='w-50' src={logo} alt='Exempla' />
                </div>
                <div className='col-lg-12'>
                    <div className='card rounded-4 shadow'>
                        <div className='card-body'>
                            <div className='row pl-2 pr-2'>
                                <div className='col-md-12  pt-2  bg-menu'>
                                    <h4 className='text-white  text-uppercase text-center'>
                                        Informe de Autoevaluación en Protección de Datos Personales
                                    </h4>
                                </div>
                                <div className='col-md-3 bg-exampla1 border'>
                                    <h6 className='text-white p-2'>Ámbito</h6>
                                </div>
                                <div className='col-md-3 bg-exampla2 border'>
                                    <h6 className='text-white  p-2'>Sub-Ámbito</h6>
                                </div>
                                <div className='col-md-3 bg-exampla border'>
                                    <h6 className='color-text p-2 text-center'>
                                        {' '}
                                        Nivel de Cumplimiento por Sub-ámbito
                                    </h6>
                                </div>
                                <div className='col-md-3 bg-exampla text-center border'>
                                    <h6 className='color-text p-2'> Nivel de Cumplimiento por Ámbito</h6>
                                </div>

                                {isLoading && <Skeleton active className='pt-3' />}

                                {resultadoData?.data.ambitos.map((ambito) => (
                                    <React.Fragment key={ambito.id}>
                                        <div className='col-md-3 bg-exampla  bg-box1 border d-flex align-items-center'>
                                            <h6 className='color-text p-2'>{ambito.nombre}</h6>
                                        </div>
                                        <div className='col-md-3 bg-exampla bg-box2 border pt-2'>
                                            <ul>
                                                {ambito.subambitos.map((subambito, index) => (
                                                    <li key={index}>{subambito.nombre}</li>
                                                ))}
                                            </ul>
                                        </div>
                                        <div className='col-md-3 bg-exampla bg-box2 border text-center pt-2 d-flex align-items-center justify-content-center'>
                                            <ul className='fw-bold' style={{ listStyle: 'none', padding: '0px' }}>
                                                {ambito.subambitos.map((subambito, index) => (
                                                    <li key={index}>{subambito.puntaje}%</li>
                                                ))}
                                            </ul>
                                        </div>
                                        <div
                                            className='col-md-3 bg-exampla text-center bg-box2 border d-flex align-items-center justify-content-center'
                                            style={{ fontSize: '25px', fontWeight: 'bold' }}
                                        >
                                            {ambito.puntaje}%
                                        </div>
                                    </React.Fragment>
                                ))}

                                <div className='col-lg-12 pt-3'>
                                    <p className='text-center'>
                                        Su empresa cumple con el <strong>{resultadoData?.data.puntaje}% </strong>en
                                        materia de estándares normativos y buenas prácticas en protección de datos
                                        personales, lo que lo sitúa en una situación:
                                    </p>
                                </div>

                                <div className='col-lg-6 pt-3 text-center'>
                                    <div>
                                        <img className='img-fluid' src={semaforo1} alt='semáforo' />
                                    </div>
                                </div>

                                <div className='col-lg-6 pt-3  d-flex align-items-center justify-content-center'>
                                    <div className='z-index-1 position-absolute pt-3'>
                                        <h3 className='fw-bold text-black'>{resultadoData?.data.puntaje}%</h3>
                                    </div>

                                    <div>
                                        {isLoading && <Skeleton.Avatar active={true} size={80} shape='circle' />}

                                        {resultadoData?.data.idSemaforo === SEMAFORO_ROJO && (
                                            <img src={semaforoRojo} width='300' alt='semáforo' />
                                        )}

                                        {resultadoData?.data.idSemaforo === SEMAFORO_NARANJO && (
                                            <img src={semaforoNaranjo} width='300' alt='semáforo' />
                                        )}

                                        {resultadoData?.data.idSemaforo === SEMAFORO_AMARILLO && (
                                            <img src={semaforoAmarillo} width='300' alt='semáforo' />
                                        )}

                                        {resultadoData?.data.idSemaforo === SEMAFORO_VERDE && (
                                            <img src={semaforoVerde} width='300' alt='semáforo' />
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className='row pl-2 pr-2'>
                                <div className='col-md-12  pt-2  bg-menu'>
                                    <h4 className='text-white  text-uppercase text-center'>
                                        Informe detallado de retroalimentación
                                    </h4>
                                </div>

                                {resultadoData?.data.ambitos.map((ambito) => (
                                    <React.Fragment key={ambito.id}>
                                        <div className='col-md-12 bg-exampla1 border'>
                                            <h6 className='text-white p-2'>Ámbito: {ambito.nombre}</h6>
                                        </div>
                                        <div className='col-md-6 bg-exampla  bg-box3 border'>
                                            <ul>
                                                {ambito.fortalezas.map((fortaleza, index) => (
                                                    <li key={index}>{fortaleza}</li>
                                                ))}
                                            </ul>
                                        </div>
                                        <div className='col-md-6 bg-exampla  bg-box3 border'>
                                            <ul>
                                                {ambito.debilidades.map((debilidad, index) => (
                                                    <li key={index}>{debilidad}</li>
                                                ))}
                                            </ul>
                                        </div>

                                        {ambito.subambitos.map(
                                            (subambito) =>
                                                (subambito.fortalezas.length > 0 ||
                                                    subambito.debilidades.length > 0) && (
                                                    <React.Fragment key={subambito.id}>
                                                        <div className='col-md-12 bg-exampla2 border'>
                                                            <h6 className='text-white p-2'>
                                                                Sub-Ámbito: {subambito.nombre}
                                                            </h6>
                                                        </div>
                                                        <div className='col-md-6 bg-exampla  bg-box3 border'>
                                                            <ul>
                                                                {subambito.fortalezas.map((fortaleza, index) => (
                                                                    <li key={index}>{fortaleza}</li>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                        <div className='col-md-6 bg-exampla  bg-box3 border'>
                                                            <ul>
                                                                {subambito.debilidades.map((debilidad, index) => (
                                                                    <li key={index}>{debilidad}</li>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                    </React.Fragment>
                                                )
                                        )}
                                    </React.Fragment>
                                ))}
                                <div className='col-md-12 bg-exampla border'>
                                    <p className='fst-italic'>
                                        "El software y criterios empleados para orientar esta evaluación han sido
                                        desarrollados por Exempla y son de su propiedad. Tratándose de un proceso de
                                        autoevaluación, es el usuario o cliente quien ha proporcionado e ingresado la
                                        información que ha servido de base para el presente análisis y que fundamenta
                                        sus conclusiones, por lo que la completitud, veracidad y autenticidad de la
                                        misma no es responsabilidad de Exempla, que no ha podido ni podría verificarla.
                                        Sin perjuicio de lo anterior, cualquiera sea el resultado, éste no acredita ni
                                        certifica el cumplimiento o incumplimiento de las disposiciones legales
                                        aplicables y la información, conclusiones o diagnóstico no puede ser publicado o
                                        difundido por ningún medio masivo ni tampoco comentarios alusivos a su
                                        contenido, dado que resulta ser información no elaborada para su difusión
                                        general. El nivel de cumplimiento informado da cuenta, conforme los criterios
                                        definidos por Exempla, de la existencia de avances y brechas en materia de
                                        cumplimiento de estándares normativos y buenas prácticas de protección de datos
                                        personales y tiene por objeto orientar al usuario o cliente en el proceso
                                        avanzar en la adopción de un ambiente de gestión que resguarde adecuadamente los
                                        datos personales cuyo tratamiento se encuentra bajo su responsabilidad. Es
                                        responsabilidad de la organización resolver las brechas identificadas con un
                                        plan de acción específico.
                                    </p>

                                    <p className='fst-italic'>
                                        La información que Exempla recoja por esta vía será tratada conforme su Política
                                        de Privacidad.
                                    </p>

                                    <p className='fst-italic'>
                                        Una vez realizado el proceso de evaluación Exempla está en condiciones de
                                        ofrecer sus servicios profesionales para acompañar al usuario o cliente en su
                                        proceso de mejora o disminución total o parcial de brechas en materia de
                                        protección de datos personales, por lo que podrá solicitarnos una propuesta al
                                        efecto, comunicándose a través de nuestro de correo contacto@exempla.cl. También
                                        le invitamos a visitar nuestro sitio web www.exempla.cl”
                                    </p>
                                </div>
                                <div className='col-md-12  pt-2  bg-menu'>
                                    <h4 className='text-white  text-uppercase text-center'>
                                        Visualización de resultados
                                    </h4>
                                </div>
                                <div className='col-md-12 border'>
                                    {resultadoData?.data.graficos.map((grafico) => (
                                        <div className='pt-2 pb-2 text-center'>
                                            {grafico.showText && grafico.text}

                                            {!grafico.showText && (
                                                <img
                                                    src={`data:image/png;base64,${grafico.raw}`}
                                                    width='500'
                                                    alt='Gráfico'
                                                />
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-lg-12 text-center mt-3 mb-3'>
                    <Button type='primary' onClick={() => navigate(-1)}>
                        Cerrar
                    </Button>
                </div>
            </div>
        </>
    );
};

export default ResultadoEncuestaItemPage;
