import logExemplaAzulSvg from '../../assets/image/logo-exempla-azul.svg';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Form, Input, message } from 'antd';
import { GENERIC_ERROR_MESSAGE, validateMessages } from '../../constants/feedback';
import { resetPassword, passwordRecovery } from '../../services/api/auth';

const itemLayout = {
    labelCol: { span: 24 },
};

const RecoveryForm = () => {
    return (
        <div className='col-md-8 p-2 text-center m-auto'>
            <h5 className='color-text pt-3'>Recuperar Contraseña</h5>
            <div>
                <p>Por favor escribe tu nombre de usuario o correo electrónico.</p>
                <p>Recibirás una notificación por correo con un código de seguridad.</p>
            </div>
            <div className='pt-2 pb-3'>
                <Form.Item
                    name='username'
                    label='Usuario/Email'
                    {...itemLayout}
                    rules={[{ required: true, whitespace: true }]}
                >
                    <Input maxLength={45} allowClear />
                </Form.Item>
            </div>
        </div>
    );
};

const ResetPasswordForm = () => {
    return (
        <div className='col-md-8 p-2 text-center m-auto'>
            <h5 className='color-text pt-3'>Recuperar Contraseña</h5>

            <div>
                <Form.Item
                    name='recoveryCode'
                    label='Ingrese código de seguridad'
                    {...itemLayout}
                    rules={[{ required: true, whitespace: true }]}
                >
                    <Input maxLength={45} allowClear />
                </Form.Item>
            </div>
            <div>
                <Form.Item
                    name='newPassword'
                    label='Ingrese nueva contraseña'
                    {...itemLayout}
                    rules={[{ required: true, whitespace: true }]}
                >
                    <Input.Password maxLength={100} allowClear />
                </Form.Item>
            </div>
            <div>
                <Form.Item
                    name='newPasswordRepeat'
                    label='Repetir nueva contraseña'
                    {...itemLayout}
                    rules={[{ required: true, whitespace: true }]}
                >
                    <Input.Password maxLength={100} allowClear />
                </Form.Item>
            </div>
        </div>
    );
};

const RecoveryPage = () => {
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(false);
    const [username, setUsername] = useState(null);
    const [mode, setMode] = useState('recovery');

    const handleFinishRecovery = async (values) => {
        try {
            setLoading(true);

            await passwordRecovery(values);

            setMode('reset');

            setUsername(values.username);
        } catch (error) {
            if (error.response.status === 401) {
                message.error(error.response.data.Message);
            } else {
                message.error(GENERIC_ERROR_MESSAGE);
            }
        } finally {
            setLoading(false);
        }
    };

    const handleFinishReset = async (values) => {
        try {
            setLoading(true);
            await resetPassword({ username, ...values });

            message.success('Contraseña restablecida con exito').then(() => {
                navigate('/login', { replace: true });
            });
        } catch (error) {
            if (error.response.status === 401) {
                message.error(error.response.data.Message);
            } else {
                message.error(GENERIC_ERROR_MESSAGE);
            }
        } finally {
            setLoading(false);
        }
    };

    const RenderForm = () => {
        return mode === 'recovery' ? <RecoveryForm /> : <ResetPasswordForm />;
    };
    return (
        <div className='m-0 vh-100 row justify-content-center align-items-center'>
            <div className='col-md-6 m-auto'>
                <div className='card rounded-4 shadow'>
                    <div className='text-center'>
                        <div className='pt-2'>
                            <img className='img-fluid' src={logExemplaAzulSvg} width='250' alt='' />
                        </div>
                        <h5 className='color-text pt-3'>Bienvenidos al </h5>
                        <h3 className='color-text border-bottom mx-3'>Sistema de Diagnóstico Compliance</h3>
                    </div>
                    <div className='row pt-3'>
                        <Form
                            onFinish={mode === 'recovery' ? handleFinishRecovery : handleFinishReset}
                            validateMessages={validateMessages}
                            autoComplete='off'
                        >
                            <RenderForm />
                            <div className='col-lg-12 pb-3 text-center'>
                                <Button type='primary' htmlType='submit' loading={isLoading}>
                                    Ok
                                </Button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RecoveryPage;
