import axios from '../../lib/axios';

export const REGIONES_ENDPOINT = '/regiones';

export const REGIONES_DDL_ENDPOINT = `${REGIONES_ENDPOINT}/drop-down-list`;

export const getAllRegionesForDropDowList = async () => {
    const response = await axios.get(REGIONES_DDL_ENDPOINT);

    return response;
};

export const COMUNAS_BY_REGION_DDL_ENDPOINT = `${REGIONES_ENDPOINT}/{id}/comunas/drop-down-list`;

export const getComunasByRegionForDropDowList = async (idRegion) => {
    const response = await axios.get(`${REGIONES_ENDPOINT}/${idRegion}/comunas/drop-down-list`);

    return response;
};
