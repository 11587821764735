import { Button, Checkbox, Collapse, Divider, Dropdown, Form, Input, Menu, Select } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import useSWRImmutable from 'swr/immutable';
import { v4 as uuidv4 } from 'uuid';
import {
    AMBITOS_DDL_ENDPOINT,
    getAmbitosByLeyNormativaForDropDowList,
    getSubambitosByAmbitoForDropDowList,
    SUBAMBITOS_BY_AMBITO_DDL_ENDPOINT,
} from '../../../services/api/ambito';
import {
    TIPO_PREGUNTA_MULTIPLE_SELECCION_MULTIPLE,
    TIPO_PREGUNTA_MULTIPLE_SELECCION_UNICA,
    TIPO_PREGUNTA_SINO,
} from '../../../constants/tipo-pregunta';
import Pregunta from './pregunta';
import useCuestionario from '../../../hooks/use-cuestionario';
import { useEffect, useState } from 'react';
import { SUBAMBITO_TAKEN } from '../../../constants/feedback';

const { Panel } = Collapse;
const { Option } = Select;

const itemLayout = {
    labelCol: { span: 24 },
};

const Seccion = (props) => {
    const { seccion, form } = props;
    const cuestionarioContext = useCuestionario();
    const [idAmbitoWatch, setIdAmbitoWatch] = useState(0);

    const { data: ambitosData } = useSWRImmutable(
        cuestionarioContext.idLeyNormativa >= 0 ? [AMBITOS_DDL_ENDPOINT, cuestionarioContext.idLeyNormativa] : null,
        (_, idLeyNormativa) => getAmbitosByLeyNormativaForDropDowList(idLeyNormativa)
    );

    const { data: subambitosData } = useSWRImmutable(
        idAmbitoWatch >= 0 ? [SUBAMBITOS_BY_AMBITO_DDL_ENDPOINT, idAmbitoWatch] : null,
        (_, idAmbito) => getSubambitosByAmbitoForDropDowList(idAmbito)
    );

    useEffect(() => {
        setIdAmbitoWatch(seccion.idAmbito ?? 0);
    }, [seccion.idAmbito]);

    const menu = (seccion) => (
        <Menu
            items={[
                {
                    key: '1',
                    label: 'Si/No',
                    onClick: () => handleAddPreguntaClick(TIPO_PREGUNTA_SINO, seccion),
                },
                {
                    key: '2',
                    label: 'Múltiple con selección única',
                    onClick: () => handleAddPreguntaClick(TIPO_PREGUNTA_MULTIPLE_SELECCION_UNICA, seccion),
                },
                {
                    key: '3',
                    label: 'Múltiple con selección múltiple',
                    onClick: () => handleAddPreguntaClick(TIPO_PREGUNTA_MULTIPLE_SELECCION_MULTIPLE, seccion),
                },
            ]}
        />
    );

    const handleAddPreguntaClick = (idTipoPregunta, seccion) => {
        let cantidadOpciones = undefined;
        let opciones = [];

        if (idTipoPregunta === TIPO_PREGUNTA_SINO) {
            cantidadOpciones = 2;

            opciones.push(
                {
                    uuid: uuidv4(),
                    nombre: 'Si',
                    ponderacion: undefined,
                    posicion: 1,
                },
                {
                    uuid: uuidv4(),
                    nombre: 'No',
                    ponderacion: undefined,
                    posicion: 2,
                }
            );
        }

        const newPregunta = {
            uuid: uuidv4(),
            uuidSeccion: seccion.uuid,
            idSeccion: seccion.id,
            nombre: '',
            idTipoPregunta: idTipoPregunta,
            cantidadOpciones,
            posicion: Math.max(...seccion.preguntas.map((pregunta) => pregunta.posicion), 0) + 1,
            opciones,
        };

        const newSecciones = cuestionarioContext.secciones.map((item) =>
            item.uuid === seccion.uuid ? { ...item, preguntas: [...item.preguntas, newPregunta] } : item
        );

        cuestionarioContext.update({ ...cuestionarioContext, secciones: newSecciones });
    };

    const handlePreguntaChange = (pregunta) => {
        const seccion = cuestionarioContext.secciones.find((seccion) => seccion.uuid === pregunta.uuidSeccion);

        const newPreguntas = seccion.preguntas.map((item) =>
            item.uuid === pregunta.uuid ? { ...item, ...pregunta } : item
        );

        const newSecciones = cuestionarioContext.secciones.map((item) =>
            item.uuid === pregunta.uuidSeccion ? { ...item, preguntas: newPreguntas } : item
        );

        cuestionarioContext.update({ ...cuestionarioContext, secciones: newSecciones });
    };

    const handlePreguntaDelete = (pregunta) => {
        const seccion = cuestionarioContext.secciones.find((seccion) => seccion.uuid === pregunta.uuidSeccion);
        let newPreguntas = seccion.preguntas.filter((item) => item.uuid !== pregunta.uuid);

        let i = 0;
        newPreguntas = newPreguntas.map((item) => {
            i++;
            return { ...item, posicion: i };
        });

        const newSecciones = cuestionarioContext.secciones.map((item) =>
            item.uuid === seccion.uuid ? { ...item, preguntas: [...newPreguntas] } : item
        );

        cuestionarioContext.update({
            ...cuestionarioContext,
            secciones: newSecciones,
        });
    };

    const handlePreguntaUp = (pregunta) => {
        const seccion = cuestionarioContext.secciones.find((seccion) => seccion.uuid === pregunta.uuidSeccion);
        const index = seccion.preguntas.map((item) => item.uuid).indexOf(pregunta.uuid);

        let newPreguntas = reorderArray(index, index - 1, seccion.preguntas);
        newPreguntas = newPreguntas.map((item, index) => ({ ...item, posicion: index + 1 }));

        const newSecciones = cuestionarioContext.secciones.map((item) =>
            item.uuid === seccion.uuid ? { ...item, preguntas: [...newPreguntas] } : item
        );

        cuestionarioContext.update({
            ...cuestionarioContext,
            secciones: newSecciones,
        });
    };

    const handlePreguntaDown = (pregunta) => {
        const seccion = cuestionarioContext.secciones.find((seccion) => seccion.uuid === pregunta.uuidSeccion);
        const index = seccion.preguntas.map((item) => item.uuid).indexOf(pregunta.uuid);

        let newPreguntas = reorderArray(index, index + 1, seccion.preguntas);
        newPreguntas = newPreguntas.map((item, index) => ({ ...item, posicion: index + 1 }));

        const newSecciones = cuestionarioContext.secciones.map((item) =>
            item.uuid === seccion.uuid ? { ...item, preguntas: [...newPreguntas] } : item
        );

        cuestionarioContext.update({
            ...cuestionarioContext,
            secciones: newSecciones,
        });
    };

    const reorderArray = (oldIndex, newIndex, originalArray) => {
        const movedItem = originalArray.find((_, index) => index === oldIndex);
        const remainingItems = originalArray.filter((_, index) => index !== oldIndex);

        const reorderedItems = [...remainingItems.slice(0, newIndex), movedItem, ...remainingItems.slice(newIndex)];

        return reorderedItems;
    };

    const handleAmbitoChange = (value, seccion) => {
        form.setFieldValue(`idSubambito_${seccion.uuid}`, undefined);
        setIdAmbitoWatch(value ?? 0);
    };

    const handleAmbitoClear = (seccion) => {
        form.setFieldValue(`idSubambito_${seccion.uuid}`, undefined);
    };

    const genExtra = () => (
        <Form.Item
            name={`saltoPagina_${seccion.uuid}`}
            valuePropName='checked'
            initialValue={seccion.saltoPagina}
            style={{ margin: 0 }}
        >
            <Checkbox>Salto de página</Checkbox>
        </Form.Item>
    );

    return (
        <Collapse collapsible='header' defaultActiveKey={['1']}>
            <Panel
                header={`Sección ${seccion.posicion} de ${cuestionarioContext.secciones.length}`}
                key={'1'}
                extra={genExtra()}
                forceRender
            >
                <div className='row'>
                    <div className='col-md-12'>
                        <Form.Item
                            name={`descripcion_${seccion.uuid}`}
                            label='Descripcion de sección'
                            {...itemLayout}
                            rules={[{ whitespace: true }]}
                            initialValue={seccion.descripcion}
                        >
                            <Input.TextArea maxLength={500} allowClear showCount />
                        </Form.Item>
                    </div>

                    <div className='col-md-6'>
                        <Form.Item
                            name={`idAmbito_${seccion.uuid}`}
                            label='Ámbito'
                            {...itemLayout}
                            rules={[{ required: true }]}
                            initialValue={seccion.idAmbito}
                        >
                            <Select
                                className='w-100'
                                allowClear
                                onChange={(value) => handleAmbitoChange(value, seccion)}
                                onClear={() => handleAmbitoClear(seccion)}
                            >
                                {ambitosData?.data.map((ambito) => (
                                    <Option key={ambito.id} value={ambito.id}>
                                        {ambito.nombre}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </div>

                    <div className='col-md-6'>
                        <Form.Item
                            name={`idSubambito_${seccion.uuid}`}
                            label='Sub-ámbito'
                            {...itemLayout}
                            rules={[
                                { required: true },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        const otherSecciones = cuestionarioContext.secciones.filter(
                                            (item) => item.uuid !== seccion.uuid
                                        );

                                        let taken = false;

                                        for (const s of otherSecciones) {
                                            if (getFieldValue(`idSubambito_${s.uuid}`) === value) {
                                                taken = true;
                                                break;
                                            }
                                        }

                                        if (!taken) {
                                            return Promise.resolve();
                                        }

                                        return Promise.reject(new Error(SUBAMBITO_TAKEN));
                                    },
                                }),
                            ]}
                            initialValue={seccion.idSubambito}
                        >
                            <Select className='w-100' allowClear>
                                {subambitosData?.data.map((subAmbito) => (
                                    <Option key={subAmbito.id} value={subAmbito.id}>
                                        {subAmbito.nombre}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </div>
                </div>

                <Divider />

                {seccion.preguntas.map((pregunta) => (
                    <Pregunta
                        pregunta={pregunta}
                        total={seccion.preguntas.length}
                        key={pregunta.uuid}
                        onChange={handlePreguntaChange}
                        onDelete={handlePreguntaDelete}
                        onUp={handlePreguntaUp}
                        onDown={handlePreguntaDown}
                        form={form}
                    />
                ))}

                <Divider />

                <div className='row'>
                    <div className='col-md-12 text-end'>
                        <Dropdown
                            overlay={menu(seccion)}
                            placement='top'
                            arrow={{
                                pointAtCenter: true,
                            }}
                        >
                            <Button type='primary' icon={<PlusCircleOutlined />}>
                                Agregar pregunta
                            </Button>
                        </Dropdown>
                    </div>
                </div>
            </Panel>
        </Collapse>
    );
};

export default Seccion;
