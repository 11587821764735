import { message } from 'antd';
import { createContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSWRConfig } from 'swr';
import { GENERIC_ERROR_MESSAGE, GENERIC_SUCCESS_MESSAGE } from '../constants/feedback';
import { createEmpresa, EMPRESAS_ENDPOINT, updateEmpresa } from '../services/api/empresas';
import { clearRut } from '../utils/rut';

const initialState = {
    id: 0,
    rut: '',
    razonSocial: '',
    idRubroEmpresa: undefined,
    idTipoPersonaJuridica: undefined,
    idUsuarioResponsable: undefined,
    cargoResponsable: '',
    telefonoResponsable: '',
    idComuna: undefined,
    direccion: '',
    numDireccion: '',
    numDptoOf: '',
    urlSitioWeb: '',
    correoContacto: '',
    idTamanoEmpresa: undefined,
    actividadEmpresa: '',
    idRangoColaboradores: undefined,
    foda: '',
    principalesProductos: '',
    tecnologiasUtilizadas: '',
    usuarioAbonado: false,
    activo: false,
    pagos: [],
};

const EmpresaContext = createContext({
    ...initialState,
    fill: (params) => {},
    update: (params) => {},
    save: (params) => {},
});

export const EmpresaProvider = (props) => {
    const [empresa, setEmpresa] = useState(initialState);
    const { mutate } = useSWRConfig();
    const navigate = useNavigate();

    const handleFill = async (params) => {
        setEmpresa({ ...empresa, ...params });
    };

    const handleUpdate = (params) => {
        setEmpresa({ ...empresa, ...params });
    };

    const handleSave = async (params) => {
        try {
            const newEmpresa = {
                ...empresa,
                rut: clearRut(params.rut),
                razonSocial: params.razonSocial,
                idRubroEmpresa: params.idRubroEmpresa,
                idTipoPersonaJuridica: params.idTipoPersonaJuridica,
                cargoResponsable: params.cargoResponsable || null,
                telefonoResponsable: params.telefonoResponsable || null,
                idComuna: params.idComuna,
                direccion: params.direccion || null,
                numDireccion: params.numDireccion || null,
                numDptoOf: params.numDptoOf || null,
                urlSitioWeb: params.urlSitioWeb || null,
                correoContacto: params.correoContacto || null,
                idTamanoEmpresa: params.idTamanoEmpresa,
                actividadEmpresa: params.actividadEmpresa || null,
                idRangoColaboradores: params.idRangoColaboradores || null,
                foda: params.foda || null,
                principalesProductos: params.principalesProductos || null,
                tecnologiasUtilizadas: params.tecnologiasUtilizadas || null,
                usuarioAbonado: params.usuarioAbonado ?? false,
                activo: params.activo ?? false,
                nombre: params.nombre,
                apellidoPaterno: params.apellidoPaterno,
                apellidoMaterno: params.apellidoMaterno,
                username: params.username,
                password: params.password,
                pagos: [],
            };

            if (empresa.id > 0) {
                await updateEmpresa(newEmpresa);

                mutate([EMPRESAS_ENDPOINT, empresa.id.toString()]);
            } else {
                const response = await createEmpresa(newEmpresa);

                setEmpresa({ ...empresa, id: response.data });
            }

            navigate(`/empresas`);

            message.success(GENERIC_SUCCESS_MESSAGE);
        } catch (error) {
            message.error(GENERIC_ERROR_MESSAGE);
        }
    };

    const context = {
        ...empresa,
        fill: handleFill,
        update: handleUpdate,
        save: handleSave,
    };

    return <EmpresaContext.Provider value={context}>{props.children}</EmpresaContext.Provider>;
};

export default EmpresaContext;
