import { Checkbox, Form, Input, InputNumber, Radio, Select } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { v4 as uuidv4 } from 'uuid';
import { PODERACION_PREGUNTA_NOT_VALID } from '../../../constants/feedback';
import { TIPO_PREGUNTA_MULTIPLE_SELECCION_UNICA, TIPO_PREGUNTA_SINO } from '../../../constants/tipo-pregunta';
import Toolbar from './toolbar';

const { Option } = Select;

const itemLayout = {
    labelCol: { span: 24 },
};

const MAX_OPCIONES = 20;

const PreguntaSiNo = (props) => {
    const { pregunta, total, onDelete, onUp, onDown } = props;

    const handleDelete = () => {
        onDelete(pregunta);
    };

    const handleUpClick = (pregunta) => {
        onUp(pregunta);
    };

    const handleDownClick = (pregunta) => {
        onDown(pregunta);
    };

    return (
        <>
            <div className='row'>
                <div className='col-md-12 text-end'>
                    <Toolbar
                        pregunta={pregunta}
                        total={total}
                        onDelete={handleDelete}
                        onUp={handleUpClick}
                        onDown={handleDownClick}
                    />
                </div>
            </div>
            <div className='row'>
                <div className='col-md-12'>
                    <Form.Item
                        name={`nombre_${pregunta.uuid}`}
                        label={`Pregunta ${pregunta.posicion}`}
                        {...itemLayout}
                        rules={[
                            {
                                required: true,
                                whitespace: true,
                            },
                            ({ getFieldValue }) => ({
                                validator() {
                                    const sum = pregunta.opciones
                                        .map((opcion, index) => getFieldValue(`ponderacion_${opcion.uuid}_${index}`))
                                        .reduce((a, b) => a + b, 0);

                                    if (sum === 100) {
                                        return Promise.resolve();
                                    }

                                    return Promise.reject(new Error(PODERACION_PREGUNTA_NOT_VALID));
                                },
                            }),
                        ]}
                        initialValue={pregunta.nombre}
                    >
                        <TextArea maxLength={250} allowClear />
                    </Form.Item>
                </div>

                <div className='col-md-12'>
                    {pregunta.opciones.map((opcion, index) => (
                        <div className='row' key={opcion.uuid}>
                            <div className='col-md-1'>
                                <Radio defaultChecked />
                            </div>

                            <div className='col-md-4'>
                                <Form.Item
                                    name={`nombre_${opcion.uuid}_${index}`}
                                    label='Nombre'
                                    rules={[{ required: true }]}
                                    initialValue={opcion.nombre}
                                >
                                    <Input disabled />
                                </Form.Item>
                            </div>

                            <div className='col-md-4'>
                                <Form.Item
                                    name={`ponderacion_${opcion.uuid}_${index}`}
                                    label='Ponderación'
                                    rules={[{ required: true }]}
                                    initialValue={opcion.ponderacion}
                                >
                                    <InputNumber min={0} max={100} precision={0} />
                                </Form.Item>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

const PreguntaMultipleSeleccionUnica = (props) => {
    const { pregunta, total, onChange, onDelete, onUp, onDown } = props;

    const handleCantidadOpcionesChange = (value) => {
        const cantidadOpciones = value;

        let opciones = [];
        if (cantidadOpciones > 0) {
            [...Array(cantidadOpciones)].map((_, index) => {
                opciones.push({
                    uuid: uuidv4(),
                    nombre: '',
                    ponderacion: undefined,
                    posicion: index + 1,
                });

                return opciones;
            });
        }

        onChange({ ...pregunta, cantidadOpciones, opciones });
    };

    const handleDelete = () => {
        onDelete(pregunta);
    };

    const handleUpClick = (pregunta) => {
        onUp(pregunta);
    };

    const handleDownClick = (pregunta) => {
        onDown(pregunta);
    };

    return (
        <>
            <div className='row'>
                <div className='col-md-12 text-end'>
                    <Toolbar
                        pregunta={pregunta}
                        total={total}
                        onDelete={handleDelete}
                        onUp={handleUpClick}
                        onDown={handleDownClick}
                    />
                </div>
            </div>

            <div className='row'>
                <div className='col-md-10'>
                    <Form.Item
                        name={`nombre_${pregunta.uuid}`}
                        label={`Pregunta ${pregunta.posicion}`}
                        {...itemLayout}
                        rules={[
                            {
                                required: true,
                                whitespace: true,
                            },
                            ({ getFieldValue }) => ({
                                validator() {
                                    const sum = pregunta.opciones
                                        .map((opcion, index) => getFieldValue(`ponderacion_${opcion.uuid}_${index}`))
                                        .reduce((a, b) => a + b, 0);

                                    if (sum === 100) {
                                        return Promise.resolve();
                                    }

                                    return Promise.reject(new Error(PODERACION_PREGUNTA_NOT_VALID));
                                },
                            }),
                        ]}
                        initialValue={pregunta.nombre}
                    >
                        <TextArea maxLength={250} allowClear />
                    </Form.Item>
                </div>

                <div className='col-md-2'>
                    <Form.Item
                        name={`cantidadOpciones_${pregunta.uuid}`}
                        label='Cantidad de opciones'
                        {...itemLayout}
                        rules={[{ required: true }]}
                        initialValue={pregunta.cantidadOpciones}
                    >
                        <Select allowClear onChange={handleCantidadOpcionesChange}>
                            {[...Array(MAX_OPCIONES)].map((_, index) => {
                                const value = index + 1;

                                return (
                                    <Option value={value} key={index}>
                                        {value}
                                    </Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                </div>

                <div className='col-md-12'>
                    {pregunta.opciones.map((opcion, index) => (
                        <div className='row' key={opcion.uuid}>
                            <div className='col-md-1'>
                                <Radio defaultChecked />
                            </div>

                            <div className='col-md-4'>
                                <Form.Item
                                    name={`nombre_${opcion.uuid}_${index}`}
                                    label='Nombre'
                                    rules={[{ required: true, whitespace: true }]}
                                    initialValue={opcion.nombre}
                                >
                                    <Input maxLength={250} allowClear />
                                </Form.Item>
                            </div>

                            <div className='col-md-4'>
                                <Form.Item
                                    name={`ponderacion_${opcion.uuid}_${index}`}
                                    label='Ponderación'
                                    rules={[{ required: true }]}
                                    initialValue={opcion.ponderacion}
                                >
                                    <InputNumber min={0} max={100} precision={0} />
                                </Form.Item>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

const PreguntaMultipleSeleccionMultiple = (props) => {
    const { pregunta, total, onChange, onDelete, onUp, onDown } = props;

    const handleCantidadOpcionesChange = (value) => {
        const cantidadOpciones = value;

        let opciones = [];
        if (cantidadOpciones > 0) {
            [...Array(cantidadOpciones)].map((_, index) => {
                opciones.push({
                    uuid: uuidv4(),
                    nombre: '',
                    ponderacion: undefined,
                    posicion: index + 1,
                });

                return opciones;
            });
        }

        onChange({ ...pregunta, cantidadOpciones, opciones });
    };

    const handleDelete = () => {
        onDelete(pregunta);
    };

    const handleUpClick = (pregunta) => {
        onUp(pregunta);
    };

    const handleDownClick = (pregunta) => {
        onDown(pregunta);
    };

    return (
        <>
            <div className='row'>
                <div className='col-md-12 text-end'>
                    <Toolbar
                        pregunta={pregunta}
                        total={total}
                        onDelete={handleDelete}
                        onUp={handleUpClick}
                        onDown={handleDownClick}
                    />
                </div>
            </div>

            <div className='row'>
                <div className='col-md-10'>
                    <Form.Item
                        name={`nombre_${pregunta.uuid}`}
                        label={`Pregunta ${pregunta.posicion}`}
                        {...itemLayout}
                        rules={[
                            {
                                required: true,
                                whitespace: true,
                            },
                            ({ getFieldValue }) => ({
                                validator() {
                                    const sum = pregunta.opciones
                                        .map((opcion, index) => getFieldValue(`ponderacion_${opcion.uuid}_${index}`))
                                        .reduce((a, b) => a + b, 0);

                                    if (sum === 100) {
                                        return Promise.resolve();
                                    }

                                    return Promise.reject(new Error(PODERACION_PREGUNTA_NOT_VALID));
                                },
                            }),
                        ]}
                        initialValue={pregunta.nombre}
                    >
                        <TextArea maxLength={250} allowClear />
                    </Form.Item>
                </div>

                <div className='col-md-2'>
                    <Form.Item
                        name={`cantidadOpciones_${pregunta.uuid}`}
                        label='Cantidad de opciones'
                        {...itemLayout}
                        rules={[{ required: true }]}
                        initialValue={pregunta.cantidadOpciones}
                    >
                        <Select allowClear onChange={handleCantidadOpcionesChange}>
                            {[...Array(MAX_OPCIONES)].map((_, index) => {
                                const value = index + 1;

                                return (
                                    <Option value={value} key={index}>
                                        {value}
                                    </Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                </div>

                <div className='col-md-12'>
                    {pregunta.opciones.map((opcion, index) => (
                        <div className='row' key={opcion.uuid}>
                            <div className='col-md-1'>
                                <Checkbox checked />
                            </div>

                            <div className='col-md-4'>
                                <Form.Item
                                    name={`nombre_${opcion.uuid}_${index}`}
                                    label='Nombre'
                                    rules={[{ required: true, whitespace: true }]}
                                    initialValue={opcion.nombre}
                                >
                                    <Input maxLength={250} allowClear />
                                </Form.Item>
                            </div>

                            <div className='col-md-4'>
                                <Form.Item
                                    name={`ponderacion_${opcion.uuid}_${index}`}
                                    label='Ponderación'
                                    rules={[{ required: true }]}
                                    initialValue={opcion.ponderacion}
                                >
                                    <InputNumber min={0} max={100} precision={0} />
                                </Form.Item>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

const Pregunta = (props) => {
    const { pregunta, total, onChange, onDelete, onUp, onDown } = props;

    if (pregunta.idTipoPregunta === TIPO_PREGUNTA_SINO) {
        return <PreguntaSiNo pregunta={pregunta} total={total} onDelete={onDelete} onUp={onUp} onDown={onDown} />;
    }

    if (pregunta.idTipoPregunta === TIPO_PREGUNTA_MULTIPLE_SELECCION_UNICA) {
        return (
            <PreguntaMultipleSeleccionUnica
                pregunta={pregunta}
                total={total}
                onChange={onChange}
                onDelete={onDelete}
                onUp={onUp}
                onDown={onDown}
            />
        );
    }

    return (
        <PreguntaMultipleSeleccionMultiple
            pregunta={pregunta}
            total={total}
            onChange={onChange}
            onDelete={onDelete}
            onUp={onUp}
            onDown={onDown}
        />
    );
};

export default Pregunta;
