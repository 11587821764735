import { DatePicker, Form, Input, InputNumber, message, Modal } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { useEffect } from 'react';
import useSWRImmutable from 'swr/immutable';
import moment from 'moment';
import { GENERIC_ERROR_MESSAGE, GENERIC_SUCCESS_MESSAGE } from '../../constants/feedback';
import { createPago, getPagoById, PAGO_EMPRESA_BY_ID_ENDPOINT, updatePago } from '../../services/api/empresas';

const itemLayout = {
    labelCol: { span: 24 },
};

const PagoItem = (props) => {
    const { id, idEmpresa, isModalOpen, onOk, onCancel } = props;

    const [form] = Form.useForm();

    const { data: pagoData } = useSWRImmutable(
        id > 0 ? [PAGO_EMPRESA_BY_ID_ENDPOINT, id] : null,
        (_, id) => getPagoById(id),
        {
            revalidateOnMount: true,
        }
    );

    useEffect(() => {
        if (pagoData) {
            const values = {
                fechaPago: moment(pagoData.data.fechaPago),
                numTransaccion: pagoData.data.numTransaccion,
                fechaInicio: moment(pagoData.data.fechaInicio),
                fechaTermino: moment(pagoData.data.fechaTermino),
                montoPago: pagoData.data.montoPago,
                comprobante: pagoData.data.comprobante,
            };

            form.setFieldsValue(values);
        }
    }, [pagoData, form]);

    const handleOk = () => {
        form.submit();
    };

    const handleCancel = () => {
        form.resetFields();
        onCancel();
    };

    const handleFinishClick = async (values) => {
        try {
            const params = {
                id: id ?? 0,
                idEmpresa,
                fechaPago: values.fechaPago.toISOString(),
                numTransaccion: values.numTransaccion,
                fechaInicio: values.fechaInicio.toISOString(),
                fechaTermino: values.fechaTermino.toISOString(),
                montoPago: values.montoPago,
                comprobante: values.comprobante || null,
            };

            if (id > 0) {
                await updatePago(params);
            } else {
                await createPago(params);
            }

            form.resetFields();
            onOk();

            message.success(GENERIC_SUCCESS_MESSAGE);
        } catch (error) {
            message.error(GENERIC_ERROR_MESSAGE);
        }
    };

    return (
        <>
            <Modal title='Pago' open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <Form form={form} onFinish={handleFinishClick}>
                    <div className='row'>
                        <div className='col-md-6'>
                            <Form.Item name='fechaPago' label='Fecha pago' {...itemLayout} rules={[{ required: true }]}>
                                <DatePicker className='w-100' format='L' allowClear />
                            </Form.Item>
                        </div>

                        <div className='col-md-6'>
                            <Form.Item
                                name='numTransaccion'
                                label='N° transacción'
                                {...itemLayout}
                                rules={[{ required: true, whitespace: true }]}
                            >
                                <Input maxLength={45} allowClear />
                            </Form.Item>
                        </div>

                        <div className='col-md-6'>
                            <Form.Item
                                name='fechaInicio'
                                label='Fecha inicio'
                                {...itemLayout}
                                rules={[{ required: true }]}
                            >
                                <DatePicker className='w-100' format='L' allowClear />
                            </Form.Item>
                        </div>

                        <div className='col-md-6'>
                            <Form.Item
                                name='fechaTermino'
                                label='Fecha término'
                                {...itemLayout}
                                rules={[{ required: true }]}
                            >
                                <DatePicker className='w-100' format='L' allowClear />
                            </Form.Item>
                        </div>

                        <div className='col-md-6'>
                            <Form.Item name='montoPago' label='Monto' {...itemLayout} rules={[{ required: true }]}>
                                <InputNumber className='w-100' min={1} allowClear />
                            </Form.Item>
                        </div>

                        <div className='col-md-12'>
                            <Form.Item
                                name='comprobante'
                                label='Comprobante'
                                {...itemLayout}
                                rules={[{ whitespace: true }]}
                            >
                                <TextArea maxLength={200} showCount allowClear />
                            </Form.Item>
                        </div>
                    </div>
                </Form>
            </Modal>
        </>
    );
};

export default PagoItem;
