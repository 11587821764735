import axios from '../../lib/axios';

export const SUBAMBITOS_ENDPOINT = '/subambitos';

export const getAllSubAmbitos = async (params) => {
    const response = await axios.get(SUBAMBITOS_ENDPOINT, { params });
    return response;
};

export const getSubAmbitoById = async (id) => {
    const response = await axios.get(`${SUBAMBITOS_ENDPOINT}/${id}`);
    return response;
};

/*
export const AMBITOS_DDL_ENDPOINT = `${AMBITOS_ENDPOINT}/drop-down-list`;

export const getAllAmbitosForDropDowList = async () => {
    const response = await axios.get(AMBITOS_DDL_ENDPOINT);

    return response;
};*/
/*
export const SUBAMBITOS_BY_AMBITO_DDL_ENDPOINT = `${AMBITOS_ENDPOINT}/{id}/subambitos/drop-down-list`;

export const getSubambitosByAmbitoForDropDowList = async (idAmbito) => {
    const response = await axios.get(`${AMBITOS_ENDPOINT}/${idAmbito}/subambitos/drop-down-list`);

    return response;
};*/

export const createSubAmbito = async (params) => {
    const response = await axios.post(SUBAMBITOS_ENDPOINT, params);
    return response;
};

export const updatSubAmbito = async (params) => {
    const response = await axios.put(SUBAMBITOS_ENDPOINT, params);
    return response;
};

export const deleteSubAmbito = async (id) => {
    const response = await axios.delete(`${SUBAMBITOS_ENDPOINT}/${id}`);
    return response;
};

export const checkSubambitoAvailability = async (params) => {
    const response = await axios.get(`${SUBAMBITOS_ENDPOINT}/availability`, { params });
    return response;
};
