import { useEffect, useState } from 'react';
import { Form, InputNumber, Select, Collapse } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import useSWRImmutable from 'swr/immutable';
import { getAllTipoFeedbacks, TIPO_FEEDBACKS_ENDPOINT } from '../../../services/api/enumeraciones';
import Subambito from './subambito';
import { SUM_PODERACION_EVALUACION_SUBAMBITO_NOT_VALID } from '../../../constants/feedback';

const { Option } = Select;
const { Panel } = Collapse;

const itemLayout = {
    labelCol: { span: 24 },
};

const Ambito = (props) => {
    const { ambito, activePanels } = props;
    let [openPanels, setOpenPanels] = useState([]);

    const { data: tipoFeedbacksData } = useSWRImmutable(TIPO_FEEDBACKS_ENDPOINT, () => getAllTipoFeedbacks());

    useEffect(() => {
        setOpenPanels(...activePanels);
    }, [activePanels]);

    return (
        <div className='row'>
            <h5>Ámbito - {ambito.nombreAmbito}</h5>
            <hr className='style2'></hr>

            <div className='col-md-6'>
                <Form.Item
                    name={`ponderacion_${ambito.uuid}`}
                    label='Ponderación'
                    {...itemLayout}
                    rules={[{ required: true }]}
                    initialValue={ambito.ponderacion}
                >
                    <InputNumber min={0} max={100} precision={0} />
                </Form.Item>
            </div>

            <div className='col-md-6'></div>

            <hr className='style1'></hr>

            <div className='col-md-12'>
                <h5>Rangos</h5>
            </div>

            <div className='col-md-2'>
                <p>0% a 30% </p>
            </div>

            <div className='col-md-3'>
                <Form.Item
                    name={`idTipoFeedbackR1_${ambito.uuid}`}
                    label=''
                    {...itemLayout}
                    rules={[{ required: true }]}
                    initialValue={ambito.idTipoFeedbackR1}
                >
                    <Select className='w-100' allowClear>
                        {tipoFeedbacksData?.data.map((feedback) => (
                            <Option key={feedback.id} value={feedback.id}>
                                {feedback.nombre}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
            </div>

            <div className='col-md-7'>
                <Form.Item
                    name={`feedbackRango1_${ambito.uuid}`}
                    label=''
                    {...itemLayout}
                    initialValue={ambito.feedbackRango1}
                    rules={[{ whitespace: true }]}
                >
                    <TextArea maxLength={1000} allowClear />
                </Form.Item>
            </div>

            <div className='col-md-2'>
                <p>31% a 50%</p>
            </div>

            <div className='col-md-3'>
                <Form.Item
                    name={`idTipoFeedbackR2_${ambito.uuid}`}
                    label=''
                    {...itemLayout}
                    rules={[{ required: true }]}
                    initialValue={ambito.idTipoFeedbackR2}
                >
                    <Select className='w-100' allowClear>
                        {tipoFeedbacksData?.data.map((feedback) => (
                            <Option key={feedback.id} value={feedback.id}>
                                {feedback.nombre}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
            </div>

            <div className='col-md-7'>
                <Form.Item
                    name={`feedbackRango2_${ambito.uuid}`}
                    label=''
                    {...itemLayout}
                    initialValue={ambito.feedbackRango2}
                    rules={[{ whitespace: true }]}
                >
                    <TextArea maxLength={1000} allowClear />
                </Form.Item>
            </div>

            <div className='col-md-2'>
                <p>51% a 70% </p>
            </div>

            <div className='col-md-3'>
                <Form.Item
                    name={`idTipoFeedbackR3_${ambito.uuid}`}
                    label=''
                    {...itemLayout}
                    rules={[{ required: true }]}
                    initialValue={ambito.idTipoFeedbackR3}
                >
                    <Select className='w-100' allowClear>
                        {tipoFeedbacksData?.data.map((feedback) => (
                            <Option key={feedback.id} value={feedback.id}>
                                {feedback.nombre}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
            </div>

            <div className='col-md-7'>
                <Form.Item
                    name={`feedbackRango3_${ambito.uuid}`}
                    label=''
                    {...itemLayout}
                    initialValue={ambito.feedbackRango3}
                    rules={[{ whitespace: true }]}
                >
                    <TextArea maxLength={1000} allowClear />
                </Form.Item>
            </div>

            <div className='col-md-2'>
                <p>71% a 100%</p>
            </div>

            <div className='col-md-3'>
                <Form.Item
                    name={`idTipoFeedbackR4_${ambito.uuid}`}
                    label=''
                    {...itemLayout}
                    rules={[{ required: true }]}
                    initialValue={ambito.idTipoFeedbackR4}
                >
                    <Select className='w-100' allowClear>
                        {tipoFeedbacksData?.data.map((feedback) => (
                            <Option key={feedback.id} value={feedback.id}>
                                {feedback.nombre}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
            </div>

            <div className='col-md-7'>
                <Form.Item
                    name={`feedbackRango4_${ambito.uuid}`}
                    label=''
                    {...itemLayout}
                    initialValue={ambito.feedbackRango4}
                    rules={[{ whitespace: true }]}
                >
                    <TextArea maxLength={1000} allowClear />
                </Form.Item>
            </div>

            <div className='col-md-2'>
                <h6>Sub-Ámbitos</h6>
            </div>

            <div className='col-md-10'>
                <Form.Item
                    name={`sumPoderacionSubambito_${ambito.uuid}`}
                    rules={[
                        ({ getFieldValue }) => ({
                            validator() {
                                const sum = ambito.subambitos
                                    .map((subambito) => getFieldValue(`ponderacion_${subambito.uuid}`))
                                    .reduce((a, b) => a + b, 0);

                                if (sum === 100) {
                                    return Promise.resolve();
                                }

                                return Promise.reject(new Error(SUM_PODERACION_EVALUACION_SUBAMBITO_NOT_VALID));
                            },
                        }),
                    ]}
                />
            </div>

            <Collapse activeKey={openPanels} ghost onChange={setOpenPanels}>
                {ambito.subambitos.map((subambito) => (
                    <Panel header={subambito.nombreSubambito} key={subambito.uuid} forceRender>
                        <Subambito subambito={subambito} />
                    </Panel>
                ))}
            </Collapse>

            <hr className='style1' />
        </div>
    );
};

export default Ambito;
