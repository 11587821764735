import { useEffect } from 'react';
import { Button, Form, notification, Space, Tabs } from 'antd';
import { Link, useParams } from 'react-router-dom';
import useSWRImmutable from 'swr/immutable';
import { EMPRESAS_ENDPOINT, getEmpresaById } from '../../services/api/empresas';
import EmpresaStepOne from './empresa-step-one';
import EmpresaStepTwo from './empresa-step-two';
import { validateMessages } from '../../constants/feedback';
import useEmpresa from '../../hooks/use-empresa';

const EmpresaItemPage = () => {
    const empresaContext = useEmpresa();
    let { id } = useParams();

    const [form] = Form.useForm();

    const { data: empresaData } = useSWRImmutable(
        id > 0 ? [EMPRESAS_ENDPOINT, id] : null,
        (_, id) => getEmpresaById(id),
        {
            revalidateOnMount: true,
        }
    );

    useEffect(() => {
        if (empresaData) {
            empresaContext.fill(empresaData.data);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [empresaData]);

    const handleFinishFailed = () => {
        form.validateFields()
            .then((_) => {})
            .catch((errorInfo) => {
                notification['error']({
                    message: 'Registrar Empresa',
                    description: `${errorInfo.errorFields.length} errores impiden que se registre esta empresa. Corrija los errores y vuelva a intentarlo.`,
                });
            });
    };

    const handleFinishClick = (values) => {
        empresaContext.save(values);
    };

    return (
        <>
            <Form
                form={form}
                onFinish={handleFinishClick}
                onFinishFailed={handleFinishFailed}
                validateMessages={validateMessages}
                autoComplete='off'
            >
                <div className='row pt-3'>
                    <div className='col-lg-12'>
                        <div className='card rounded-4 shadow'>
                            <h4 className='color-text pt-3 border-bottom mx-3'>Empresas</h4>

                            <div className='card-body'>
                                <div className='row pl-2 pr-2'>
                                    <Tabs
                                        defaultActiveKey='1'
                                        type='card'
                                        size='small'
                                        className='ps-3 pe-3'
                                        items={[
                                            {
                                                label: 'Empresa',
                                                key: 'step-one',
                                                children: <EmpresaStepOne form={form} />,
                                                forceRender: true,
                                            },
                                            {
                                                label: 'Encargado',
                                                key: 'step-two',
                                                children: <EmpresaStepTwo form={form} />,
                                                forceRender: true,
                                            },
                                        ]}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='col-lg-12 text-center mt-3 mb-3'>
                        <Space>
                            <Link to='/empresas'>
                                <Button type='secondary'>Cerrar</Button>
                            </Link>

                            <Button type='primary' htmlType='submit'>
                                Guardar
                            </Button>
                        </Space>
                    </div>
                </div>
            </Form>
        </>
    );
};

export default EmpresaItemPage;
