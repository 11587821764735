import { loginAxios } from '../../lib/axios';

export const signIn = async (params) => {
    const response = await loginAxios.post('auth', params);
    return response;
};


export const passwordRecovery = async (params) => {
    const response = await loginAxios.post('auth/recovery', params);
    return response;
};


export const resetPassword = async (params) => {
    const response = await loginAxios.post('auth/reset', params);
    return response;
};
