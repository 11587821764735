import { loginAxios } from '../../lib/axios';

export const checkUsernameAvailability = async (params) => {
    const response = await loginAxios.get(`signup/availability`, { params });
    return response;
};

export const signUp = async (params) => {
    const response = await loginAxios.post('signup', params);
    return response;
};
