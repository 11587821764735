import { Form, InputNumber, Select } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import useSWRImmutable from 'swr/immutable';
import { getAllTipoFeedbacks, TIPO_FEEDBACKS_ENDPOINT } from '../../../services/api/enumeraciones';

const { Option } = Select;

const itemLayout = {
    labelCol: { span: 24 },
};

const Subambito = (props) => {
    const { subambito } = props;

    const { data: tipoFeedbacksData } = useSWRImmutable(TIPO_FEEDBACKS_ENDPOINT, () => getAllTipoFeedbacks());

    return (
        <div className='row'>
            <div className='col-md-2'></div>

            <div className='col-md-10'>
                <Form.Item
                    name={`ponderacion_${subambito.uuid}`}
                    label='Ponderación'
                    {...itemLayout}
                    rules={[{ required: true }]}
                    initialValue={subambito.ponderacion}
                >
                    <InputNumber min={0} max={100} precision={0} />
                </Form.Item>
            </div>

            <div className='col-md-2'>
                <p>0% a 30% </p>
            </div>

            <div className='col-md-3'>
                <Form.Item
                    name={`idTipoFeedbackR1_${subambito.uuid}`}
                    label=''
                    {...itemLayout}
                    rules={[{ required: true }]}
                    initialValue={subambito.idTipoFeedbackR1}
                >
                    <Select className='w-100' allowClear>
                        {tipoFeedbacksData?.data.map((feedback) => (
                            <Option key={feedback.id} value={feedback.id}>
                                {feedback.nombre}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
            </div>

            <div className='col-md-7'>
                <Form.Item
                    name={`feedbackRango1_${subambito.uuid}`}
                    label=''
                    {...itemLayout}
                    initialValue={subambito.feedbackRango1}
                    rules={[{ whitespace: true }]}
                >
                    <TextArea maxLength={1000} allowClear />
                </Form.Item>
            </div>

            <div className='col-md-2'>
                <p>31% a 50%</p>
            </div>

            <div className='col-md-3'>
                <Form.Item
                    name={`idTipoFeedbackR2_${subambito.uuid}`}
                    label=''
                    {...itemLayout}
                    rules={[{ required: true }]}
                    initialValue={subambito.idTipoFeedbackR2}
                >
                    <Select className='w-100' allowClear>
                        {tipoFeedbacksData?.data.map((feedback) => (
                            <Option key={feedback.id} value={feedback.id}>
                                {feedback.nombre}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
            </div>

            <div className='col-md-7'>
                <Form.Item
                    name={`feedbackRango2_${subambito.uuid}`}
                    label=''
                    {...itemLayout}
                    initialValue={subambito.feedbackRango2}
                    rules={[{ whitespace: true }]}
                >
                    <TextArea maxLength={1000} allowClear />
                </Form.Item>
            </div>

            <div className='col-md-2 '>
                <p>51% a 70% </p>
            </div>

            <div className='col-md-3'>
                <Form.Item
                    name={`idTipoFeedbackR3_${subambito.uuid}`}
                    label=''
                    {...itemLayout}
                    rules={[{ required: true }]}
                    initialValue={subambito.idTipoFeedbackR3}
                >
                    <Select className='w-100' allowClear>
                        {tipoFeedbacksData?.data.map((feedback) => (
                            <Option key={feedback.id} value={feedback.id}>
                                {feedback.nombre}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
            </div>

            <div className='col-md-7'>
                <Form.Item
                    name={`feedbackRango3_${subambito.uuid}`}
                    label=''
                    {...itemLayout}
                    initialValue={subambito.feedbackRango3}
                    rules={[{ whitespace: true }]}
                >
                    <TextArea maxLength={1000} allowClear />
                </Form.Item>
            </div>

            <div className='col-md-2'>
                <p>71% a 100%</p>
            </div>

            <div className='col-md-3'>
                <Form.Item
                    name={`idTipoFeedbackR4_${subambito.uuid}`}
                    label=''
                    {...itemLayout}
                    rules={[{ required: true }]}
                    initialValue={subambito.idTipoFeedbackR4}
                >
                    <Select className='w-100' allowClear>
                        {tipoFeedbacksData?.data.map((feedback) => (
                            <Option key={feedback.id} value={feedback.id}>
                                {feedback.nombre}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
            </div>

            <div className='col-md-7'>
                <Form.Item
                    name={`feedbackRango4_${subambito.uuid}`}
                    label=''
                    {...itemLayout}
                    initialValue={subambito.feedbackRango4}
                    rules={[{ whitespace: true }]}
                >
                    <TextArea maxLength={1000} allowClear />
                </Form.Item>
            </div>
        </div>
    );
};

export default Subambito;
