import { Outlet, Navigate } from 'react-router-dom';
import { useAuth } from '../../hooks/use-auth';

const AuthGuard = () => {
    const { user } = useAuth();

    return user ? <Outlet /> : <Navigate to='/login' />;
};

export default AuthGuard;
