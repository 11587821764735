import axios from '../../lib/axios';

export const RUBRO_EMPRESAS_ENDPOINT = '/rubroempresas';

export const RUBRO_EMPRESAS_DDL_ENDPOINT = `${RUBRO_EMPRESAS_ENDPOINT}/drop-down-list`;

export const getAllRubroEmpresasForDropDowList = async () => {
    const response = await axios.get(RUBRO_EMPRESAS_DDL_ENDPOINT);

    return response;
};
