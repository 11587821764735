import axios from '../../lib/axios';

export const CUESTIONARIOS_ENDPOINT = '/cuestionarios';

export const getAllCuestionarios = async (params) => {
    const response = await axios.get(CUESTIONARIOS_ENDPOINT, { params });
    return response;
};

export const getCuestionarioById = async (id) => {
    const response = await axios.get(`${CUESTIONARIOS_ENDPOINT}/${id}`);
    return response;
};

export const createCuestionario = async (params) => {
    const response = await axios.post(CUESTIONARIOS_ENDPOINT, params);
    return response;
};

export const updateCuestionario = async (params) => {
    const response = await axios.put(CUESTIONARIOS_ENDPOINT, params);
    return response;
};

export const deleteCuestionario = async (id) => {
    const response = await axios.delete(`${CUESTIONARIOS_ENDPOINT}/${id}`);
    return response;
};

export const publishCuestionario = async (id) => {
    const response = await axios.patch(`${CUESTIONARIOS_ENDPOINT}/${id}/publicacion`);
    return response;
};

export const CUESTIONARIO_EVALUACION_ENDPOINT = `${CUESTIONARIOS_ENDPOINT}/{id}/evaluacion`;

export const getEvaluacionById = async (id) => {
    const response = await axios.get(`${CUESTIONARIOS_ENDPOINT}/${id}/evaluacion`);
    return response;
};

export const updateEvaluacion = async (params) => {
    const response = await axios.put(`${CUESTIONARIOS_ENDPOINT}/${params.id}/evaluacion`, params);
    return response;
};
