import React, { useState } from 'react';
import { EditTwoTone } from '@ant-design/icons';
import {
    Button,
    Comment,
    Divider,
    List,
    Form,
    DatePicker,
    Select,
    Space,
    Table,
    Tooltip,
    Modal,
    Input,
    Row,
    Col,
    message,
} from 'antd';

import { useAuth } from '../../hooks/use-auth';
import {
    ATENCION_CLIENTES_ENDPOINT,
    getAllConsultas,
    createNuevaConsulta,
    updateConsulta,
    getHilo,
} from '../../services/api/atencion-clientes';

import { GENERIC_ERROR_MESSAGE, GENERIC_SUCCESS_MESSAGE } from '../../constants/feedback';

import { DEFAULT_PAGE_SIZE } from '../../constants/pagination';
import useSWR from 'swr';
import useSWRImmutable from 'swr/immutable';

const { RangePicker } = DatePicker;
const { TextArea } = Input;
const { Option } = Select;
const { Column } = Table;
const itemLayout = {
    labelCol: { span: 24 },
};

const initialFilters = {
    pageNumber: 1,
    pageSize: DEFAULT_PAGE_SIZE,
};

const EmpresaTable = ({ consultasData, isLoading, displayMode, onRespondAction, onViewAction }) => {
    return (
        <>
            <Table rowKey={(consulta) => consulta.id} dataSource={consultasData?.data.items} loading={isLoading}>
                <Column title='Id' dataIndex='id' key='id' sorter />
                {displayMode === 1 && (
                    <Column
                        title='Empresa'
                        dataIndex='atencionClienteHiloUsuarioEmpresaNombre'
                        key='atencionClienteHiloUsuarioEmpresaNombre'
                        sorter
                    />
                )}
                {displayMode === 1 && <Column title='Usuario' dataIndex='usuario' key='usuario' sorter />}

                <Column
                    title='Fecha Consulta'
                    dataIndex='fechaCreacion'
                    key='fechaCreacion'
                    sorter
                    render={(fechaCreacion) => new Date(fechaCreacion).toLocaleDateString()}
                />
                <Column
                    title='Estado'
                    dataIndex='estadoConsulta'
                    key='estadoConsulta'
                    render={(estadoConsulta) => (estadoConsulta === 0 ? 'No Respondida' : 'Respondida')}
                    sorter
                />

                {displayMode === 1 && (
                    <Column
                        title='Consulta'
                        render={(consulta) => (
                            <Space size={0}>
                                {consulta.estadoConsulta === 0 && (
                                    <Tooltip title='Responder'>
                                        <Button
                                            icon={<EditTwoTone />}
                                            onClick={() => onRespondAction(consulta)}
                                        ></Button>
                                    </Tooltip>
                                )}
                            </Space>
                        )}
                    />
                )}
                {displayMode === 2 && (
                    <Column
                        title='Consulta'
                        render={(consulta) => (
                            <Space size={0}>
                                {consulta.estadoConsulta === 1 && (
                                    <Tooltip title='Ver Respuesta'>
                                        <Button
                                            icon={<EditTwoTone />}
                                            onClick={() => {
                                                onViewAction(consulta);
                                            }}
                                        ></Button>
                                    </Tooltip>
                                )}
                            </Space>
                        )}
                    />
                )}
            </Table>
        </>
    );
};

const NuevaConsulta = ({ isModalOpen, onOk, onCancel }) => {
    const [form] = Form.useForm();
    const handleSubmit = async (values) => {
        try {
            await createNuevaConsulta(values);
            form.resetFields();
            message.success(GENERIC_SUCCESS_MESSAGE, () => {
                onOk();
            });
        } catch {
            message.error(GENERIC_ERROR_MESSAGE, () => {
                onOk();
            });
        }
    };

    const handleOnCancel = () => {
        form.resetFields();
        onCancel();
    };
    return (
        <Modal title='Nueva Consulta' open={isModalOpen} onOk={form.submit} onCancel={handleOnCancel} width={1000}>
            <Form form={form} layout='vertical' onFinish={handleSubmit}>
                <Row>
                    <Col span={24}>
                        <Form.Item name='consulta'>
                            <TextArea rows={8} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

const ResponderConsulta = ({ isModalOpen, onOk, onCancel, consulta }) => {
    const [form] = Form.useForm();
    const handleSubmit = async (values) => {
        try {
            const { id, idAtencionClienteHilo } = consulta;
            await updateConsulta({ ...values, id, idAtencionClienteHilo });
            form.resetFields();
            message.success(GENERIC_SUCCESS_MESSAGE, () => {
                onOk();
            });
        } catch {
            message.error(GENERIC_ERROR_MESSAGE, () => {
                onOk();
            });
        }
    };

    const handleOnCancel = () => {
        form.resetFields();
        onCancel();
    };
    return (
        <Modal title='Responder Consulta' open={isModalOpen} onOk={form.submit} onCancel={handleOnCancel} width={1000}>
            <Form form={form} layout='vertical' onFinish={handleSubmit}>
                <Row>
                    <Col span={24}>
                        <Comment
                            datetime={
                                <Tooltip title={new Date(consulta?.fechaCreacion).toLocaleDateString()}>
                                    <span>{consulta?.horasAntiguedad} horas atrás</span>
                                </Tooltip>
                            }
                            // eslint-disable-next-line jsx-a11y/anchor-is-valid
                            author={<a href='#'>{consulta?.usuario}</a>}
                            content={<p>{consulta?.consulta}</p>}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item name='respuesta'>
                            <TextArea rows={8} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

const VerRespuestaConsulta = ({ isModalOpen, onOk, onCancel, consulta }) => {
    const [form] = Form.useForm();
    const { data: consultaData } = useSWRImmutable(
        consulta?.idAtencionClienteHilo > 0 ? [ATENCION_CLIENTES_ENDPOINT, consulta?.idAtencionClienteHilo] : null,
        (_, id) => getHilo(id),
        {
            revalidateOnMount: true,
        }
    );
    const handleSubmit = async (values) => {
        try {
            const { idAtencionClienteHilo } = consulta;
            await createNuevaConsulta({ ...values, idAtencionClienteHilo });
            form.resetFields();
            message.success(GENERIC_SUCCESS_MESSAGE, () => {
                onOk();
            });
        } catch {
            message.error(GENERIC_ERROR_MESSAGE, () => {
                onOk();
            });
        }
    };

    const handleOnCancel = () => {
        form.resetFields();
        onCancel();
    };

    return (
        <Modal
            title='Ver Respuesta'
            open={isModalOpen}
            onOk={form.submit}
            onCancel={handleOnCancel}
            width={1000}
            okText='Nueva Consulta'
        >
            <Form form={form} layout='vertical' onFinish={handleSubmit}>
                <Row>
                    <Col span={24}>
                        <List>
                            {consultaData?.data.map((consulta, i) => (
                                <>
                                    <Comment
                                        key={i}
                                        datetime={<span>{new Date(consulta.fechaCreacion).toLocaleString()}</span>}
                                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                        author={<a href='#'>Consulta</a>}
                                        content={<p>{consulta.consulta}</p>}
                                    >
                                        <Comment
                                            datetime={
                                                <span> {new Date(consulta.fechaModificacion).toLocaleString()}</span>
                                            }
                                            // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                            author={<a href='#'>Respuesta</a>}
                                            content={<p>{consulta.respuesta}</p>}
                                        ></Comment>
                                    </Comment>

                                    <Divider className='atencion-clientes' />
                                </>
                            ))}
                        </List>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item name='Consulta'>
                            <TextArea rows={8} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

const ConsultasList = () => {
    const { user } = useAuth();
    const [form] = Form.useForm();
    const [isModalNuevaConsultaOpen, setIsModalNuevaConsultaOpen] = useState(false);
    const [isModalResponderConsultaOpen, setIsModalResponderConsultaOpen] = useState(false);

    const [isModalViewConsultaOpen, setIsModalViewConsultaOpen] = useState(false);
    const [selectedConsulta, setSelectedConsulta] = useState(null);
    const [filters, setFilters] = useState({ ...initialFilters });
    const [selectedFilters, setSelectedFilters] = useState({ ...initialFilters });

    const {
        data: consultasData,
        error,
        mutate,
    } = useSWR([ATENCION_CLIENTES_ENDPOINT, filters], (_, filters) => getAllConsultas(filters));

    const isLoading = !error && !consultasData;

    const handleReset = async () => {
        form.resetFields();

        setSelectedFilters({ ...initialFilters });
        setFilters({ ...initialFilters });
    };
    const handleSearch = async (values) => {
        const fechaCreacionRange = values['fechaCreacion'];

        const params = {
            ...selectedFilters,
            fechaDesde: fechaCreacionRange ? fechaCreacionRange[0].toISOString() : null,
            fechaHasta: fechaCreacionRange ? fechaCreacionRange[1].toISOString() : null,
            estadoConsulta: values.estadoConsulta,
            pageNumber: 1,
        };

        setFilters(params);
    };

    return (
        <>
            <div className='row pt-3'>
                <div className='col-lg-12'>
                    <div className='card rounded-4 shadow'>
                        <h4 className='color-text pt-3 border-bottom mx-3'>Filtros</h4>

                        <div className='card-body'>
                            <Form form={form} onFinish={handleSearch}>
                                <div className='row pl-2 pr-2'>
                                    <div className='col-md-4 pt-2 pb-3'>
                                        <Form.Item name='estadoConsulta' label='Estado' {...itemLayout}>
                                            <Select className='w-100' allowClear>
                                                {[
                                                    { id: 0, nombre: 'No Respondida' },
                                                    { id: 1, nombre: 'Respondida' },
                                                ].map((estado) => (
                                                    <Option key={estado.id} value={estado.id}>
                                                        {estado.nombre}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </div>
                                    {user?.idTipoUsuario === 1 && (
                                        <div className='col-md-4 pt-2 pb-3'>
                                            <Form.Item name='fechaCreacion' label='Fecha creación' {...itemLayout}>
                                                <RangePicker className='w-100' format='L' />
                                            </Form.Item>
                                        </div>
                                    )}
                                </div>
                                <div className='col-lg-12 text-end'>
                                    <Form.Item>
                                        <Space>
                                            <Button type='primary' htmlType='submit'>
                                                Filtrar
                                            </Button>
                                            <Button onClick={handleReset}>Limpiar</Button>
                                        </Space>
                                    </Form.Item>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row pt-5 pb-5'>
                <div className='col-lg-12'>
                    <div className='card rounded-4 shadow'>
                        <h4 className='color-text pt-3 border-bottom mx-3'>Consultas</h4>

                        <div className='card-body'>
                            <div className='row pl-2 pr-2'>
                                <EmpresaTable
                                    displayMode={user.idTipoUsuario}
                                    consultasData={consultasData}
                                    isLoading={isLoading}
                                    onRespondAction={(consulta) => {
                                        setSelectedConsulta(consulta);
                                        setIsModalResponderConsultaOpen(true);
                                    }}
                                    onViewAction={(consulta) => {
                                        setSelectedConsulta(consulta);
                                        setIsModalViewConsultaOpen(true);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {user?.idTipoUsuario === 2 && (
                    <div className='col-lg-12 text-end mt-3'>
                        <Button type='primary' onClick={() => setIsModalNuevaConsultaOpen(true)}>
                            Nueva Consulta
                        </Button>
                    </div>
                )}
            </div>

            <ResponderConsulta
                isModalOpen={isModalResponderConsultaOpen}
                onOk={() => {
                    setIsModalResponderConsultaOpen(false);
                    mutate();
                }}
                onCancel={() => setIsModalResponderConsultaOpen(false)}
                consulta={selectedConsulta}
            />
            <NuevaConsulta
                isModalOpen={isModalNuevaConsultaOpen}
                onOk={() => {
                    setIsModalNuevaConsultaOpen(false);
                    mutate();
                }}
                onCancel={() => setIsModalNuevaConsultaOpen(false)}
            />

            <VerRespuestaConsulta
                isModalOpen={isModalViewConsultaOpen}
                consulta={selectedConsulta}
                onOk={() => {
                    setIsModalViewConsultaOpen(false);
                    mutate();
                }}
                onCancel={() => setIsModalViewConsultaOpen(false)}
            />
        </>
    );
};

export default ConsultasList;

/*          <Row>
            <Col span={24}>
              <List>
                <Comment
                  datetime={
                    <Tooltip title="2016-11-22 10:22:33">
                      <span>9 hours ago</span>
                    </Tooltip>
                  }
                  author={<a href="#">Consulta</a>}
                  content={
                    <p>
                      Tengo una consulta asasas asasa asasa sasasa asas asasas
                      asas sdsd sdsds sdsdsdsdsdsdsds
                    </p>
                  }
                >
                  <Comment
                    datetime={
                      <Tooltip title="2016-11-22 10:22:33">
                        <span>9 hours ago</span>
                      </Tooltip>
                    }
                    author={<a href="#">Respuesta</a>}
                    content={<p>Cuename que paso</p>}
                  ></Comment>
                </Comment>

                <Divider className="atencion-clientes" />
                <Comment
                  datetime={
                    <Tooltip title="2016-11-22 10:22:33">
                      <span>9 hours ago</span>
                    </Tooltip>
                  }
                  author={<a href="#">Yo Pregunte</a>}
                  content={<p>Tengo una consulta</p>}
                >
                  <Comment
                    datetime={
                      <Tooltip title="2016-11-22 10:22:33">
                        <span>9 hours ago</span>
                      </Tooltip>
                    }
                    author={<a href="#">Admin Respondio</a>}
                    content={<p>Cuename que paso</p>}
                  ></Comment>
                </Comment>
                <Divider className="atencion-clientes" />

                <Comment
                  datetime={
                    <Tooltip title="2016-11-22 10:22:33">
                      <span>9 hours ago</span>
                    </Tooltip>
                  }
                  author={<a href="#">Yo</a>}
                  content={<p>Tengo una consulta</p>}
                >
                  <Comment
                    datetime={
                      <Tooltip title="2016-11-22 10:22:33">
                        <span>9 hours ago</span>
                      </Tooltip>
                    }
                    author={<a href="#">Admin</a>}
                    content={<p>Cuename que paso</p>}
                  ></Comment>
                </Comment>
                <Divider className="atencion-clientes" />
              </List>
            </Col>
          </Row>*/
