import { Checkbox, Form, Radio, Space } from 'antd';
import { TIPO_PREGUNTA_MULTIPLE_SELECCION_UNICA, TIPO_PREGUNTA_SINO } from '../../../constants/tipo-pregunta';

const PreguntaSiNo = (props) => {
    const { data } = props;

    return (
        <>
            <div className='row'>
                <div className='col-md-12'>
                    <label>
                        {data.numero}.{data.nombre} <span className='text-danger'>*</span>
                    </label>
                </div>

                <div className='col-md-12 mt-3'>
                    <Form.Item
                        name={`pregunta_${data.id}`}
                        label=''
                        rules={[{ required: true }]}
                        initialValue={data.respuestas[0]}
                    >
                        <Radio.Group>
                            <Space direction='vertical'>
                                {data.opciones.map((opcion, index) => (
                                    <div className='row' key={index}>
                                        <div className='col-md-12'>
                                            <Radio value={opcion.id}>{opcion.nombre}</Radio>
                                        </div>
                                    </div>
                                ))}
                            </Space>
                        </Radio.Group>
                    </Form.Item>
                </div>
            </div>
        </>
    );
};

const PreguntaMultipleSeleccionUnica = (props) => {
    const { data } = props;

    return (
        <>
            <div className='row'>
                <div className='col-md-12'>
                    <label>
                        {data.numero}.{data.nombre} <span className='text-danger'>*</span>
                    </label>
                </div>

                <div className='col-md-12 mt-3'>
                    <Form.Item
                        name={`pregunta_${data.id}`}
                        label=''
                        rules={[{ required: true }]}
                        initialValue={data.respuestas[0]}
                    >
                        <Radio.Group>
                            <Space direction='vertical'>
                                {data.opciones.map((opcion, index) => (
                                    <div className='row' key={index}>
                                        <div className='col-md-12'>
                                            <Radio value={opcion.id}>{opcion.nombre}</Radio>
                                        </div>
                                    </div>
                                ))}
                            </Space>
                        </Radio.Group>
                    </Form.Item>
                </div>
            </div>
        </>
    );
};

const PreguntaMultipleSeleccionMultiple = (props) => {
    const { data } = props;

    return (
        <>
            <div className='row'>
                <div className='col-md-12'>
                    <label>
                        {data.numero}.{data.nombre} <span className='text-danger'>*</span>
                    </label>
                </div>

                <div className='col-md-12 mt-3'>
                    <Form.Item
                        name={`pregunta_${data.id}`}
                        label=''
                        rules={[{ required: true }]}
                        initialValue={data.respuestas}
                    >
                        <Checkbox.Group>
                            <Space direction='vertical'>
                                {data.opciones.map((opcion, index) => (
                                    <div className='row' key={index}>
                                        <div className='col-md-12'>
                                            <Checkbox value={opcion.id}>{opcion.nombre}</Checkbox>
                                        </div>
                                    </div>
                                ))}
                            </Space>
                        </Checkbox.Group>
                    </Form.Item>
                </div>
            </div>
        </>
    );
};

const Pregunta = (props) => {
    const { data } = props;

    if (data.idTipoPregunta === TIPO_PREGUNTA_SINO) {
        return <PreguntaSiNo data={data} />;
    }

    if (data.idTipoPregunta === TIPO_PREGUNTA_MULTIPLE_SELECCION_UNICA) {
        return <PreguntaMultipleSeleccionUnica data={data} />;
    }

    return <PreguntaMultipleSeleccionMultiple data={data} />;
};

export default Pregunta;
