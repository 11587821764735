import { Button, Form, Select, DatePicker, Table, Space, Tooltip, Popconfirm, Divider, message } from 'antd';
import { EditTwoTone, DeleteTwoTone, ProfileTwoTone } from '@ant-design/icons';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import useSWR from 'swr';
import useSWRImmutable from 'swr/immutable';
import { DefaultPaginationConfig, DEFAULT_PAGE_SIZE } from '../../constants/pagination';
import { CUESTIONARIOS_ENDPOINT, deleteCuestionario, getAllCuestionarios } from '../../services/api/cuestionarios';
import { getAllRubroEmpresasForDropDowList, RUBRO_EMPRESAS_ENDPOINT } from '../../services/api/rubro-empresas';
import { ESTADO_ENCUESTAS_ENDPOINT, getAllEstadoEncuestas } from '../../services/api/enumeraciones';
import { DELETE_CONFIRMATION_MESSAGE, GENERIC_SUCCESS_MESSAGE, GENERIC_ERROR_MESSAGE } from '../../constants/feedback';
import { ESTADO_ENCUESTA_FINALIZADO } from '../../constants/estado-encuesta';

const { Option } = Select;
const { RangePicker } = DatePicker;
const { Column } = Table;

const itemLayout = {
    labelCol: { span: 24 },
};

const initialFilters = {
    idRubroEmpresa: '',
    fechaDesde: '',
    fechaHasta: '',
    idEstadoEncuesta: '',
    sortField: 'nombre',
    sortOrder: 'ascend',
    pageNumber: 1,
    pageSize: DEFAULT_PAGE_SIZE,
};

const CuestionarioList = () => {
    const [filters, setFilters] = useState({ ...initialFilters });
    const [selectedFilters, setSelectedFilters] = useState({ ...initialFilters });

    const { data: rubroEmpresasData } = useSWRImmutable(RUBRO_EMPRESAS_ENDPOINT, () =>
        getAllRubroEmpresasForDropDowList()
    );

    const { data: estadoEncuentasData } = useSWRImmutable(ESTADO_ENCUESTAS_ENDPOINT, () => getAllEstadoEncuestas());

    const {
        data: cuestionarios,
        error,
        mutate,
    } = useSWR([CUESTIONARIOS_ENDPOINT, filters], (_, filters) => getAllCuestionarios(filters));
    const isLoading = !error && !cuestionarios;

    const [form] = Form.useForm();

    const handleTableChange = (pagination, _, sorter) => {
        const params = {
            ...filters,
            pageNumber: Number(pagination.current),
            pageSize: Number(pagination.pageSize),
            sortField: sorter.columnKey,
            sortOrder: sorter.order,
        };

        setSelectedFilters({ ...params });
        setFilters({ ...params });
    };

    const getActionColumn = (cuestionario) => {
        return (
            <Space size={0}>
                <Tooltip title='Editar'>
                    <Link to={`/cuestionarios/${cuestionario.id}`}>
                        <EditTwoTone />
                    </Link>
                </Tooltip>

                {cuestionario.eliminable && (
                    <>
                        <Divider type='vertical' />

                        <Popconfirm
                            title={DELETE_CONFIRMATION_MESSAGE}
                            onConfirm={() => handleConfirmDelete(cuestionario.id)}
                        >
                            <Tooltip title='Eliminar' className='cursor-pointer'>
                                <DeleteTwoTone />
                            </Tooltip>
                        </Popconfirm>
                    </>
                )}

                {cuestionario.idEstadoEncuesta === ESTADO_ENCUESTA_FINALIZADO && (
                    <>
                        <Divider type='vertical' />

                        <Tooltip title='Evaluación'>
                            <Link to={`/cuestionarios/${cuestionario.id}/evaluacion`}>
                                <ProfileTwoTone />
                            </Link>
                        </Tooltip>
                    </>
                )}
            </Space>
        );
    };

    const handleShowSizeChange = (current, pageSize) => {
        setSelectedFilters({
            ...filters,
            pageSize: pageSize,
        });
    };

    const handleConfirmDelete = async (id) => {
        try {
            await deleteCuestionario(id);

            message.success(GENERIC_SUCCESS_MESSAGE);
            mutate();
        } catch (error) {
            if (error.response && error.response.status === 422) {
                message.error(error.response.data.Message);
            } else {
                message.error(GENERIC_ERROR_MESSAGE);
            }
        }
    };

    const handleReset = async () => {
        form.resetFields();

        setSelectedFilters({ ...initialFilters });
        setFilters({ ...initialFilters });
    };

    const handleFinish = async (values) => {
        const fechaCreacionRange = values['fechaCreacion'];

        const params = {
            ...selectedFilters,
            idRubroEmpresas: values.idRubroEmpresas ? values.idRubroEmpresas.join() : null,
            fechaDesde: fechaCreacionRange ? fechaCreacionRange[0].toISOString() : null,
            fechaHasta: fechaCreacionRange ? fechaCreacionRange[1].toISOString() : null,
            idEstadoEncuestas: values.idEstadoEncuestas ? values.idEstadoEncuestas.join() : null,
            pageNumber: 1,
        };

        setFilters(params);
    };

    return (
        <>
            <div className='row pt-3'>
                <div className='col-lg-12'>
                    <div className='card rounded-4 shadow'>
                        <h4 className='color-text pt-3 border-bottom mx-3'>Filtros</h4>

                        <div className='card-body'>
                            <Form form={form} onFinish={handleFinish}>
                                <div className='row pl-2 pr-2'>
                                    <div className='col-md-4 pt-2 pb-3'>
                                        <Form.Item name='idRubroEmpresas' label='Tipo rubro' {...itemLayout}>
                                            <Select
                                                className='w-100'
                                                mode='multiple'
                                                maxTagCount='responsive'
                                                allowClear
                                            >
                                                {rubroEmpresasData?.data.map((rubro) => (
                                                    <Option key={rubro.id} value={rubro.id}>
                                                        {rubro.nombre}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </div>
                                    <div className='col-md-4 pt-2 pb-3'>
                                        <Form.Item name='fechaCreacion' label='Fecha creación' {...itemLayout}>
                                            <RangePicker className='w-100' format='L' />
                                        </Form.Item>
                                    </div>

                                    <div className='col-md-4  pt-2 pb-3'>
                                        <Form.Item name='idEstadoEncuestas' label='Estado' {...itemLayout}>
                                            <Select
                                                className='w-100'
                                                mode='multiple'
                                                maxTagCount='responsive'
                                                allowClear
                                            >
                                                {estadoEncuentasData?.data.map((estado) => (
                                                    <Option key={estado.id} value={estado.id}>
                                                        {estado.nombre}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </div>

                                    <div className='col-lg-12 text-end'>
                                        <Form.Item>
                                            <Space>
                                                <Button type='primary' htmlType='submit'>
                                                    Filtrar
                                                </Button>
                                                <Button onClick={handleReset}>Limpiar</Button>
                                            </Space>
                                        </Form.Item>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>

            <div className='row pt-5 pb-5'>
                <div className='col-lg-12'>
                    <div className='card rounded-4 shadow'>
                        <h4 className='color-text pt-3 border-bottom mx-3'>Cuestionarios</h4>

                        <div className='card-body'>
                            <div className='row pl-2 pr-2'>
                                <Table
                                    rowKey={(cuestionario) => cuestionario.id}
                                    dataSource={cuestionarios?.data.items}
                                    pagination={{
                                        ...DefaultPaginationConfig,
                                        current: filters.pageNumber,
                                        total: cuestionarios?.data.totalCount,
                                        onShowSizeChange: handleShowSizeChange,
                                    }}
                                    loading={isLoading}
                                    onChange={handleTableChange}
                                >
                                    <Column title='Nombre' dataIndex='nombre' key='nombre' sorter />
                                    <Column
                                        title='Ley/Normativa'
                                        dataIndex='nombreLeyNormativa'
                                        key='leyNormativa.nombre'
                                        sorter
                                    />
                                    <Column
                                        title='Estado'
                                        dataIndex='nombreEstadoEncuesta'
                                        key='idEstadoEncuesta'
                                        sorter
                                    />
                                    <Column
                                        title='Fecha creación'
                                        dataIndex='fechaCreacion'
                                        key='fechaCreacion'
                                        sorter
                                        render={(fechaCreacion) => new Date(fechaCreacion).toLocaleDateString()}
                                    />
                                    <Column title='Acción' render={(cuestionario) => getActionColumn(cuestionario)} />
                                </Table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-lg-12 text-end mt-3'>
                    <Link to='/cuestionarios/new'>
                        <Button type='primary'>Nuevo cuestionario</Button>
                    </Link>
                </div>
            </div>
        </>
    );
};

export default CuestionarioList;
