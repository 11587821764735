import { Button, Form, Select, Space, Input, DatePicker, Tooltip, message, Spin } from 'antd';
import { FileExcelOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import useSWRImmutable from 'swr/immutable';
import { DEFAULT_PAGE_SIZE } from '../../constants/pagination';
import { getAllRubroEmpresasForDropDowList, RUBRO_EMPRESAS_ENDPOINT } from '../../services/api/rubro-empresas';
import { GENERIC_ERROR_MESSAGE, RUT_NOT_VALID } from '../../constants/feedback';
import { clearRut, formatRut, validateRut } from '../../utils/rut';
import Resultado from './components/resultado';
import { getAllResultadoEmpresasExcel } from '../../services/api/empresas';

const { Option } = Select;
const { RangePicker } = DatePicker;

const itemLayout = {
    labelCol: { span: 24 },
};

const initialFilters = {
    rut: '',
    razonSocial: '',
    idRubroEmpresa: '',
    fechaCreacionDesde: '',
    fechaCreacionHasta: '',
    usuarioAbonado: false,
    sortField: 'razonSocial',
    sortOrder: 'ascend',
    pageNumber: 1,
    pageSize: DEFAULT_PAGE_SIZE,
};

const ResultadoEmpresaList = () => {
    const [filters, setFilters] = useState({ ...initialFilters });
    const [isDownloadingAbonadosExcel, setIsDownloadingAbonadosExcel] = useState(false);
    const [isDownloadingNoAbonadosExcel, setIsDownloadingNoAbonadosExcel] = useState(false);

    const { data: rubroEmpresasData } = useSWRImmutable(RUBRO_EMPRESAS_ENDPOINT, () =>
        getAllRubroEmpresasForDropDowList()
    );

    const [form] = Form.useForm();

    const handleRutBlur = (e) => {
        const formattedRut = formatRut(e.target.value);

        if (formattedRut) {
            form.setFieldValue('rut', formatRut(e.target.value));
        }
    };

    const handleRutFocus = (e) => {
        form.setFieldValue('rut', clearRut(e.target.value));
    };

    const handleReset = async () => {
        form.resetFields();

        setFilters({ ...initialFilters });
    };

    const handleFinish = async (values) => {
        const fechaCreacionRange = values['fechaCreacion'];

        const params = {
            ...filters,
            rut: values.rut ? clearRut(values.rut) : null,
            razonSocial: values.razonSocial || null,
            idRubroEmpresa: values.idRubroEmpresa ?? null,
            fechaCreacionDesde: fechaCreacionRange ? fechaCreacionRange[0].toISOString() : null,
            fechaCreacionHasta: fechaCreacionRange ? fechaCreacionRange[1].toISOString() : null,
            pageNumber: 1,
        };

        setFilters(params);
    };

    const handleExportClick = async (usuarioAbonado) => {
        try {
            if (usuarioAbonado) {
                setIsDownloadingAbonadosExcel(true);
            } else {
                setIsDownloadingNoAbonadosExcel(true);
            }
            const response = await getAllResultadoEmpresasExcel({ ...filters, usuarioAbonado });

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'resultado.xlsx');
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            message.error(GENERIC_ERROR_MESSAGE);
        } finally {
            setIsDownloadingAbonadosExcel(false);
            setIsDownloadingNoAbonadosExcel(false);
        }
    };

    return (
        <>
            <div className='row pt-3'>
                <div className='col-lg-12'>
                    <div className='card rounded-4 shadow'>
                        <h4 className='color-text pt-3 border-bottom mx-3'>Filtros</h4>

                        <div className='card-body'>
                            <Form form={form} onFinish={handleFinish}>
                                <div className='row pl-2 pr-2'>
                                    <div className='col-md-4'>
                                        <Form.Item
                                            name='rut'
                                            label='RUT'
                                            {...itemLayout}
                                            rules={[
                                                { whitespace: true },
                                                () => ({
                                                    validator(_, value) {
                                                        if (!value || validateRut(value)) {
                                                            return Promise.resolve();
                                                        }
                                                        return Promise.reject(new Error(RUT_NOT_VALID));
                                                    },
                                                }),
                                            ]}
                                        >
                                            <Input
                                                maxLength={12}
                                                allowClear
                                                onBlur={handleRutBlur}
                                                onFocus={handleRutFocus}
                                            />
                                        </Form.Item>
                                    </div>

                                    <div className='col-md-4'>
                                        <Form.Item
                                            name='razonSocial'
                                            label='Razón social'
                                            {...itemLayout}
                                            rules={[{ whitespace: true }]}
                                        >
                                            <Input maxLength={100} allowClear />
                                        </Form.Item>
                                    </div>

                                    <div className='col-md-4'>
                                        <Form.Item name='idRubroEmpresa' label='Rubro' {...itemLayout}>
                                            <Select className='w-100' allowClear>
                                                {rubroEmpresasData?.data.map((rubro) => (
                                                    <Option key={rubro.id} value={rubro.id}>
                                                        {rubro.nombre}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </div>

                                    <div className='col-md-4'>
                                        <Form.Item name='fechaCreacion' label='Fecha creación' {...itemLayout}>
                                            <RangePicker className='w-100' format='L' />
                                        </Form.Item>
                                    </div>

                                    <div className='col-lg-12 text-end'>
                                        <Form.Item>
                                            <Space>
                                                <Button type='primary' htmlType='submit'>
                                                    Filtrar
                                                </Button>
                                                <Button onClick={handleReset}>Limpiar</Button>
                                            </Space>
                                        </Form.Item>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>

            <div className='row pt-5 pb-5'>
                <div className='col-lg-12'>
                    <div className='card rounded-4 shadow'>
                        <h4 className='color-text pt-3 border-bottom mx-3 d-flex flex-row justify-content-between'>
                            <p>Empresas abonadas</p>{' '}
                            <Tooltip title='Exportar a Excel'>
                                {!isDownloadingAbonadosExcel && (
                                    <FileExcelOutlined role='button' onClick={() => handleExportClick(true)} />
                                )}
                                {isDownloadingAbonadosExcel && <Spin />}
                            </Tooltip>
                        </h4>

                        <div className='card-body'>
                            <div className='row pl-2 pr-2'>
                                <Resultado filters={{ ...filters, usuarioAbonado: true }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='row pb-5'>
                <div className='col-lg-12'>
                    <div className='card rounded-4 shadow'>
                        <h4 className='color-text pt-3 border-bottom mx-3 d-flex flex-row justify-content-between'>
                            <p>Empresas no abonadas</p>{' '}
                            <Tooltip title='Exportar a Excel'>
                                {!isDownloadingNoAbonadosExcel && (
                                    <FileExcelOutlined role='button' onClick={() => handleExportClick(false)} />
                                )}
                                {isDownloadingNoAbonadosExcel && <Spin />}
                            </Tooltip>
                        </h4>

                        <div className='card-body'>
                            <div className='row pl-2 pr-2'>
                                <Resultado filters={{ ...filters, usuarioAbonado: false }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ResultadoEmpresaList;
