import React, { useState, useRef } from 'react';
import { Button, message } from 'antd';
import {
    ESTADO_AMBITO_ENDPOINT,
    getAllEstadoAmbitos,
    TIPO_USUARIOS_ENDPOINT,
    getAllTipoUsuarios,
} from '../../services/api/enumeraciones';
import useSWRImmutable from 'swr/immutable';
import { Card } from '../../components/card';
import { DataTable } from '../../components/form/datatable';
import { FilterForm } from './components/filter-form';
import {
    USUARIOS_ENDPOINT,
    getAllUsuarios,
    deleteUsuario,
    createUsuario,
    updateUsuario,
} from '../../services/api/usuarios';

import { GENERIC_SUCCESS_MESSAGE, GENERIC_ERROR_MESSAGE } from '../../constants/feedback';

import { CRUDASubmbitoForm } from './components/crud-form';

const UsuarioList = () => {
    const { data: estadosAmbitoData } = useSWRImmutable(ESTADO_AMBITO_ENDPOINT, () => getAllEstadoAmbitos());

    const { data: tipoUsuarioData } = useSWRImmutable(TIPO_USUARIOS_ENDPOINT, () => getAllTipoUsuarios());

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isPosting, setIsPosting] = useState(false);
    const [selectedModel, setSelectedModel] = useState(null);
    const [filters, setFilters] = useState({
        sortField: 'nombre',
        sortOrder: 'ascend',
    });

    const dataTable = useRef();

    const estados = estadosAmbitoData?.data
        ?.sort((a, b) => a.id - b.id)
        .map((estado) => ({
            nombre: estado.nombre,
            id: estado.id === 0,
        }));

    const openModal = () => {
        setIsModalOpen(true);
    };
    const closeModal = () => {
        setIsModalOpen(false);
    };

    const onSubmit = async (form) => {
        setIsPosting(true);
        try {
            const { id } = form;
            if (id) {
                await updateUsuario(form);
            } else {
                await createUsuario(form);
            }
            dataTable.current.refresh();
            setIsPosting(false);
            message.success(GENERIC_SUCCESS_MESSAGE);
        } catch (e) {
            message.error(GENERIC_ERROR_MESSAGE);
        } finally {
            closeModal();
            setSelectedModel(null);
        }
    };
    const onCancel = () => {
        setSelectedModel(null);
        closeModal();
    };

    const onSearch = (params) => {
        setFilters({
            ...(params ? filters : { sortField: 'nombre', sortOrder: 'ascend' }),
            ...params,
        });

        if (!params) {
            dataTable.current.reset();
        }
    };

    return (
        <>
            <div className='row pt-3'>
                <div className='col-lg-12'>
                    <Card title='Mantenedor Usuarios'>
                        <FilterForm onSearch={onSearch} estados={estados} />
                    </Card>
                </div>
            </div>
            <div className='row pt-5 pb-5'>
                <div className='col-lg-12'>
                    <Card title='Listado de Usuarios '>
                        <DataTable
                            ref={dataTable}
                            linkTo='/usuarios'
                            endpoint={{
                                path: USUARIOS_ENDPOINT,
                                getItems: getAllUsuarios,
                                deleteItem: (model) => deleteUsuario(model.id),
                            }}
                            dataFilters={filters}
                            columns={[
                                { name: 'id', title: 'ID' },
                                { name: 'username', title: 'User Name', sorter: true },
                                { name: 'nombre', title: 'Nombres' },
                                { name: 'apellidos', title: 'Apellidos' },
                                { name: 'correo', title: 'Correo' },
                                {
                                    name: 'activo',
                                    title: 'Estado',
                                    sorter: true,
                                    render: (estado) => (estado ? 'Activo' : 'No Activo'),
                                },
                            ]}
                            actions={{
                                deleteable: true,
                                editable: true,
                                handleEdit: (model) => {
                                    setSelectedModel(model);
                                    openModal();
                                },
                            }}
                        ></DataTable>
                    </Card>
                </div>
                <div className='col-lg-12 text-end mt-3'>
                    <Button type='primary' onClick={() => openModal()}>
                        Nuevo Usuario
                    </Button>
                </div>
            </div>
            <CRUDASubmbitoForm
                openModal={isModalOpen}
                onCancel={onCancel}
                onSubmit={onSubmit}
                model={selectedModel}
                estados={estados}
                isPosting={isPosting}
                tipoUsuarios={tipoUsuarioData?.data}
            />
        </>
    );
};

export default UsuarioList;
