import axios from '../../lib/axios';

export const EMPRESAS_ENDPOINT = '/empresas';

export const getAllEmpresas = async (params) => {
    const response = await axios.get(EMPRESAS_ENDPOINT, { params });
    return response;
};

export const getEmpresaById = async (id) => {
    const response = await axios.get(`${EMPRESAS_ENDPOINT}/${id}`);
    return response;
};

export const createEmpresa = async (params) => {
    const response = await axios.post(EMPRESAS_ENDPOINT, params);
    return response;
};

export const updateEmpresa = async (params) => {
    const response = await axios.put(EMPRESAS_ENDPOINT, params);
    return response;
};

export const deleteEmpresa = async (id) => {
    const response = await axios.delete(`${EMPRESAS_ENDPOINT}/${id}`);
    return response;
};

export const RESULTADO_EMPRESAS_ENDPOINT = `${EMPRESAS_ENDPOINT}/resultados`;

export const getAllResultadoEmpresas = async (params) => {
    const response = await axios.get(RESULTADO_EMPRESAS_ENDPOINT, { params });
    return response;
};

export const getAllResultadoEmpresasExcel = async (params) => {
    const response = await axios.get(`${RESULTADO_EMPRESAS_ENDPOINT}/excel`, { params, responseType: 'blob' });
    return response;
};

export const ENCUESTAS_FINALIZADAS_EMPRESAS_ENDPOINT = `${EMPRESAS_ENDPOINT}/encuestas/finalizadas`;

export const getEncuestasByEmpresa = async (params) => {
    const response = await axios.get(ENCUESTAS_FINALIZADAS_EMPRESAS_ENDPOINT, { params });
    return response;
};

export const PAGOS_EMPRESA_ENDPOINT = `${EMPRESAS_ENDPOINT}/{id}/pagos`;

export const getPagosByEmpresa = async (id, params) => {
    const response = await axios.get(`${EMPRESAS_ENDPOINT}/${id}/pagos`, { params });
    return response;
};

export const PAGO_EMPRESA_BY_ID_ENDPOINT = `${EMPRESAS_ENDPOINT}/pagos/{id}`;

export const getPagoById = async (id) => {
    const response = await axios.get(`${EMPRESAS_ENDPOINT}/pagos/${id}`);
    return response;
};

export const createPago = async (params) => {
    const response = await axios.post(`${EMPRESAS_ENDPOINT}/pagos`, params);
    return response;
};

export const updatePago = async (params) => {
    const response = await axios.put(`${EMPRESAS_ENDPOINT}/pagos`, params);
    return response;
};

export const deletePago = async (id) => {
    const response = await axios.delete(`${EMPRESAS_ENDPOINT}/pagos/${id}`);
    return response;
};
