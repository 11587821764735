import React, { useEffect, useState, useImperativeHandle } from 'react';
import useSWR from 'swr';
import { Table, Space, Tooltip, Popconfirm, Divider, message, Button } from 'antd';
import { EditTwoTone, DeleteTwoTone } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { DefaultPaginationConfig, DEFAULT_PAGE_SIZE } from '../../constants/pagination';
import { DELETE_CONFIRMATION_MESSAGE, GENERIC_SUCCESS_MESSAGE, GENERIC_ERROR_MESSAGE } from '../../constants/feedback';

const baseFilters = {
    pageNumber: 1,
    pageSize: DEFAULT_PAGE_SIZE,
};

const { Column } = Table;
export const DataTable = React.forwardRef((props, ref) => {
    const navigate = useNavigate();
    const { dataFilters, columns = [], children } = props;

    const {
        endpoint: { path, getItems, deleteItem },
    } = props;

    const [selectedFilters, setSelectedFilters] = useState({
        ...baseFilters,
    });

    useEffect(() => {
        setSelectedFilters({ ...selectedFilters, ...dataFilters });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataFilters]);

    const { data: response, error, mutate } = useSWR([path, selectedFilters], (_, filters) => getItems(filters));

    useImperativeHandle(ref, () => ({
        refresh: () => {
            mutate();
        },
        reset: () => {
            setSelectedFilters({ ...baseFilters });
        },
    }));

    const handleConfirmDelete = async (model) => {
        try {
            await deleteItem(model);

            message.success(GENERIC_SUCCESS_MESSAGE);
            mutate();
        } catch (error) {
            if (error.response) {
                const { data } = error.response;
                const { Message } = data;
                message.error(Message || GENERIC_ERROR_MESSAGE);
            } else {
                message.error(GENERIC_ERROR_MESSAGE);
            }
        }
    };

    const renderActions = () => {
        const { actions } = props;
        const { deleteable, editable = true, handleEdit } = actions;

        return (
            actions && (
                <Column
                    title='Acción'
                    render={(model) => (
                        <Space size={0}>
                            {editable && (
                                <>
                                    <Tooltip title='Editar'>
                                        <Button
                                            size='small'
                                            type='text'
                                            icon={<EditTwoTone />}
                                            onClick={() => {
                                                const editAction = handleEdit || navigateAction;
                                                editAction(model);
                                            }}
                                        />
                                    </Tooltip>

                                    <Divider type='vertical' />
                                </>
                            )}
                            {deleteable && (
                                <Popconfirm
                                    title={DELETE_CONFIRMATION_MESSAGE}
                                    onConfirm={() => handleConfirmDelete(model)}
                                >
                                    <Tooltip title='Eliminar' className='cursor-pointer'>
                                        <Button size='small' type='text' icon={<DeleteTwoTone />} />
                                    </Tooltip>
                                </Popconfirm>
                            )}
                        </Space>
                    )}
                />
            )
        );
    };

    const navigateAction = (model) => {
        navigate(`${props.linkTo}/${model.id}`);
    };

    const handleShowSizeChange = (current, pageSize) => {
        setSelectedFilters({
            ...selectedFilters,
            pageSize: pageSize,
        });
    };

    const handleTableChange = (pagination, _, sorter) => {
        setSelectedFilters({
            ...selectedFilters,
            pageNumber: Number(pagination.current),
            pageSize: Number(pagination.pageSize),
            sortField: sorter.columnKey,
            sortOrder: sorter.order,
        });
    };

    const isLoading = !error && !response;

    return (
        <>
            <Table
                rowKey={(model) => model.id}
                dataSource={response?.data.items}
                pagination={{
                    ...DefaultPaginationConfig,
                    current: selectedFilters.pageNumber,
                    total: response?.data.totalCount,
                    onShowSizeChange: handleShowSizeChange,
                }}
                loading={isLoading}
                onChange={handleTableChange}
            >
                {columns.map((column) => {
                    const { render, name, title, sorter } = column;
                    return (
                        <Column
                            sorter={sorter}
                            title={title || `${name.charAt(0).toUpperCase()}${name.slice(1)}`}
                            dataIndex={name}
                            key={name}
                            {...(render ? { render } : {})}
                        />
                    );
                })}
                {children}

                {renderActions()}
            </Table>
        </>
    );
});
