import axios from '../../lib/axios';

export const ATENCION_CLIENTES_ENDPOINT = '/AtencionClientes';


export const createNuevaConsulta = async (params) => { 
    const response = await axios.post(ATENCION_CLIENTES_ENDPOINT, params);
    return response;
}

export const updateConsulta = async (params) => { 
    const response = await axios.put(ATENCION_CLIENTES_ENDPOINT, params);
    return response;
}
export const getAllConsultas = async (params) => {

    const response = await axios.get(ATENCION_CLIENTES_ENDPOINT, { params });
    return response;
};

export const getHilo = async (id) => {
    const response = await axios.get(`${ATENCION_CLIENTES_ENDPOINT}/${id}`);
    return response;
};