import { useEffect, useState } from 'react';
import useSWRImmutable from 'swr/immutable';
import { useParams, useNavigate } from 'react-router-dom';
import { createEncuesta, ENCUESTAS_ENDPOINT, getEncuestaTemplate } from '../../services/api/encuestas';
import Seccion from './components/seccion';
import { Button, Form, message, Space } from 'antd';
import { GENERIC_ERROR_MESSAGE, GENERIC_SUCCESS_MESSAGE, PREGUNTA_REQUIRED } from '../../constants/feedback';
import {
    ESTADO_CUESTIONARIO_RESPONDIDO_FINALIZADO,
    ESTADO_CUESTIONARIO_RESPONDIDO_GUARDADO,
} from '../../constants/estado-cuestionario-respondido';
import { compareValues } from '../../utils/list';

const EncuestaNewPage = () => {
    const [steps, setSteps] = useState([]);
    const [currentStep, setCurrentStep] = useState({ step: 1, secciones: [] });

    let { id } = useParams();
    const navigate = useNavigate();

    const [form] = Form.useForm();

    const { data: templateData } = useSWRImmutable(
        id > 0 ? [ENCUESTAS_ENDPOINT, id] : null,
        (_, id) => getEncuestaTemplate(id),
        {
            revalidateOnMount: true,
        }
    );

    useEffect(() => {
        if (templateData) {
            const secciones = templateData.data.secciones.sort(compareValues('posicion'));

            let newSteps = [];
            const count = secciones.filter((seccion) => seccion.saltoPagina).length + 1;

            for (let i = 1; i <= count; i++) {
                newSteps.push({ step: i, secciones: [] });
            }

            let index = 0;

            for (let i = 0; i <= steps.length; i++) {
                for (let j = index; j < secciones.length; j++) {
                    if (secciones[j].saltoPagina) {
                        newSteps[i + 1] = {
                            ...newSteps[i + 1],
                            secciones: [...newSteps[i + 1].secciones, { ...secciones[j] }],
                        };

                        index = j + 1;
                        break;
                    } else {
                        newSteps[i] = { ...newSteps[i], secciones: [...newSteps[i].secciones, { ...secciones[j] }] };
                        index++;
                    }
                }
            }

            setSteps(newSteps);
            setCurrentStep({ ...newSteps[0] });
        }
    }, [templateData, steps.length]);

    const handleNextClick = () => {
        form.validateFields()
            .then(async (values) => {
                try {
                    const id = await save(values, steps.length > 1 ? 2 : 1, false);

                    navigate(`/encuestas/${id}`);
                } catch (error) {
                    message.error(GENERIC_ERROR_MESSAGE);
                }
            })
            .catch((errorInfo) => {});
    };

    const save = async (values, pagina, isFinish) => {
        const preguntas = templateData?.data.secciones.reduce((acc, cv) => {
            return [...acc, ...cv.preguntas.map((pregunta) => pregunta.id)];
        }, []);

        const respuestas = preguntas.reduce((acc, cv) => {
            const value = values[`pregunta_${cv}`];

            if (value !== undefined) {
                return [...acc, ...(Array.isArray(value) ? value : [value])];
            } else {
                return acc;
            }
        }, []);

        const params = {
            idCuestionario: id,
            idEstadoCuestionarioRespondido: isFinish
                ? ESTADO_CUESTIONARIO_RESPONDIDO_FINALIZADO
                : ESTADO_CUESTIONARIO_RESPONDIDO_GUARDADO,
            pagina,
            respuestas,
        };

        const response = await createEncuesta(params);

        return response.data;
    };

    const handleSaveClick = async () => {
        const values = form.getFieldsValue();

        try {
            const id = await save(values, 1, false);

            message.success(GENERIC_SUCCESS_MESSAGE);

            navigate(`/encuestas/${id}`);
        } catch (error) {
            message.error(GENERIC_ERROR_MESSAGE);
        }
    };

    return (
        <Form form={form} validateMessages={{ required: PREGUNTA_REQUIRED }} autoComplete='off'>
            {currentStep?.secciones.map((seccion, index) => (
                <Seccion data={seccion} key={index} />
            ))}

            <div className='row'>
                <div className='col-lg-12 text-end mt-3 mb-3'>
                    <Space>
                        {currentStep.step < steps.length && (
                            <Button type='secondary' onClick={handleNextClick}>
                                Siguiente
                            </Button>
                        )}

                        <Button type='primary' onClick={handleSaveClick}>
                            Guardar
                        </Button>

                        {currentStep.step === steps.length && (
                            <Button type='primary' htmlType='submit'>
                                Finalizar
                            </Button>
                        )}
                    </Space>
                </div>
            </div>
        </Form>
    );
};

export default EncuestaNewPage;
