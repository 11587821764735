import React, { useState } from 'react';
import { MenuFoldOutlined, MenuUnfoldOutlined, UserOutlined, MoreOutlined, ExportOutlined } from '@ant-design/icons';
import { Avatar, Breadcrumb, Dropdown, Layout, Menu } from 'antd';
import Icon from '@ant-design/icons';
import IcoMantenedoresSvg from '../../assets/image/ico-mantenedores.svg';
import IcoCuestioanarioSvg from '../../assets/image/ico-cuestionario.svg';
import IcoClientesSvg from '../../assets/image/ico-clientes.svg';
import IcoReportesSvg from '../../assets/image/ico-reportes.svg';
import { Footer } from 'antd/lib/layout/layout';
import { Link, Outlet } from 'react-router-dom';
import { useAuth } from '../../hooks/use-auth';
import { TIPO_USUARIO_ADMINISTRADOR } from '../../constants/tipo-usuario';

const { Header, Sider, Content } = Layout;

const getItem = (label, key, icon, children, type) => {
    return {
        key,
        icon,
        children,
        label,
        type,
    };
};

const adminitradorItems = [
    getItem(
        'Cuestionario',
        'sub-cuestionario',
        <Icon component={() => <img src={IcoCuestioanarioSvg} height='20' alt='Cuestionario' />} />,
        [getItem(<Link to='/cuestionarios'>Admin. Cuestionario</Link>, 'sub-cuestionario-1')]
    ),
    getItem(
        'Mantenedores',
        'sub-mantenedores',
        <Icon component={() => <img src={IcoMantenedoresSvg} height='20' alt='Mantenedores' />} />,
        [
            getItem(<Link to='/ambitos'>Ámbito</Link>, 'sub-mantenedores-1'),
            getItem(<Link to='/sub-ambitos'>Sub-Ámbito</Link>, 'sub-mantenedores-2'),
            getItem(<Link to='/usuarios'>Usuarios</Link>, 'sub-mantenedores-3'),
        ]
    ),
    getItem(
        'Clientes',
        'sub-clientes',
        <Icon component={() => <img src={IcoClientesSvg} height='20' alt='Clientes' />} />,
        [
            getItem(<Link to='/atencion-clientes'>Consulta de Clientes</Link>, 'sub-clientes-1'),
            getItem(<Link to='/empresas'>Mantenedor de Empresas</Link>, 'sub-clientes-2'),
        ]
    ),
    getItem(
        'Reportes',
        'sub-reportes',
        <Icon component={() => <img src={IcoReportesSvg} height='20' alt='Clientes' />} />,
        [getItem(<Link to='/resultado-empresas'>Resultados por Empresa</Link>, 'sub-reportes-1')]
    ),
];

const empresaItems = [
    getItem(
        'Cuestionario',
        'sub-cuestionario',
        <Icon component={() => <img src={IcoCuestioanarioSvg} height='20' alt='Cuestionario' />} />,
        [
            getItem(<Link to='/encuestas'>Mis Cuestionarios</Link>, 'sub-cuestionario-1'),
            getItem(<Link to='/encuestas/disponibles'>Cuestionarios Disponibles</Link>, 'sub-cuestionario-2'),
        ]
    ),
    getItem(
        'Atención a Clientes',
        'sub-atencion-clientes',
        <Icon component={() => <img src={IcoMantenedoresSvg} height='20' alt='Atención a Clientes' />} />,
        [getItem(<Link to='/atencion-clientes'>Mis Consultas</Link>, 'sub-atencion-clientes-1')]
    ),
    getItem(
        <a href='https://www.youtube.com/watch?v=4lrfHD0zhqY&t=6s' target='_blank' rel='noreferrer'>
            Material Audiovisual
        </a>,
        'sub-material-audiovisual',
        <Icon component={() => <img src={IcoClientesSvg} height='20' alt='Clientes' />} />,
        null
    ),
];

const avatarMenu = (logout) => {
    const avatarItems = [getItem('Cerrar sesión', 'sub-cerrar-sesion', <ExportOutlined />)];

    const onClick = (e) => {
        switch (e.key) {
            case 'sub-cerrar-sesion':
                logout();

                break;
            default:
                break;
        }
    };

    return <Menu items={avatarItems} onClick={onClick} />;
};

const SiteLayout = () => {
    const [collapsed, setCollapsed] = useState(false);
    const { user, logout } = useAuth();

    return (
        <Layout className='site-layout'>
            <Header>
                <Link to='/'>
                    <div className='logo' />
                </Link>

                <div className='d-flex justify-content-between align-items-center'>
                    {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                        className: 'trigger',
                        onClick: () => setCollapsed(!collapsed),
                    })}

                    <div>
                        <h5 className='text-white '>
                            Escritorio{' '}
                            {user?.idTipoUsuario === TIPO_USUARIO_ADMINISTRADOR ? 'Administrador' : 'Empresa'}
                        </h5>
                    </div>

                    <div className='avatar-menu'>
                        <ul className='d-flex'>
                            <li>
                                <Dropdown overlay={avatarMenu(logout)} trigger={['click']} arrow>
                                    <MoreOutlined style={{ fontSize: '30px' }} />
                                </Dropdown>
                            </li>
                            <li>
                                <Avatar size={40} icon={<UserOutlined />} />
                            </li>
                            <li className='ms-2'>
                                <span>
                                    {user?.nombre} {user?.apellidoPaterno}
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
            </Header>

            <Layout>
                <Sider trigger={null} collapsible collapsed={collapsed}>
                    <Menu
                        theme='dark'
                        mode='inline'
                        items={user?.idTipoUsuario === TIPO_USUARIO_ADMINISTRADOR ? adminitradorItems : empresaItems}
                        defaultSelectedKeys={['sub-cuestionario']}
                    />
                </Sider>
                <Layout>
                    <Content>
                        <Breadcrumb
                            style={{
                                margin: '16px 0',
                            }}
                        >
                            <Breadcrumb.Item>Home</Breadcrumb.Item>
                            <Breadcrumb.Item>Cuestionario</Breadcrumb.Item>
                        </Breadcrumb>
                        <div className='layout-content'>
                            <Outlet />
                        </div>
                    </Content>
                    <Footer>
                        <div className='container text-center'>
                            <ul className='list-unstyled mb-1'>
                                <li>
                                    <a href='https://exempla.cl/contact.php' className='link-footer'>
                                        <strong>Contáctenos </strong>
                                    </a>
                                </li>
                                <li className='border-bottom'>
                                    Isidora Goyenechea No. 3365, Of 902. Las Condes, Santiago - Chile - +56223663900 -
                                    info@exempla.cl
                                </li>
                                <li>
                                    <strong>Links de interés</strong>
                                </li>
                                <li>
                                    <a href='https://exempla.cl' className='link-footer'>
                                        Exempla
                                    </a>{' '}
                                    -{' '}
                                    <a href='https://exempla.cl/servicios.php' className='link-footer'>
                                        Plataformas Exempla
                                    </a>{' '}
                                    -{' '}
                                    <a href='https://exempla.cl/plataformas.php' className='link-footer'>
                                        Servicios Exempla
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </Footer>
                </Layout>
            </Layout>
        </Layout>
    );
};

export default SiteLayout;
