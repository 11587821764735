import { useContext } from 'react';
import CuestionarioContext from '../contexts/cuestionario-context';

const useCuestionario = () => {
    const context = useContext(CuestionarioContext);

    if (context === undefined) {
        throw new Error('useCuestionario must be used within a CuestionarioContext');
    }

    return context;
};

export default useCuestionario;
