import axios from '../../lib/axios';

export const USUARIOS_ENDPOINT = '/usuarios';

export const getAllUsuarios = async (params) => {
    const response = await axios.get(USUARIOS_ENDPOINT, { params });
    return response;
};

export const getUsuarioById = async (id) => {
    const response = await axios.get(`${USUARIOS_ENDPOINT}/${id}`);
    return response;
};

export const createUsuario = async (params) => {
    const response = await axios.post(USUARIOS_ENDPOINT, params);
    return response;
};

export const updateUsuario = async (params) => {
    const response = await axios.put(USUARIOS_ENDPOINT, params);
    return response;
};

export const deleteUsuario = async (id) => {
    const response = await axios.delete(`${USUARIOS_ENDPOINT}/${id}`);
    return response;
};
