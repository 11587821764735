import { Button, Table, Space, Tooltip, Divider, Popconfirm, message } from 'antd';
import { EditTwoTone, DeleteTwoTone } from '@ant-design/icons';
import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import useSWR from 'swr';
import { DefaultPaginationConfig, DEFAULT_PAGE_SIZE } from '../../constants/pagination';
import { deletePago, getPagosByEmpresa, PAGOS_EMPRESA_ENDPOINT } from '../../services/api/empresas';
import { DELETE_CONFIRMATION_MESSAGE, GENERIC_ERROR_MESSAGE, GENERIC_SUCCESS_MESSAGE } from '../../constants/feedback';
import PagoItem from './pago-item';

const { Column } = Table;

const initialFilters = {
    sortField: 'fechaPago',
    sortOrder: 'descend',
    pageNumber: 1,
    pageSize: DEFAULT_PAGE_SIZE,
};

const PagoListPage = () => {
    const [filters, setFilters] = useState({ ...initialFilters });
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedId, setSelectedId] = useState(0);

    let { id } = useParams();

    const {
        data: pagosData,
        error,
        mutate,
    } = useSWR([PAGOS_EMPRESA_ENDPOINT, filters], (_, filters) => getPagosByEmpresa(id, filters));
    const isLoading = !error && !pagosData;

    const handleTableChange = (pagination, _, sorter) => {
        const params = {
            ...filters,
            pageNumber: Number(pagination.current),
            pageSize: Number(pagination.pageSize),
            sortField: sorter.columnKey,
            sortOrder: sorter.order,
        };

        setFilters({ ...params });
    };

    const getActionColumn = (pago) => {
        return (
            <Space size={0}>
                <Tooltip title='Editar'>
                    <EditTwoTone onClick={() => handleEditClick(pago.id)} />
                </Tooltip>

                <Divider type='vertical' />

                <Popconfirm title={DELETE_CONFIRMATION_MESSAGE} onConfirm={() => handleConfirmDelete(pago.id)}>
                    <Tooltip title='Eliminar' className='cursor-pointer'>
                        <DeleteTwoTone />
                    </Tooltip>
                </Popconfirm>
            </Space>
        );
    };

    const handleShowSizeChange = (current, pageSize) => {
        setFilters({
            ...filters,
            pageSize: pageSize,
        });
    };

    const handleConfirmDelete = async (id) => {
        try {
            await deletePago(id);

            message.success(GENERIC_SUCCESS_MESSAGE);
            mutate();
        } catch (error) {
            message.error(GENERIC_ERROR_MESSAGE);
        }
    };

    const handleEditClick = (id) => {
        setSelectedId(id);
        setIsModalOpen(true);
    };

    const handleNewPaymentClick = () => {
        setIsModalOpen(true);
    };

    const handleOkModal = () => {
        mutate();
        setIsModalOpen(false);
    };

    const handleCancelModal = () => {
        setIsModalOpen(false);
    };

    return (
        <>
            <div className='row pt-3 pb-5'>
                <div className='col-lg-12'>
                    <div className='card rounded-4 shadow'>
                        <h4 className='color-text pt-3 border-bottom mx-3'>Pagos</h4>

                        <div className='card-body'>
                            <div className='row pl-2 pr-2'>
                                <Table
                                    rowKey={(empresa) => empresa.id}
                                    dataSource={pagosData?.data.items}
                                    pagination={{
                                        ...DefaultPaginationConfig,
                                        current: filters.pageNumber,
                                        total: pagosData?.data.totalCount,
                                        onShowSizeChange: handleShowSizeChange,
                                    }}
                                    loading={isLoading}
                                    onChange={handleTableChange}
                                >
                                    <Column title='N°' dataIndex='id' key='id' sorter />
                                    <Column
                                        title='Fecha Pago'
                                        dataIndex='fechaPago'
                                        key='fechaPago'
                                        sorter
                                        render={(fechaPago) => new Date(fechaPago).toLocaleDateString()}
                                    />
                                    <Column
                                        title='N° Transacción'
                                        dataIndex='numTransaccion'
                                        key='numTransaccion'
                                        sorter
                                    />
                                    <Column
                                        title='Fecha Inicio'
                                        dataIndex='fechaInicio'
                                        key='fechaInicio'
                                        sorter
                                        render={(fechaInicio) => new Date(fechaInicio).toLocaleDateString()}
                                    />
                                    <Column
                                        title='Fecha Término'
                                        dataIndex='fechaTermino'
                                        key='fechaTermino'
                                        sorter
                                        render={(fechaTermino) => new Date(fechaTermino).toLocaleDateString()}
                                    />
                                    <Column title='Acción' render={(pago) => getActionColumn(pago)} />
                                </Table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-lg-12 text-end mt-3'>
                    <Space>
                        <Button type='primary' onClick={handleNewPaymentClick}>
                            Nuevo pago
                        </Button>

                        <Link to={`/empresas`}>
                            <Button type='primary'>Volver</Button>
                        </Link>
                    </Space>
                </div>
            </div>

            {isModalOpen && (
                <PagoItem
                    id={selectedId}
                    idEmpresa={id}
                    isModalOpen={isModalOpen}
                    onOk={handleOkModal}
                    onCancel={handleCancelModal}
                />
            )}
        </>
    );
};

export default PagoListPage;
