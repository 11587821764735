import { useEffect } from 'react';
import { Form, Input, Select, Switch } from 'antd';
import useSWRImmutable from 'swr/immutable';
import { getAllRubroEmpresasForDropDowList, RUBRO_EMPRESAS_DDL_ENDPOINT } from '../../services/api/rubro-empresas';
import { getAllTamanoEmpresasForDropDowList, TAMANO_EMPRESAS_DDL_ENDPOINT } from '../../services/api/tamano-empresas';
import { getAllTipoPersonaJuridicas, TIPO_PERSONA_JURIDICAS_ENDPOINT } from '../../services/api/enumeraciones';
import TextArea from 'antd/lib/input/TextArea';
import useEmpresa from '../../hooks/use-empresa';
import {
    COMUNAS_BY_REGION_DDL_ENDPOINT,
    getAllRegionesForDropDowList,
    getComunasByRegionForDropDowList,
    REGIONES_DDL_ENDPOINT,
} from '../../services/api/regiones';
import { clearRut, formatRut, validateRut } from '../../utils/rut';
import { RUT_NOT_VALID } from '../../constants/feedback';

const { Option } = Select;

const itemLayout = {
    labelCol: { span: 24 },
};

const EmpresaStepOne = (props) => {
    const { form } = props;
    const idRegionWatch = Form.useWatch('idRegion', form);

    const { data: rubroEmpresasData } = useSWRImmutable(RUBRO_EMPRESAS_DDL_ENDPOINT, () =>
        getAllRubroEmpresasForDropDowList()
    );

    const { data: tipoPersonaJuridicasData } = useSWRImmutable(TIPO_PERSONA_JURIDICAS_ENDPOINT, () =>
        getAllTipoPersonaJuridicas()
    );

    const { data: regionesData } = useSWRImmutable(REGIONES_DDL_ENDPOINT, () => getAllRegionesForDropDowList());

    const { data: comunasData } = useSWRImmutable(
        idRegionWatch >= 0 ? [COMUNAS_BY_REGION_DDL_ENDPOINT, idRegionWatch] : null,
        (_, idRegion) => getComunasByRegionForDropDowList(idRegion)
    );

    const { data: tamanoEmpresasData } = useSWRImmutable(TAMANO_EMPRESAS_DDL_ENDPOINT, () =>
        getAllTamanoEmpresasForDropDowList()
    );

    const empresaContext = useEmpresa();

    useEffect(() => {
        if (empresaContext.id > 0) {
            const values = {
                rut: formatRut(empresaContext.rut),
                razonSocial: empresaContext.razonSocial,
                idRubroEmpresa: empresaContext.idRubroEmpresa,
                idTipoPersonaJuridica: empresaContext.idTipoPersonaJuridica,
                cargoResponsable: empresaContext.cargoResponsable,
                telefonoResponsable: empresaContext.telefonoResponsable,
                idRegion: empresaContext.idRegion,
                idComuna: empresaContext.idComuna,
                direccion: empresaContext.direccion,
                numDireccion: empresaContext.numDireccion,
                numDptoOf: empresaContext.numDptoOf,
                urlSitioWeb: empresaContext.urlSitioWeb,
                correoContacto: empresaContext.correoContacto,
                idTamanoEmpresa: empresaContext.idTamanoEmpresa,
                actividadEmpresa: empresaContext.actividadEmpresa,
                foda: empresaContext.foda,
                principalesProductos: empresaContext.principalesProductos,
                tecnologiasUtilizadas: empresaContext.tecnologiasUtilizadas,
                usuarioAbonado: empresaContext.usuarioAbonado,
                activo: empresaContext.activo,
            };

            form.setFieldsValue(values);
        }
    }, [empresaContext, form]);

    useEffect(() => {
        if (comunasData) {
            if (comunasData.data.some((comuna) => comuna.id === empresaContext.idComuna)) {
                form.setFieldValue(`idComuna`, empresaContext.idComuna);
            }
        }
    }, [comunasData, empresaContext, form]);

    const handleRegionChange = (value) => {
        form.setFieldValue('idComuna', undefined);
    };

    const handleRutBlur = (e) => {
        const formattedRut = formatRut(e.target.value);

        if (formattedRut) {
            form.setFieldValue('rut', formatRut(e.target.value));
        }
    };

    const handleRutFocus = (e) => {
        form.setFieldValue('rut', clearRut(e.target.value));
    };

    return (
        <>
            <div className='row'>
                <div className='col-md-8'>
                    <Form.Item
                        name='razonSocial'
                        label='Nombre del empresa'
                        {...itemLayout}
                        rules={[{ required: true, whitespace: true }]}
                    >
                        <Input maxLength={100} allowClear />
                    </Form.Item>
                </div>

                <div className='col-md-4'>
                    <Form.Item
                        name='rut'
                        label='RUT'
                        {...itemLayout}
                        rules={[
                            { required: true, whitespace: true },
                            () => ({
                                validator(_, value) {
                                    if (!value || validateRut(value)) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error(RUT_NOT_VALID));
                                },
                            }),
                        ]}
                    >
                        <Input maxLength={12} allowClear onBlur={handleRutBlur} onFocus={handleRutFocus} />
                    </Form.Item>
                </div>

                <div className='col-md-4'>
                    <Form.Item name='idRubroEmpresa' label='Rubro' {...itemLayout} rules={[{ required: true }]}>
                        <Select className='w-100' allowClear>
                            {rubroEmpresasData?.data.map((rubro) => (
                                <Option key={rubro.id} value={rubro.id}>
                                    {rubro.nombre}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </div>

                <div className='col-md-4'>
                    <Form.Item
                        name='idTipoPersonaJuridica'
                        label='Tipo persona jurídica'
                        {...itemLayout}
                        rules={[{ required: true }]}
                    >
                        <Select className='w-100' allowClear>
                            {tipoPersonaJuridicasData?.data.map((ley) => (
                                <Option key={ley.id} value={ley.id}>
                                    {ley.nombre}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </div>

                <div className='col-md-4'>
                    <Form.Item name='idRegion' label='Región' {...itemLayout} rules={[{ required: true }]}>
                        <Select className='w-100' allowClear onChange={handleRegionChange}>
                            {regionesData?.data.map((ley) => (
                                <Option key={ley.id} value={ley.id}>
                                    {ley.nombre}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </div>

                <div className='col-md-4'>
                    <Form.Item name='idComuna' label='Comuna' {...itemLayout} rules={[{ required: true }]}>
                        <Select className='w-100' allowClear>
                            {comunasData?.data.map((ley) => (
                                <Option key={ley.id} value={ley.id}>
                                    {ley.nombre}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </div>

                <div className='col-md-4'>
                    <Form.Item name='direccion' label='Dirección' {...itemLayout} rules={[{ whitespace: true }]}>
                        <Input maxLength={100} allowClear />
                    </Form.Item>
                </div>

                <div className='col-md-4'>
                    <Form.Item name='numDireccion' label='Nº dirección' {...itemLayout} rules={[{ whitespace: true }]}>
                        <Input maxLength={8} allowClear />
                    </Form.Item>
                </div>

                <div className='col-md-4'>
                    <Form.Item name='numDptoOf' label='Nº depto/oficina' {...itemLayout} rules={[{ whitespace: true }]}>
                        <Input maxLength={8} allowClear />
                    </Form.Item>
                </div>

                <div className='col-md-8'>
                    <Form.Item
                        name='urlSitioWeb'
                        label='Url sitio web empresa'
                        {...itemLayout}
                        rules={[
                            { whitespace: true },
                            {
                                type: 'url',
                                warningOnly: true,
                            },
                        ]}
                    >
                        <Input maxLength={100} allowClear />
                    </Form.Item>
                </div>

                <div className='col-md-4'>
                    <Form.Item
                        name='idTamanoEmpresa'
                        label='Tamaño empresa (N° Trabajadores)'
                        {...itemLayout}
                        rules={[{ required: true }]}
                    >
                        <Select className='w-100' allowClear>
                            {tamanoEmpresasData?.data.map((tamano) => (
                                <Option key={tamano.id} value={tamano.id}>
                                    {tamano.nombre}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </div>

                <div className='col-md-8'>
                    <Form.Item
                        name='actividadEmpresa'
                        label='Actividad de empresa'
                        {...itemLayout}
                        rules={[{ whitespace: true }]}
                    >
                        <Input maxLength={100} allowClear />
                    </Form.Item>
                </div>

                <div className='col-md-12'>
                    <Form.Item
                        name='foda'
                        label='Recursos y capacidades de la empresa en el ámbito de datos personales'
                        {...itemLayout}
                        rules={[{ whitespace: true }]}
                    >
                        <TextArea showCount maxLength={500} rows={3} allowClear />
                    </Form.Item>
                </div>

                <div className='col-md-12'>
                    <Form.Item
                        name='principalesProductos'
                        label='Descripción de los principales productos y servicios'
                        {...itemLayout}
                        rules={[{ whitespace: true }]}
                    >
                        <TextArea showCount maxLength={500} rows={3} allowClear />
                    </Form.Item>
                </div>

                <div className='col-md-12'>
                    <Form.Item
                        name='tecnologiasUtilizadas'
                        label='Descripción de las tecnologías que habitualmente utiliza la empresa'
                        {...itemLayout}
                        rules={[{ whitespace: true }]}
                    >
                        <TextArea showCount maxLength={500} rows={3} allowClear />
                    </Form.Item>
                </div>

                <div className='col-md-4'>
                    <Form.Item name='usuarioAbonado' label='Usuario abonado' valuePropName='checked'>
                        <Switch />
                    </Form.Item>
                </div>

                <div className='col-md-4'>
                    <Form.Item name='activo' label='Activo' valuePropName='checked'>
                        <Switch />
                    </Form.Item>
                </div>

                <div className='col-md-12'>
                    <br />
                    <p>
                        <b>
                            <span style={{ color: '#ff4d4f' }}>*</span> Son campos obligatorios
                        </b>
                    </p>
                </div>
            </div>
        </>
    );
};

export default EmpresaStepOne;
