import { Button, Form, notification, Space } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { v4 as uuidv4 } from 'uuid';
import useCuestionario from '../../hooks/use-cuestionario';
import Seccion from './components/seccion';
import { Link } from 'react-router-dom';
import { ESTADO_ENCUESTA_FINALIZADO } from '../../constants/estado-encuesta';

const CuestionarioStepTwo = (props) => {
    const { form } = props;
    const cuestionarioContext = useCuestionario();

    const handleAddSeccionClick = () => {
        const newSeccion = {
            uuid: uuidv4(),
            descripcion: '',
            idAmbito: undefined,
            idSubambito: undefined,
            posicion: Math.max(...cuestionarioContext.secciones.map((seccion) => seccion.posicion), 0) + 1,
            preguntas: [],
        };

        cuestionarioContext.update({
            ...cuestionarioContext,
            secciones: [...cuestionarioContext.secciones, newSeccion],
        });
    };

    const handleSaveClick = async () => {
        const values = form.getFieldsValue();
        cuestionarioContext.save(values);
    };

    const handleFinishClick = () => {
        form.validateFields()
            .then((_) => {
                form.submit();
            })
            .catch((errorInfo) => {
                notification['error']({
                    message: 'Registrar Cuestionario',
                    description: `${errorInfo.errorFields.length} errores impiden que se finalize este cuestionario. Corrija los errores y vuelva a intentarlo.`,
                });
            });
    };

    const handlePublishClick = () => {
        cuestionarioContext.publish();
    };

    return (
        <>
            <div className='row'>
                <div className='col-md-12'>
                    <Space direction='vertical' className='d-flex'>
                        {cuestionarioContext.secciones.map((seccion) => (
                            <Seccion seccion={seccion} key={seccion.uuid} form={form} />
                        ))}
                    </Space>
                </div>
            </div>

            <div className='row'>
                <div className='col-lg-12 text-end mt-4'>
                    <Form.Item>
                        <Space>
                            <Link to='/cuestionarios'>
                                <Button type='secondary'>Cerrar</Button>
                            </Link>

                            <Button type='primary' icon={<PlusCircleOutlined />} onClick={handleAddSeccionClick}>
                                Agregar sección
                            </Button>

                            <Button type='primary' onClick={handleSaveClick}>
                                Guardar
                            </Button>

                            <Button
                                onClick={handleFinishClick}
                                disabled={cuestionarioContext.idEstadoEncuesta === ESTADO_ENCUESTA_FINALIZADO}
                            >
                                Finalizar
                            </Button>

                            <Button
                                onClick={handlePublishClick}
                                disabled={cuestionarioContext.idEstadoEncuesta !== ESTADO_ENCUESTA_FINALIZADO}
                            >
                                Publicar
                            </Button>
                        </Space>
                    </Form.Item>
                </div>
            </div>
        </>
    );
};

export default CuestionarioStepTwo;
