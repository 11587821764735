import { Button, Form, Input, message } from 'antd';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import logExemplaAzulSvg from '../../assets/image/logo-exempla-azul.svg';
import loginImgSvg from '../../assets/image/login_img.jpg';
import { GENERIC_ERROR_MESSAGE, validateMessages } from '../../constants/feedback';
import { useAuth } from '../../hooks/use-auth';
import { signIn } from '../../services/api/auth';

const itemLayout = {
    labelCol: { span: 24 },
};

const AuthPage = () => {
    const [isLoading, setLoading] = useState(false);
    const { login } = useAuth();

    const handleFinish = async (values) => {
        try {
            setLoading(true);

            const response = await signIn(values);
            login(response.data);
        } catch (error) {
            if (error.response.status === 401) {
                message.error(error.response.data.Message);
            } else {
                message.error(GENERIC_ERROR_MESSAGE);
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className='m-0 vh-100 row justify-content-center align-items-center'>
            <Form onFinish={handleFinish} validateMessages={validateMessages} autoComplete='off'>
                <div className='row vh-100 pe-0'>
                    <div className='col-lg-4 m-0 row justify-content-center align-items-center'>
                        <div className='text-center'>
                            <div className='flex-container h-100'>
                                <div className='flex-items'>
                                    <img
                                        className='img-fluid'
                                        src={logExemplaAzulSvg}
                                        style={{ width: 250 }}
                                        alt='Exempla'
                                    />
                                </div>
                            </div>
                        </div>

                        <div className='text-center'>
                            <h5 className='pt-3'>Bienvenidos al </h5>
                            <h4 className='color-text mx-3'>Sistema de Evaluación en Gestión de Datos Personales</h4>
                        </div>

                        <div className='flex-container '>
                            <hr />
                            <div className='flex-items w-75 border-bottom border-top pb-3 '>
                                <div>
                                    <Form.Item
                                        name='username'
                                        label='Usuario'
                                        {...itemLayout}
                                        rules={[{ required: true, whitespace: true }]}
                                    >
                                        <Input maxLength={45} allowClear />
                                    </Form.Item>
                                </div>

                                <div>
                                    <Form.Item
                                        name='password'
                                        label='Contraseña'
                                        {...itemLayout}
                                        rules={[{ required: true, whitespace: true }]}
                                    >
                                        <Input.Password maxLength={100} allowClear />
                                    </Form.Item>
                                </div>

                                <div>
                                    <Button type='primary' htmlType='submit' loading={isLoading} className='w-100'>
                                        Iniciar sesión
                                    </Button>
                                </div>
                            </div>
                        </div>

                        <div className='text-center'>
                            <div className='pt-3'>
                                <a href='/recovery'>¿Olvidaste Contraseña?</a>
                            </div>

                            <div className='pb-3'>
                                <Link to='/signup'>¿No tienes Cuenta? Regístrate.</Link>
                            </div>
                        </div>
                    </div>

                    <div class='col-lg-8 p-0 m-0 d-flex align-items-center img-background'>
                        <div class='text-white text-start'>
                            <div class='col-md-8 mx-auto p-2'>
                                <p class='font-responsive'>
                                    Esta herramienta se ha desarrollado para evaluar el nivel de madurez de su
                                    organización en relación con el tratamiento de datos personales que lleven a cabo
                                    sea de trabajadores, directivos, socios, empleados, clientes, proveedores y en
                                    general cualquier tercero.
                                </p>
                                <p class='font-responsive'>
                                    Para el desarrollo de este ejercicio, entenderemos tratamiento de datos personales
                                    como cualquier operación o conjunto de operaciones o procedimientos técnicos, de
                                    carácter automatizado o no, que permitan de cualquier forma recolectar, procesar,
                                    almacenar, comunicar, transmitir o utilizar datos personales o conjuntos de datos
                                    personales.
                                </p>

                                <p class='font-responsive'>
                                    El resultado de la ejecución de esta evaluación le permitirá tener conocimiento del
                                    estado de cumplimiento de su organización, lo anterior respecto de la norma legal
                                    vigente y las mejores prácticas existentes en la materia, por lo que se recomienda
                                    responder con sinceridad y de acuerdo con aquellos elementos que efectivamente
                                    existan en su organización.
                                </p>
                                <p class='font-responsive'>
                                    La realización y resultados del siguiente ejercicio no garantizan el cumplimiento de
                                    la normativa de Protección de Datos Personales
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </Form>
        </div>
    );
};

export default AuthPage;
