import axios from '../../lib/axios';

export const LEY_NORMATIVAS_ENDPOINT = '/leynormativas';

export const LEY_NORMATIVAS_DDL_ENDPOINT = `${LEY_NORMATIVAS_ENDPOINT}/drop-down-list`;

export const getAllLeyNormativasForDropDowList = async () => {
    const response = await axios.get(LEY_NORMATIVAS_DDL_ENDPOINT);

    return response;
};
