import React, { useEffect, useMemo } from 'react';
import { Modal, Form, Input, Select, Row, Col, Checkbox, Spin } from 'antd';
import { LETTERS_SPACE_ONLY_PATTERN } from '../../../constants/regex-pattern';

export const CRUDASubmbitoForm = ({ openModal, onCancel, onSubmit, model, estados, tipoUsuarios, isPosting }) => {
    const [form] = Form.useForm();

    const formFields = useMemo(
        () => (model ? Object.keys(model).map((key) => ({ name: key, value: model[key] })) : []),
        [model]
    );

    useEffect(() => {
        form.setFields(formFields);
    }, [formFields, form]);

    const handleSubmit = async (values) => {
        await onSubmit?.({ ...values, ...(model?.id && { id: model.id }) });
        form.resetFields();
        // setId(null);
    };

    const handleCancel = () => {
        form.resetFields();
        onCancel?.();
    };
    return (
        <Modal title='Crear o Modificar Usuario' visible={openModal} onOk={form.submit} onCancel={handleCancel}>
            <Spin spinning={isPosting}>
                <Form form={form} onFinish={handleSubmit} layout='vertical'>
                    <Row>
                        <Col span={11}>
                            <Form.Item name='username' label='User Name' rules={[{ required: true, whitespace: true }]}>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={2} />
                        <Col span={11}>
                            <Form.Item
                                name='nombre'
                                label='Nombres'
                                rules={[
                                    { required: true, whitespace: true },
                                    { pattern: LETTERS_SPACE_ONLY_PATTERN, message: 'Nombres no es válido' },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={11}>
                            <Form.Item
                                name='apellidoPaterno'
                                label='Apellido Paterno'
                                rules={[
                                    { required: true, whitespace: true },
                                    { pattern: LETTERS_SPACE_ONLY_PATTERN, message: 'Apellido paterno no es válido' },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={2}></Col>
                        <Col span={11}>
                            <Form.Item
                                name='apellidoMaterno'
                                label='Apellido Materno'
                                rules={[
                                    { whitespace: true },
                                    { pattern: LETTERS_SPACE_ONLY_PATTERN, message: 'Apellido materno no es válido' },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={11}>
                            <Form.Item
                                name='correo'
                                label='Correo'
                                rules={[{ required: true, type: 'email', whitespace: true }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    {!model?.id && (
                        <Row>
                            <Col span={11}>
                                <Form.Item
                                    name='password'
                                    label='Password'
                                    rules={[{ required: true, whitespace: true }]}
                                >
                                    <Input.Password />
                                </Form.Item>
                            </Col>
                            <Col span={2}></Col>
                            <Col span={11}>
                                <Form.Item
                                    name='repetirPassword'
                                    label='Repetir Password'
                                    rules={[{ required: true, whitespace: true }]}
                                >
                                    <Input.Password />
                                </Form.Item>
                            </Col>
                        </Row>
                    )}

                    {model?.id && (
                        <Row>
                            <Col span={11}>
                                <Form.Item valuePropName='checked' name='cambiarPassword' label='Cambiar Password'>
                                    <Checkbox rules={[{ required: true }]} />
                                </Form.Item>
                            </Col>
                            <Col span={1}></Col>
                            <Col span={11}>
                                <Form.Item valuePropName='checked' name='bloqueado' label='Bloqueado'>
                                    <Checkbox rules={[{ required: true }]} />
                                </Form.Item>
                            </Col>
                        </Row>
                    )}

                    <Row>
                        <Col span={11}>
                            <Form.Item name='idTipoUsuario' label='Tipo Usuario' rules={[{ required: true }]}>
                                <Select>
                                    {tipoUsuarios?.map((estado, i) => (
                                        <Select.Option key={i} value={estado.id}>
                                            {estado.nombre}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={1} />
                        <Col span={11}>
                            <Form.Item name='activo' label='Estado Activo' rules={[{ required: true }]}>
                                <Select>
                                    {estados?.map((estado, i) => (
                                        <Select.Option key={i} value={estado.id}>
                                            {estado.nombre}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        </Modal>
    );
};
