import axios from '../../lib/axios';

export const ENCUESTAS_ENDPOINT = '/encuestas';

export const ENCUESTAS_DISPONIBLES_ENDPOINT = `${ENCUESTAS_ENDPOINT}/disponibles`;

export const getEncuestasDisponibles = async () => {
    const response = await axios.get(ENCUESTAS_DISPONIBLES_ENDPOINT, {});
    return response;
};

export const getEncuestaTemplate = async (id) => {
    const response = await axios.get(`${ENCUESTAS_DISPONIBLES_ENDPOINT}/${id}/template`);
    return response;
};

export const getAllEncuestas = async (params) => {
    const response = await axios.get(`${ENCUESTAS_ENDPOINT}`, { params });
    return response;
};

export const ENCUESTAS_FULL_ENDPOINT = `${ENCUESTAS_ENDPOINT}}/{id}/full`;

export const getFullEncuestaById = async (id) => {
    const response = await axios.get(`${ENCUESTAS_ENDPOINT}/${id}/full`);
    return response;
};

export const createEncuesta = async (params) => {
    const response = await axios.post(ENCUESTAS_ENDPOINT, params);
    return response;
};

export const updateEncuesta = async (params) => {
    const response = await axios.put(ENCUESTAS_ENDPOINT, params);
    return response;
};

export const RESULTADO_ENCUESTA_ENDPOINT = `${ENCUESTAS_ENDPOINT}/{id}/resultado`;

export const getResultadoEncuestaById = async (id) => {
    const response = await axios.get(`${ENCUESTAS_ENDPOINT}/${id}/resultado`);
    return response;
};

export const getPdfResultadoEncuestaById = async (id) => {
    const response = await axios.get(`${ENCUESTAS_ENDPOINT}/${id}/pdf`, { responseType: 'blob' });
    return response;
};

export const sendPdfResultadoEncuestaToEmailById = async (id) => {
    const response = await axios.post(`${ENCUESTAS_ENDPOINT}/${id}/pdf/send`,{
        id
    });
    return response;
};

export const PREVIEW_ENCUESTA_ENDPOINT = `${ENCUESTAS_ENDPOINT}}/{id}/preview`;

export const getPreviewEncuestaById = async (id) => {
    const response = await axios.get(`${ENCUESTAS_ENDPOINT}/${id}/preview`);
    return response;
};
