import { message } from 'antd';
import axios from 'axios';

axios.defaults.timeout = 30000;

export const loginAxios = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
});

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
});

instance.interceptors.request.use(
    (config) => {
        const user = JSON.parse(localStorage.getItem('user'));

        if (user) {
            const bearerAuth = `Bearer ${user.accessToken}`;
            config.headers['Authorization'] = bearerAuth;
        }

        return config;
    },
    (error) => {
        Promise.reject(error);
    }
);

instance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response.status === 401) {
            message.error(error);

            localStorage.removeItem('user');
            window.location = '/login';
        }

        return Promise.reject(error);
    }
);

export default instance;
