import { useState } from 'react';
import { Button, Form, message, Skeleton, Space } from 'antd';
import { Link, Navigate, useParams } from 'react-router-dom';
import useSWRImmutable from 'swr/immutable';
import { v4 as uuidv4 } from 'uuid';
import {
    CUESTIONARIO_NOT_FOUND,
    GENERIC_ERROR_MESSAGE,
    GENERIC_SUCCESS_MESSAGE,
    SUM_PODERACION_EVALUACION_AMBITO_NOT_VALID,
    validateMessages,
} from '../../constants/feedback';
import {
    CUESTIONARIO_EVALUACION_ENDPOINT,
    getEvaluacionById,
    updateEvaluacion,
} from '../../services/api/cuestionarios';
import Ambito from './components/ambito';

const EvaluacionItem = () => {
    const [ambitos, setAmbitos] = useState([]);
    const [activePanels, setActivePanels] = useState([]);

    let { id } = useParams();

    const { data: encuestaData, error: encuestaError } = useSWRImmutable(
        id > 0 ? [CUESTIONARIO_EVALUACION_ENDPOINT, id] : null,
        (_, id) => getEvaluacionById(id),
        {
            revalidateOnMount: true,
            onSuccess: (response) => {
                if (response.status === 404) {
                    message.error(CUESTIONARIO_NOT_FOUND);
                    Navigate('/cuestionarios');
                } else {
                    const newAmbitos = response.data.ambitos.map((ambito) => {
                        const uuidAmbito = uuidv4();
                        return {
                            ...ambito,
                            uuid: uuidAmbito,

                            subambitos: ambito.subambitos.map((subambito) => {
                                const uuidSubambito = uuidv4();
                                return {
                                    ...subambito,
                                    uuid: uuidSubambito,
                                    uuidSeccion: uuidAmbito,
                                };
                            }),
                        };
                    });

                    setAmbitos(newAmbitos);
                }
            },
        }
    );
    const isLoading = !encuestaData && !encuestaError;

    const handleFinishFailed = (values) => {
        const errorkeys = values.errorFields.map((error) => error.name[0].split('_')[1]);

        const subambitokeys = [];
        ambitos.map((ambito) => {
            subambitokeys.push(...ambito.subambitos.map((subambito) => subambito.uuid));
            return ambito;
        });

        const keys = [];
        errorkeys.map((key) => {
            if (subambitokeys.includes(key)) {
                keys.push(key);
            }

            return key;
        });

        setActivePanels(keys);
    };

    const handleFinish = async (values) => {
        try {
            const newAmbitos = ambitos.map((ambito) => {
                return {
                    id: ambito.id,
                    idAmbito: ambito.idAmbito,
                    ponderacion: values[`ponderacion_${ambito.uuid}`] ?? null,
                    idTipoFeedbackR1: values[`idTipoFeedbackR1_${ambito.uuid}`] ?? null,
                    feedbackRango1: values[`feedbackRango1_${ambito.uuid}`] || null,
                    idTipoFeedbackR2: values[`idTipoFeedbackR2_${ambito.uuid}`] ?? null,
                    feedbackRango2: values[`feedbackRango2_${ambito.uuid}`] || null,
                    idTipoFeedbackR3: values[`idTipoFeedbackR3_${ambito.uuid}`] ?? null,
                    feedbackRango3: values[`feedbackRango3_${ambito.uuid}`] || null,
                    idTipoFeedbackR4: values[`idTipoFeedbackR4_${ambito.uuid}`] ?? null,
                    feedbackRango4: values[`feedbackRango4_${ambito.uuid}`] || null,
                    subambitos: ambito.subambitos.map((subambito) => {
                        return {
                            id: subambito.id,
                            idAmbito: subambito.idAmbito,
                            idSubambito: subambito.idSubambito,
                            ponderacion: values[`ponderacion_${subambito.uuid}`] ?? null,
                            idTipoFeedbackR1: values[`idTipoFeedbackR1_${subambito.uuid}`] ?? null,
                            feedbackRango1: values[`feedbackRango1_${subambito.uuid}`] || null,
                            idTipoFeedbackR2: values[`idTipoFeedbackR2_${subambito.uuid}`] ?? null,
                            feedbackRango2: values[`feedbackRango2_${subambito.uuid}`] || null,
                            idTipoFeedbackR3: values[`idTipoFeedbackR3_${subambito.uuid}`] ?? null,
                            feedbackRango3: values[`feedbackRango3_${subambito.uuid}`] || null,
                            idTipoFeedbackR4: values[`idTipoFeedbackR4_${subambito.uuid}`] ?? null,
                            feedbackRango4: values[`feedbackRango4_${subambito.uuid}`] || null,
                        };
                    }),
                };
            });

            await updateEvaluacion({ id, ambitos: newAmbitos });

            message.success(GENERIC_SUCCESS_MESSAGE);
        } catch (error) {
            message.error(GENERIC_ERROR_MESSAGE);
        }
    };

    if (isLoading) {
        return <Skeleton active />;
    }

    return (
        <div className='row pt-3'>
            <div className='col-lg-12'>
                <div className='card rounded-4 shadow'>
                    <h4 className='color-text pt-3 border-bottom mx-3'>Evaluación</h4>

                    <div className='card-body'>
                        <Form
                            onFinish={handleFinish}
                            onFinishFailed={handleFinishFailed}
                            validateMessages={validateMessages}
                            autoComplete='off'
                        >
                            {ambitos.map((ambito) => (
                                <Ambito ambito={ambito} key={ambito.id} activePanels={activePanels} />
                            ))}

                            <div className='row'>
                                <div className='col-lg-6'>
                                    <Form.Item
                                        name='sumPoderacionAmbitos'
                                        rules={[
                                            ({ getFieldValue }) => ({
                                                validator() {
                                                    const sum = ambitos
                                                        .map((ambito) => getFieldValue(`ponderacion_${ambito.uuid}`))
                                                        .reduce((a, b) => a + b, 0);

                                                    if (sum === 100) {
                                                        return Promise.resolve();
                                                    }

                                                    return Promise.reject(
                                                        new Error(SUM_PODERACION_EVALUACION_AMBITO_NOT_VALID)
                                                    );
                                                },
                                            }),
                                        ]}
                                    />
                                </div>
                                <div className='col-lg-6 text-end mt-3'>
                                    <Space>
                                        <Link to='/cuestionarios'>
                                            <Button type='secondary'>Volver</Button>
                                        </Link>

                                        <Button type='primary' htmlType='submit'>
                                            Grabar
                                        </Button>
                                    </Space>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EvaluacionItem;
