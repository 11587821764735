import { Button, Form, Select, DatePicker, Table, Space, Tooltip, Divider, message, Spin } from 'antd';
import { EditTwoTone, FilePdfOutlined, SearchOutlined, MailOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import useSWR from 'swr';
import useSWRImmutable from 'swr/immutable';
import { DefaultPaginationConfig, DEFAULT_PAGE_SIZE } from '../../constants/pagination';
import { getAllRubroEmpresasForDropDowList, RUBRO_EMPRESAS_ENDPOINT } from '../../services/api/rubro-empresas';
import {
    ESTADO_CUESTIONARIO_RESPONDIDO_ENDPOINT,
    getAllEstadoCuestionarioRespondidos,
} from '../../services/api/enumeraciones';
import {
    ENCUESTAS_ENDPOINT,
    getAllEncuestas,
    getPdfResultadoEncuestaById,
    sendPdfResultadoEncuestaToEmailById,
} from '../../services/api/encuestas';
import {
    ESTADO_CUESTIONARIO_RESPONDIDO_FINALIZADO,
    ESTADO_CUESTIONARIO_RESPONDIDO_GUARDADO,
} from '../../constants/estado-cuestionario-respondido';
import { GENERIC_ERROR_MESSAGE } from '../../constants/feedback';

const { Option } = Select;
const { RangePicker } = DatePicker;
const { Column } = Table;

const itemLayout = {
    labelCol: { span: 24 },
};

const initialFilters = {
    idRubroEmpresa: '',
    fechaCreacionDesde: '',
    fechaCreacionHasta: '',
    idEstadoCuestionarioRespondido: '',
    sortField: 'nombre',
    sortOrder: 'ascend',
    pageNumber: 1,
    pageSize: DEFAULT_PAGE_SIZE,
};

const EncuestaList = () => {
    const [filters, setFilters] = useState({ ...initialFilters });
    const [selectedFilters, setSelectedFilters] = useState({ ...initialFilters });
    const [isDownloadingPdf, setIsDownloadingPdf] = useState(false);

    const { data: rubroEmpresasData } = useSWRImmutable(RUBRO_EMPRESAS_ENDPOINT, () =>
        getAllRubroEmpresasForDropDowList()
    );

    const { data: estadoCuestionarioRespondidosData } = useSWRImmutable(ESTADO_CUESTIONARIO_RESPONDIDO_ENDPOINT, () =>
        getAllEstadoCuestionarioRespondidos()
    );

    const { data: encuestasData, error } = useSWR([ENCUESTAS_ENDPOINT, filters], (_, filters) =>
        getAllEncuestas(filters)
    );
    const isLoading = !error && !encuestasData;

    const [form] = Form.useForm();

    const handleTableChange = (pagination, _, sorter) => {
        const params = {
            ...filters,
            pageNumber: Number(pagination.current),
            pageSize: Number(pagination.pageSize),
            sortField: sorter.columnKey,
            sortOrder: sorter.order,
        };

        setSelectedFilters({ ...params });
        setFilters({ ...params });
    };

    const getActionColumn = (encuesta) => {
        return (
            <Space size={0}>
                {encuesta.idEstadoCuestionarioRespondido === ESTADO_CUESTIONARIO_RESPONDIDO_GUARDADO && (
                    <Tooltip title='Editar'>
                        <Link to={`/encuestas/${encuesta.id}`}>
                            <EditTwoTone />
                        </Link>
                    </Tooltip>
                )}

                {encuesta.idEstadoCuestionarioRespondido === ESTADO_CUESTIONARIO_RESPONDIDO_FINALIZADO && (
                    <>
                        <Tooltip title='Visualizar por pantalla' className='cursor-pointer'>
                            <Link to={`/encuestas/${encuesta.id}/result`}>
                                <SearchOutlined />
                            </Link>
                        </Tooltip>

                        <Divider type='vertical' />

                        <Tooltip title='Exportar a PDF' className='cursor-pointer'>
                            {!isDownloadingPdf && <FilePdfOutlined onClick={() => handleExportPdfClick(encuesta)} />}
                            {isDownloadingPdf && <Spin className='mt-2' />}
                        </Tooltip>
                        <Divider type='vertical' />

                        <Tooltip title='Enviar por Correo' className='cursor-pointer'>
                            {!isSendingPdf && <MailOutlined onClick={() => handleSendPdfToEmailClick(encuesta)} />}
                            {isSendingPdf && <Spin className='mt-2' />}
                        </Tooltip>
                    </>
                )}
            </Space>
        );
    };

    const handleShowSizeChange = (current, pageSize) => {
        setSelectedFilters({
            ...filters,
            pageSize: pageSize,
        });
    };

    const handleReset = async () => {
        form.resetFields();

        setSelectedFilters({ ...initialFilters });
        setFilters({ ...initialFilters });
    };

    const handleFinish = async (values) => {
        const fechaCreacionRange = values['fechaCreacion'];

        const params = {
            ...selectedFilters,
            idRubroEmpresa: values.idRubroEmpresa ?? null,
            fechaCreacionDesde: fechaCreacionRange ? fechaCreacionRange[0].toISOString() : null,
            fechaCreacionHasta: fechaCreacionRange ? fechaCreacionRange[1].toISOString() : null,
            idEstadoCuestionarioRespondido: values.idEstadoCuestionarioRespondido ?? null,
            pageNumber: 1,
        };

        setFilters(params);
    };

    const [isSendingPdf, setIsSendingPdf] = useState(false);

    const handleSendPdfToEmailClick = async (encuesta) => {
        try {
            setIsSendingPdf(true);

            await sendPdfResultadoEncuestaToEmailById(encuesta.id);

            message.success('Resultados enviados');
        } catch (error) {
            message.error(GENERIC_ERROR_MESSAGE);
        } finally {
            setIsSendingPdf(false);
        }
    };
    const handleExportPdfClick = async (encuesta) => {
        try {
            setIsDownloadingPdf(true);

            const response = await getPdfResultadoEncuestaById(encuesta.id);

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'resultado.pdf');
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            message.error(GENERIC_ERROR_MESSAGE);
        } finally {
            setIsDownloadingPdf(false);
        }
    };

    return (
        <>
            <div className='row pt-3'>
                <div className='col-lg-12'>
                    <div className='card rounded-4 shadow'>
                        <h4 className='color-text pt-3 border-bottom mx-3'>Filtros</h4>

                        <div className='card-body'>
                            <Form form={form} onFinish={handleFinish}>
                                <div className='row pl-2 pr-2'>
                                    <div className='col-md-4 pt-2 pb-3'>
                                        <Form.Item name='idRubroEmpresa' label='Tipo rubro' {...itemLayout}>
                                            <Select className='w-100' allowClear>
                                                {rubroEmpresasData?.data.map((rubro) => (
                                                    <Option key={rubro.id} value={rubro.id}>
                                                        {rubro.nombre}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </div>
                                    <div className='col-md-4 pt-2 pb-3'>
                                        <Form.Item name='fechaCreacion' label='Fecha creación' {...itemLayout}>
                                            <RangePicker className='w-100' format='L' />
                                        </Form.Item>
                                    </div>

                                    <div className='col-md-4  pt-2 pb-3'>
                                        <Form.Item name='idEstadoCuestionarioRespondido' label='Estado' {...itemLayout}>
                                            <Select className='w-100' allowClear>
                                                {estadoCuestionarioRespondidosData?.data.map((estado) => (
                                                    <Option key={estado.id} value={estado.id}>
                                                        {estado.nombre}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </div>

                                    <div className='col-lg-12 text-end'>
                                        <Form.Item>
                                            <Space>
                                                <Button type='primary' htmlType='submit'>
                                                    Filtrar
                                                </Button>
                                                <Button onClick={handleReset}>Limpiar</Button>
                                            </Space>
                                        </Form.Item>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>

            <div className='row pt-5 pb-5'>
                <div className='col-lg-12'>
                    <div className='card rounded-4 shadow'>
                        <h4 className='color-text pt-3 border-bottom mx-3'>Cuestionarios</h4>

                        <div className='card-body'>
                            <div className='row pl-2 pr-2'>
                                <Table
                                    rowKey={(encuesta) => encuesta.id}
                                    dataSource={encuestasData?.data.items}
                                    pagination={{
                                        ...DefaultPaginationConfig,
                                        current: filters.pageNumber,
                                        total: encuestasData?.data.totalCount,
                                        onShowSizeChange: handleShowSizeChange,
                                    }}
                                    loading={isLoading}
                                    onChange={handleTableChange}
                                >
                                    <Column title='Id' dataIndex='id' key='id' sorter />
                                    <Column title='Nombre' dataIndex='nombreCuestionario' key='nombre' sorter />
                                    <Column
                                        title='Ley/Normativa'
                                        dataIndex='nombreLeyNormativa'
                                        key='leyNormativa.nombre'
                                        sorter
                                    />
                                    <Column
                                        title='Estado'
                                        dataIndex='nombreEstadoCuestionarioRespondido'
                                        key='idEstadoCuestionarioRespondido'
                                        sorter
                                    />
                                    <Column
                                        title='Fecha creación'
                                        dataIndex='fechaCreacion'
                                        key='fechaCreacion'
                                        sorter
                                        render={(fechaCreacion) => new Date(fechaCreacion).toLocaleDateString()}
                                    />
                                    <Column
                                        title='Puntaje'
                                        dataIndex='puntaje'
                                        key='puntaje'
                                        render={(puntaje) => `${puntaje}%`}
                                    />
                                    <Column title='Acción' render={(cuestionario) => getActionColumn(cuestionario)} />
                                </Table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EncuestaList;
