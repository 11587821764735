import { useEffect, useState } from 'react';
import useSWRImmutable from 'swr/immutable';
import { useParams, useNavigate } from 'react-router-dom';
import { ENCUESTAS_FULL_ENDPOINT, getFullEncuestaById, updateEncuesta } from '../../services/api/encuestas';
import Seccion from './components/seccion';
import { Button, Form, message, Popconfirm, Space } from 'antd';
import {
    ESTADO_CUESTIONARIO_RESPONDIDO_FINALIZADO,
    ESTADO_CUESTIONARIO_RESPONDIDO_GUARDADO,
} from '../../constants/estado-cuestionario-respondido';
import {
    FINISH_CUESTIONARIO_CONFIRMATION_MESSAGE,
    GENERIC_ERROR_MESSAGE,
    GENERIC_SUCCESS_MESSAGE,
    PREGUNTA_REQUIRED,
} from '../../constants/feedback';

const EncuestaItemPage = () => {
    const [steps, setSteps] = useState([]);
    const [currentStep, setCurrentStep] = useState({ step: 1, secciones: [] });
    const [respuestas, setRespuestas] = useState([]);

    let { id } = useParams();
    const navigate = useNavigate();

    const [form] = Form.useForm();

    const { data: encuestaData } = useSWRImmutable(
        id > 0 ? [ENCUESTAS_FULL_ENDPOINT, id] : null,
        (_, id) => getFullEncuestaById(id),
        { revalidateOnMount: true }
    );

    useEffect(() => {
        if (encuestaData) {
            const secciones = encuestaData.data.secciones;

            // Inicializa respuestas
            const currentRespuestas = secciones.reduce((acc, cv) => {
                return [
                    ...acc,
                    ...cv.preguntas.map((pregunta) => ({ pregunta: pregunta.id, respuestas: pregunta.respuestas })),
                ];
            }, []);

            setRespuestas(currentRespuestas);

            // Define cantidad de pasos
            let newSteps = [];
            const count = secciones.filter((seccion) => seccion.saltoPagina).length + 1;

            for (let i = 1; i <= count; i++) {
                newSteps.push({ step: i, secciones: [] });
            }

            let index = 0;

            for (let i = 0; i <= steps.length; i++) {
                for (let j = index; j < secciones.length; j++) {
                    if (secciones[j].saltoPagina) {
                        newSteps[i + 1] = {
                            ...newSteps[i + 1],
                            secciones: [...newSteps[i + 1].secciones, { ...secciones[j] }],
                        };

                        index = j + 1;
                        break;
                    } else {
                        newSteps[i] = { ...newSteps[i], secciones: [...newSteps[i].secciones, { ...secciones[j] }] };
                        index++;
                    }
                }
            }

            setSteps(newSteps);
            setCurrentStep({ ...newSteps[encuestaData.data.pagina - 1] });
        }
    }, [encuestaData, steps.length]);

    const handlePreviousClick = async () => {
        if (currentStep.step === steps[0].step) {
            return;
        }

        try {
            await save(currentStep.step - 1);

            setCurrentStep({ ...steps[currentStep.step - 2] });
        } catch (error) {
            message.error(GENERIC_ERROR_MESSAGE);
        }
    };

    const handleNextClick = async () => {
        form.validateFields()
            .then(async (_) => {
                try {
                    try {
                        await save(currentStep.step + 1);

                        if (currentStep.step !== steps.length) {
                            setCurrentStep({ ...steps[currentStep.step] });
                        }
                    } catch (error) {
                        message.error(GENERIC_ERROR_MESSAGE);
                    }
                } catch (error) {
                    message.error(GENERIC_ERROR_MESSAGE);
                }
            })
            .catch((errorInfo) => {});
    };

    const save = async (pagina, isFinish = false) => {
        const newRespuestas = respuestas.reduce((acc, cv) => {
            return [...acc, ...cv.respuestas];
        }, []);

        const params = {
            id,
            idEstadoCuestionarioRespondido: isFinish
                ? ESTADO_CUESTIONARIO_RESPONDIDO_FINALIZADO
                : ESTADO_CUESTIONARIO_RESPONDIDO_GUARDADO,
            pagina,
            respuestas: newRespuestas,
        };

        await updateEncuesta(params);
    };

    const handleSaveClick = async () => {
        try {
            await save(currentStep.step);

            message.success(GENERIC_SUCCESS_MESSAGE);
        } catch (error) {
            message.error(GENERIC_ERROR_MESSAGE);
        }
    };

    const handleFieldsChange = (changedFields, allFields) => {
        const selectedPregunta = Number(changedFields[0].name[0].split('_')[1]);
        const selectedRespuestas = Array.isArray(changedFields[0].value)
            ? changedFields[0].value
            : [changedFields[0].value];

        let newRespuestas = [];

        const item = respuestas.find((item) => item.pregunta === selectedPregunta);

        if (item) {
            newRespuestas = respuestas.map((item) =>
                item.pregunta === selectedPregunta ? { ...item, respuestas: selectedRespuestas } : item
            );
        } else {
            newRespuestas = [...respuestas, { pregunta: selectedPregunta, respuestas: selectedRespuestas }];
        }

        setRespuestas(newRespuestas);
    };

    const handleFinishClick = async (_) => {
        try {
            await save(currentStep.step, true);

            message.success(GENERIC_SUCCESS_MESSAGE);
            navigate('/encuestas');
        } catch (error) {
            message.error(GENERIC_ERROR_MESSAGE);
        }
    };

    return (
        <Form
            form={form}
            onFinish={handleFinishClick}
            onFieldsChange={handleFieldsChange}
            validateMessages={{ required: PREGUNTA_REQUIRED }}
            autoComplete='off'
        >
            {currentStep.secciones.map((seccion, index) => (
                <Seccion data={seccion} key={index} />
            ))}

            <div className='row'>
                <div className='col-lg-12 text-end mt-3 mb-3'>
                    <Space>
                        {currentStep.step > 1 && (
                            <Button type='secondary' onClick={handlePreviousClick}>
                                Anterior
                            </Button>
                        )}

                        {currentStep.step < steps.length && (
                            <Button type='secondary' onClick={handleNextClick}>
                                Siguiente
                            </Button>
                        )}

                        <Button type='primary' onClick={handleSaveClick}>
                            Guardar
                        </Button>

                        {currentStep.step === steps.length && (
                            <Popconfirm
                                title={FINISH_CUESTIONARIO_CONFIRMATION_MESSAGE}
                                onConfirm={() => form.submit()}
                            >
                                <Button type='primary'>Finalizar</Button>
                            </Popconfirm>
                        )}
                    </Space>
                </div>
            </div>
        </Form>
    );
};

export default EncuestaItemPage;
