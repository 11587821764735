import React, { useState, useRef } from "react";
import { Button, message } from "antd";
import {
  ESTADO_AMBITO_ENDPOINT,
  getAllEstadoAmbitos,
} from "../../services/api/enumeraciones";
import useSWRImmutable from "swr/immutable";
import { Card } from "../../components/card";
import { DataTable } from "../../components/form/datatable";
import { FilterForm } from "./components/filter-form";
import {
  SUBAMBITOS_ENDPOINT,
  getAllSubAmbitos,
  deleteSubAmbito,
  createSubAmbito,
  updatSubAmbito,
} from "../../services/api/sub-ambito";

import {
  AMBITOS_DDL_ENDPOINT,
  getAllAmbitosForDropDowList
} from "../../services/api/ambito";



import {
  GENERIC_SUCCESS_MESSAGE,
  GENERIC_ERROR_MESSAGE,
} from "../../constants/feedback";

import { CRUDASubmbitoForm } from "./components/crud-form";

const SubAmbitoList = () => {
  const { data: estadosAmbitoData } = useSWRImmutable(
    ESTADO_AMBITO_ENDPOINT,
    () => getAllEstadoAmbitos()
  );

  const { data: ambitosDDLData } = useSWRImmutable(
    AMBITOS_DDL_ENDPOINT,
    () => getAllAmbitosForDropDowList()
  );

  const [isPosting, setIsPosting] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedModel, setSelectedModel] = useState(null);
  const [filters, setFilters] = useState({
    sortField: "nombre",
    sortOrder: "ascend",
  });

  const dataTable = useRef();

  const estados = estadosAmbitoData?.data
    ?.sort((a, b) => a.id - b.id)
    .map((estado) => ({
      nombre: estado.nombre,
      id: estado.id === 0,
    }));

  const ambitos = ambitosDDLData?.data.map(ambito=>({
    nombre:ambito.nombre,
    idAmbito: ambito.id
  })); 

  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const onSubmit = async (form) => {
    setIsPosting(true);
    try {
      const { id } = form;
      if (id) {
        await updatSubAmbito(form);
      } else {
        await createSubAmbito(form);
      }
      dataTable.current.refresh();
      setIsPosting(false);
      message.success(GENERIC_SUCCESS_MESSAGE);
    } catch (e) {
      message.error(GENERIC_ERROR_MESSAGE);
    } finally {
      closeModal();
    }
  };
  const onCancel = () => {
    setSelectedModel(null);
    closeModal();
  };

  const onSearch = (params) => {

    console.log("[SEARCH PARAMS]",params);
    setFilters({
      ...(params ? filters : { sortField: "nombre", sortOrder: "ascend" }),
      ...params,
    });

    if (!params) {
      dataTable.current.reset();
    }
  };

  return (
    <>
      <div className="row pt-3">
        <div className="col-lg-12">
          <Card title="Mantenedor Sub-Ámbito">
            <FilterForm onSearch={onSearch} estados={estados} ambitos={ambitos} />
          </Card>
        </div>
      </div>
      <div className="row pt-5 pb-5">
        <div className="col-lg-12">
          <Card title="Listado Sub-Ámbitos">
            <DataTable
              ref={dataTable}
              linkTo="/cuestionarios"
              endpoint={{
                path: SUBAMBITOS_ENDPOINT,
                getItems: getAllSubAmbitos,
                deleteItem: (model) => deleteSubAmbito(model.id),
              }}
              dataFilters={filters}
              columns={[
                { name: "id", title: "ID" },
                { name: "nombre", sorter: true },
                { name: "ambitoNombre" , title: "Ámbito" },
               
                {
                  name: "activo",
                  title: "Estado",
                  sorter: true,
                  render: (estado) => (estado ? "Activo" : "No Activo"),
                },
              ]}
              actions={{
                deleteable: true,
                editable: true,
                handleEdit: (model) => {
                  setSelectedModel(model);
                  openModal();
                },
              }}
            ></DataTable>
          </Card>
        </div>
        <div className="col-lg-12 text-end mt-3">
          <Button type="primary" onClick={() => openModal()}>
            Nuevo Sub-Ámbito
          </Button>
        </div>
      </div>
      <CRUDASubmbitoForm
        openModal={isModalOpen}
        onCancel={onCancel}
        onSubmit={onSubmit}
        model={selectedModel}
        estados={estados}
        ambitos={ambitos}
        isPosting={isPosting}
      />
    </>
  );
};

export default SubAmbitoList;
