import { useEffect, useState } from 'react';
import { Button, Form, Input, notification, Select, Space, Switch } from 'antd';
import useSWRImmutable from 'swr/immutable';
import { getAllRubroEmpresasForDropDowList, RUBRO_EMPRESAS_DDL_ENDPOINT } from '../../services/api/rubro-empresas';
import { getAllTamanoEmpresasForDropDowList, TAMANO_EMPRESAS_DDL_ENDPOINT } from '../../services/api/tamano-empresas';
import { getAllVisibilidadTipoUsuarios, VISIBILIDAD_TIPO_USUARIO_ENDPOINT } from '../../services/api/enumeraciones';
import TextArea from 'antd/lib/input/TextArea';
import useCuestionario from '../../hooks/use-cuestionario';
import { getAllLeyNormativasForDropDowList, LEY_NORMATIVAS_DDL_ENDPOINT } from '../../services/api/ley-normativas';
import { Link } from 'react-router-dom';
import { ESTADO_ENCUESTA_FINALIZADO } from '../../constants/estado-encuesta';
import useSelectAllOption from '../../hooks/use-select-all-option';

const { Option } = Select;

const itemLayout = {
    labelCol: { span: 24 },
};

const CuestionarioStepOne = (props) => {
    const { form } = props;
    const [options, setOptions] = useState([]);

    const { data: rubroEmpresasData } = useSWRImmutable(RUBRO_EMPRESAS_DDL_ENDPOINT, () =>
        getAllRubroEmpresasForDropDowList()
    );

    const { data: tamanoEmpresasData } = useSWRImmutable(TAMANO_EMPRESAS_DDL_ENDPOINT, () =>
        getAllTamanoEmpresasForDropDowList()
    );

    const { data: visibilidadTipoUsuariosData } = useSWRImmutable(VISIBILIDAD_TIPO_USUARIO_ENDPOINT, () =>
        getAllVisibilidadTipoUsuarios()
    );

    const { data: leyNormativasData } = useSWRImmutable(LEY_NORMATIVAS_DDL_ENDPOINT, () =>
        getAllLeyNormativasForDropDowList()
    );

    const cuestionarioContext = useCuestionario();
    const [getRubroEmpresaValueFromEvent, rubroEmpresasWithAllOption] = useSelectAllOption(options);

    useEffect(() => {
        if (rubroEmpresasData) {
            const opts = rubroEmpresasData?.data.map((item) => ({ label: item.nombre, value: item.id }));
            setOptions(opts);
        }
    }, [rubroEmpresasData]);

    useEffect(() => {
        if (cuestionarioContext.id > 0) {
            const values = {
                nombre: cuestionarioContext.nombre,
                idLeyNormativa: cuestionarioContext.idLeyNormativa,
                idRubroEmpresas: cuestionarioContext.idRubroEmpresas,
                idTamanoEmpresas: cuestionarioContext.idTamanoEmpresas,
                idVisibilidadTipoUsuario: cuestionarioContext.idVisibilidadTipoUsuario,
                objetivo: cuestionarioContext.objetivo,
                activo: cuestionarioContext.activo,
            };

            form.setFieldsValue(values);
        }
    }, [form, cuestionarioContext]);

    const handleSaveClick = async () => {
        form.validateFields(['idLeyNormativa']).then(async (_) => {
            const values = form.getFieldsValue();
            await cuestionarioContext.save(values);
        });
    };

    const handleFinishClick = () => {
        form.validateFields()
            .then((_) => {
                form.submit();
            })
            .catch((errorInfo) => {
                notification['error']({
                    message: 'Registrar Cuestionario',
                    description: `${errorInfo.errorFields.length} errores impiden que se finalize este cuestionario. Corrija los errores y vuelva a intentarlo.`,
                });
            });
    };

    const handlePublishClick = () => {
        cuestionarioContext.publish();
    };

    return (
        <>
            <div className='row'>
                <div className='col-md-8'>
                    <Form.Item
                        name='nombre'
                        label='Nombre del cuestionario'
                        {...itemLayout}
                        rules={[{ required: true, whitespace: true }]}
                    >
                        <Input maxLength={100} allowClear />
                    </Form.Item>
                </div>

                <div className='col-md-4'>
                    <Form.Item name='idLeyNormativa' label='Ley normativa' {...itemLayout} rules={[{ required: true }]}>
                        <Select className='w-100' allowClear disabled={cuestionarioContext.id > 0}>
                            {leyNormativasData?.data.map((ley) => (
                                <Option key={ley.id} value={ley.id}>
                                    {ley.nombre}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </div>

                <div className='col-md-4'>
                    <Form.Item
                        name='idRubroEmpresas'
                        label='Tipo rubro'
                        {...itemLayout}
                        rules={[{ required: true }]}
                        getValueFromEvent={getRubroEmpresaValueFromEvent}
                    >
                        <Select
                            className='w-100'
                            mode='multiple'
                            maxTagCount='responsive'
                            allowClear
                            options={rubroEmpresasWithAllOption}
                        ></Select>
                    </Form.Item>
                </div>

                <div className='col-md-4'>
                    <Form.Item
                        name='idTamanoEmpresas'
                        label='Tamaño empresa'
                        {...itemLayout}
                        rules={[{ required: true }]}
                    >
                        <Select className='w-100' mode='multiple' maxTagCount='responsive' allowClear>
                            {tamanoEmpresasData?.data.map((tamano) => (
                                <Option key={tamano.id} value={tamano.id}>
                                    {tamano.nombre}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </div>

                <div className='col-md-4'>
                    <Form.Item
                        name='idVisibilidadTipoUsuario'
                        label='Visibilidad usuario'
                        {...itemLayout}
                        rules={[{ required: true }]}
                    >
                        <Select className='w-100' allowClear>
                            {visibilidadTipoUsuariosData?.data.map((visibilidad) => (
                                <Option key={visibilidad.id} value={visibilidad.id}>
                                    {visibilidad.nombre}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </div>

                <div className='col-md-12'>
                    <Form.Item
                        name='objetivo'
                        label='Objetivo del Cuestionario'
                        {...itemLayout}
                        rules={[{ whitespace: true }]}
                    >
                        <TextArea showCount maxLength={500} rows={3} allowClear />
                    </Form.Item>
                </div>

                <div className='col-me-12'>
                    <Form.Item name='activo' label='Activo' valuePropName='checked'>
                        <Switch />
                    </Form.Item>
                </div>

                <div className='col-lg-12 text-end mt-2'>
                    <Form.Item>
                        <Space>
                            <Link to='/cuestionarios'>
                                <Button type='secondary'>Cerrar</Button>
                            </Link>

                            <Button type='primary' onClick={handleSaveClick}>
                                Guardar
                            </Button>

                            <Button
                                onClick={handleFinishClick}
                                disabled={cuestionarioContext.idEstadoEncuesta === ESTADO_ENCUESTA_FINALIZADO}
                            >
                                Finalizar
                            </Button>

                            <Button
                                onClick={handlePublishClick}
                                disabled={cuestionarioContext.idEstadoEncuesta !== ESTADO_ENCUESTA_FINALIZADO}
                            >
                                Publicar
                            </Button>
                        </Space>
                    </Form.Item>
                </div>
            </div>
            {/* </Form> */}
        </>
    );
};

export default CuestionarioStepOne;
