import { Button, Form, message, notification, Space, Tabs } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import EmpresaStepOne from './empresa-step-one';
import EmpresaStepTwo from './empresa-step-two';
import { GENERIC_ERROR_MESSAGE, GENERIC_SUCCESS_MESSAGE, validateMessages } from '../../constants/feedback';
import { clearRut } from '../../utils/rut';
import { signUp } from '../../services/api/signup';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useCallback } from 'react';

const EmpresaItemPage = () => {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const { executeRecaptcha } = useGoogleReCaptcha();

    const handleFinishFailed = () => {
        form.validateFields()
            .then((_) => {})
            .catch((errorInfo) => {
                console.log(errorInfo);
                notification['error']({
                    message: 'Registro',
                    description: `${errorInfo.errorFields.length} errores impiden que se registre este usuario. Corrija los errores en las pestañas Empresa y Encargado, y vuelva a intentarlo.`,
                });
            });
    };

    const handleFinishClick = useCallback(
        async (values) => {
            if (!executeRecaptcha) {
                console.log('Execute recaptcha not yet available');
                return;
            }

            const token = await executeRecaptcha('signup');

            if (!token) {
                return;
            }

            try {
                const newEmpresa = {
                    rut: clearRut(values.rut),
                    razonSocial: values.razonSocial,
                    idRubroEmpresa: values.idRubroEmpresa,
                    idTipoPersonaJuridica: values.idTipoPersonaJuridica,
                    cargoResponsable: values.cargoResponsable || null,
                    telefonoResponsable: values.telefonoResponsable || null,
                    idComuna: values.idComuna,
                    direccion: values.direccion || null,
                    numDireccion: values.numDireccion || null,
                    numDptoOf: values.numDptoOf || null,
                    urlSitioWeb: values.urlSitioWeb || null,
                    correoContacto: values.correoContacto || null,
                    idTamanoEmpresa: values.idTamanoEmpresa,
                    actividadEmpresa: values.actividadEmpresa || null,
                    foda: values.foda || null,
                    principalesProductos: values.principalesProductos || null,
                    tecnologiasUtilizadas: values.tecnologiasUtilizadas || null,
                    nombre: values.nombre,
                    apellidoPaterno: values.apellidoPaterno,
                    apellidoMaterno: values.apellidoMaterno,
                    username: values.username,
                    password: values.password,
                    reCaptchaToken: token,
                };

                await signUp(newEmpresa);

                navigate(`/login`);

                message.success(GENERIC_SUCCESS_MESSAGE);
            } catch (error) {
                message.error(GENERIC_ERROR_MESSAGE);
            }
        },
        [executeRecaptcha, navigate]
    );

    return (
        <>
            <Form
                form={form}
                onFinish={handleFinishClick}
                onFinishFailed={handleFinishFailed}
                validateMessages={validateMessages}
                autoComplete='off'
            >
                <div className='row pt-3 justify-content-center mt-4'>
                    <div className='col-lg-8'>
                        <div className='card rounded-4 shadow'>
                            <h4 className='color-text pt-3 border-bottom mx-3'>Registro</h4>

                            <div className='card-body'>
                                <div className='row pl-2 pr-2'>
                                    <Tabs
                                        defaultActiveKey='1'
                                        type='card'
                                        size='small'
                                        className='ps-3 pe-3'
                                        items={[
                                            {
                                                label: 'Empresa',
                                                key: 'step-one',
                                                children: <EmpresaStepOne form={form} />,
                                                forceRender: true,
                                            },
                                            {
                                                label: 'Encargado',
                                                key: 'step-two',
                                                children: <EmpresaStepTwo form={form} />,
                                                forceRender: true,
                                            },
                                        ]}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='col-lg-12 text-center mt-3 mb-3'>
                        <Space>
                            <Link to='/login'>
                                <Button type='secondary'>Volver</Button>
                            </Link>

                            <Button type='primary' htmlType='submit'>
                                Guardar
                            </Button>
                        </Space>
                    </div>
                </div>
            </Form>
        </>
    );
};

export default EmpresaItemPage;
